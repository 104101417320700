import React, { useCallback, useEffect, useState } from 'react'
import { useQuery, useReactiveVar } from '@apollo/client'

import { currentUserDetails, linkOrCode } from '../api/apollo/variables'
import { getCurrentAccountQRDetails } from '../api/graphql/company-client'
import BulkImport from '../components/bulk-import'
import ButtonTabs from '../components/button-tabs'
import TrackCreateCloneAndEdit from '../components/track-create-clone-and-edit'
import TrackIntroContent from '../components/track-intro-content'
import Intro from '../components/intro'
import Layout from '../components/layout'
import Link from '../components/link'
import { NewEmailHTMLPreview } from '../components/new-email-html-preview'
import RequestFieldButton from '../components/request-field'
import SiteWrapper from '../components/site-wrapper'
import TrackCreateFormEmail from '../components/track-create-form-email'
import TrackCreateFormMain from '../components/track-create-form-main'
import TrackCreateLinkPreview from '../components/track-create-last-links'
import { QRCodePreviewBox } from '../components/track-create-qr-preview-row'
import TrackCreateRecentUserLinks from '../components/track-create-recent-user-links'
import TwoColumns, { Column } from '../components/two-columns'
import { defaultBgColour, defaultFgColour } from '../helpers/qr-code'
import { MinCodesByUserResult } from '../helpers/track-module'
import {
  getTrackCreateFormData,
  isSavedFormType,
  saveTrackCreateTab,
  trackCreateTabs,
} from '../helpers/track-create'
import useLogAction from '../hooks/useLogAction'
import useTrackCreateGeneratorForm from '../hooks/useTrackCreateGeneratorForm'
import styles from '../styles/track-create.module.scss'

interface TrackCreateIntroProps {
  showWorkspaceInfo?: boolean
}

export function TrackCreateIntro({ showWorkspaceInfo }: TrackCreateIntroProps) {
  const referToLinks = useReactiveVar(linkOrCode)

  return (
    <Intro
      title={referToLinks ? 'Create links and QR codes' : 'Create codes'}
      showWorkspaceInfo={showWorkspaceInfo}
    >
      <TrackIntroContent
        tagLine={
          <p>
            Track campaign traffic and improve the customer experience with
            smarter {referToLinks ? 'links' : 'codes'}.{' '}
            <Link type="arrowForward" href="/track/learn">
              Learn more
            </Link>
          </p>
        }
      />
    </Intro>
  )
}

const TrackCreate = () => {
  const { workspaceID, userID } = useReactiveVar(currentUserDetails)

  const logAction = useLogAction()

  const { data: currentAccountQrDetailsData } = useQuery(
    getCurrentAccountQRDetails,
  )

  const {
    formValues,
    updateFormValues,
    formSubmissionState,
    setFormSubmissionState,
  } = useTrackCreateGeneratorForm()

  const [currentTab, setCurrentTab] = useState<keyof typeof trackCreateTabs>(
    'web',
  )
  const [previousEmailHtml, setPreviousEmailHtml] = useState('')

  // Set initial tab based on localStorage
  // And populate email HTML
  useEffect(() => {
    if (!userID || !workspaceID) return

    const savedFormData = getTrackCreateFormData(workspaceID)

    const { active: activeTab } = savedFormData.options

    setCurrentTab(isSavedFormType(activeTab) ? activeTab : 'web')

    // Update form with saved data if present
    if (isSavedFormType(activeTab)) {
      updateFormValues(activeTab, savedFormData[activeTab], {
        resetSubmissionState: true,
      })
    }

    if (activeTab === 'email') {
      const { generatedEmailHtml: prevEmail } =
        savedFormData?.email?.emailFields || {}

      setPreviousEmailHtml(prevEmail || '')
    }
  }, [userID, workspaceID])

  const [newlyCreatedLinks, setNewlyCreatedLinks] = useState<
    MinCodesByUserResult[]
  >([])
  const [latestVCard, setLatestVCard] = useState('')
  const [latestVEvent, setLatestVEvent] = useState('')
  const [newEmailHtml, setNewEmailHtml] = useState<GeneratedEmailHTML | null>(
    null,
  )
  const [isIntegrationEmail, setIsIntegrationEmail] = useState(false)

  const [qrData, setQrData] = useState({
    fgColour: defaultFgColour,
    bgColour: defaultBgColour,
    logoImage: '',
  })

  // Get default QR colours from account settings
  useEffect(() => {
    if (
      !currentAccountQrDetailsData ||
      !currentAccountQrDetailsData.currentAccount.qrSettings ||
      currentAccountQrDetailsData.currentAccount.qrSettings.length === 0
    ) {
      return
    }

    const { qrSettings } = currentAccountQrDetailsData.currentAccount

    const useFgColour = qrSettings.find((s) => s.name === 'fgColour')
    const useBgColour = qrSettings.find((s) => s.name === 'bgColour')

    setQrData({
      fgColour: useFgColour?.value || defaultFgColour,
      bgColour: useBgColour?.value || defaultBgColour,
      logoImage: '',
    })
  }, [currentAccountQrDetailsData])

  const onChangeQrData = useCallback(
    (type: 'fg' | 'bg' | 'logo', value: string) => {
      setQrData((prev) => ({
        fgColour: type === 'fg' ? value : prev.fgColour,
        bgColour: type === 'bg' ? value : prev.bgColour,
        logoImage: type === 'logo' ? value : prev.logoImage,
      }))
    },
    [],
  )

  return (
    <SiteWrapper>
      <Layout width={1200}>
        <TrackCreateIntro />
        <TwoColumns>
          <Column main>
            <div>
              <ButtonTabs
                className={styles.buttonTabs}
                selected={Object.keys(trackCreateTabs).indexOf(currentTab)}
                isTopOfBox
                tabsLabels={Object.values(trackCreateTabs)}
                type="tabs"
                onChange={(index) => {
                  const formTab = Object.keys(trackCreateTabs)[index]

                  setCurrentTab(formTab as keyof typeof trackCreateTabs)

                  // Update form values with saved values if on a tab that saves data
                  if (isSavedFormType(formTab)) {
                    saveTrackCreateTab(workspaceID, formTab)

                    const savedFormData = getTrackCreateFormData(workspaceID)

                    updateFormValues(formTab, savedFormData[formTab], {
                      switchFormType: true,
                      resetSubmissionState: true,
                    })
                  }

                  logAction({
                    variables: {
                      action: 'track-create-change-tab',
                      pagePath: '/track/create-links',
                      functionName: 'changeTab',
                      websiteSection: 'track',
                      extra: formTab,
                    },
                  })
                }}
              >
                <TrackCreateFormMain
                  formValues={formValues.web}
                  updateFormValues={(newValues, options) => {
                    updateFormValues('web', newValues, options)
                  }}
                  formSubmissionState={formSubmissionState}
                  setFormSubmissionState={setFormSubmissionState}
                  setNewlyCreatedLinks={setNewlyCreatedLinks}
                  setLatestVCard={setLatestVCard}
                  setLatestVEvent={setLatestVEvent}
                  switchToBulkCsv={() => setCurrentTab('bulkCSV')}
                  qrData={qrData}
                  onChangeQrData={onChangeQrData}
                />
                <TrackCreateCloneAndEdit key="generator-clone" />
                <BulkImport key="generator-bulk" />
                <TrackCreateFormEmail
                  formValues={formValues.email}
                  updateFormValues={(newValues, options) =>
                    updateFormValues('email', newValues, options)
                  }
                  formSubmissionState={formSubmissionState}
                  setFormSubmissionState={setFormSubmissionState}
                  onCreateLinks={({
                    createdLinks,
                    emailSource,
                    createdEmailHtml,
                  }) => {
                    setNewlyCreatedLinks(createdLinks)

                    setNewEmailHtml(createdEmailHtml)
                    setIsIntegrationEmail(emailSource !== 'emailHtml')
                  }}
                />
              </ButtonTabs>
            </div>

            {/* QR code preview for single link */}
            {currentTab === 'web' &&
              !['vCard', 'vEvent'].includes(formValues.web.linkTo) &&
              newlyCreatedLinks.length > 0 && (
                <TrackCreateLinkPreview
                  newlyCreatedLinks={newlyCreatedLinks}
                  qrData={qrData}
                  onChangeQrData={onChangeQrData}
                />
              )}

            {currentTab === 'web' &&
              formValues.web.linkTo === 'vCard' &&
              latestVCard && (
                <QRCodePreviewBox
                  rawText={latestVCard}
                  qrData={qrData}
                  onChangeQrData={onChangeQrData}
                />
              )}

            {currentTab === 'web' &&
              formValues.web.linkTo === 'vEvent' &&
              latestVEvent && (
                <QRCodePreviewBox
                  rawText={latestVEvent}
                  qrData={qrData}
                  onChangeQrData={onChangeQrData}
                />
              )}

            {/* Most recently generated email HTML. If created in current session, highlights where links were changed */}
            {currentTab === 'email' && (newEmailHtml || previousEmailHtml) && (
              <NewEmailHTMLPreview
                newEmailHtml={newEmailHtml}
                previousEmailHtml={previousEmailHtml}
                isIntegrationEmail={isIntegrationEmail}
              />
            )}
          </Column>
          <Column side fixed>
            <TrackCreateRecentUserLinks newlyCreatedLinks={newlyCreatedLinks} />
            <RequestFieldButton />
          </Column>
        </TwoColumns>
      </Layout>
    </SiteWrapper>
  )
}

export default TrackCreate
