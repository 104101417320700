import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  useApolloClient,
  useLazyQuery,
  useMutation,
  useReactiveVar,
} from '@apollo/client'
import { nanoid } from 'nanoid'

import useCustomLinks, { AvailableDomain } from './useCustomLinks'
import useLogAction from './useLogAction'
import useSubscriptionLevel from './useSubscriptionLevel'
import useTrackGenerator from './useTrackGenerator'
import {
  currentUserDetails,
  dataSourceReactive,
  selectedAppLinkDomainReactive,
  selectedShortLinkDomainReactive,
} from '../api/apollo/variables'
import {
  createNewLink,
  getCampaignCodeGenerator,
  getUniqueParamCurrentTotal,
  updateUniqueParamCurrentSequentialCodeID,
  validateShortLinkCandidate,
} from '../api/graphql/track-create-client'
import { listAppGroups } from '../api/graphql/track-edit-client'
import { userShortLinks } from '../api/graphql/track-view-client'
import { loopApiCall } from '../helpers'
import {
  CustomLinkAliasDetails,
  SavedCustomLink,
  useAliases,
} from '../helpers/custom-links'
import { getUserData, saveUserData } from '../helpers/local-client'
import {
  CreateVCardArgs,
  CreateVEventArgs,
  EmailForm,
  EmailSources,
  ExceedCharacterLimitModalType,
  ExceedsLinksLimitModalType,
  FullLinkResult,
  LinkType,
  PreparedLinkResult,
  SoftWarning,
  SoftWarningModalType,
  WebLinkForm,
  addAutomatedParams,
  createVCard,
  createVEvent,
  formIsWebLinkForm,
  prepareLinkCombinations,
  vCardFieldDefs,
  vEventFieldDefs,
} from '../helpers/track-create'
import {
  defaultValidationChecksValues,
  getAnchorFromString,
  getCustomDomainID,
  getDomain,
} from '../helpers/track-module'
import useOnboarding from './useOnboarding'
import {
  DeepLinkInput,
  MinimalCodeList,
  UplifterIdSequentialTotal,
} from '../__gql-types__/graphql'

interface UseTrackCreateFormSubmitProps {
  formSubmissionState: {
    softDisable: boolean
    fieldsWithErrors: string[]
    showErrorMessages: boolean
  }
  setFormSubmissionState: React.Dispatch<
    React.SetStateAction<{
      softDisable: boolean
      fieldsWithErrors: string[]
      showErrorMessages: boolean
    }>
  >
  useAppLinks?: boolean
}

/** Submission logic and error state for Track>Create form */
const useTrackCreateFormSubmit = ({
  formSubmissionState,
  setFormSubmissionState,
  useAppLinks = false,
}: UseTrackCreateFormSubmitProps) => {
  const apolloClient = useApolloClient()

  const { workspaceID } = useReactiveVar(currentUserDetails)
  const dataSource = useReactiveVar(dataSourceReactive)
  const selectedShortLinkDomain = useReactiveVar(
    selectedShortLinkDomainReactive,
  )
  const selectedAppLinkDomain = useReactiveVar(selectedAppLinkDomainReactive)

  const { generatedStructure } = useTrackGenerator()

  const logAction = useLogAction()

  const {
    fullOnboardingSections: { user: userOnboardingSections },
    updateOnboardingSection,
  } = useOnboarding()

  const {
    availableShortLinkDomains,
    availableAppLinkDomains,
  } = useCustomLinks()

  const {
    featureLimits: { links: linksLimit },
    getCompanyLinksCount,
  } = useSubscriptionLevel()

  const [refetchGenerator] = useLazyQuery(getCampaignCodeGenerator, {
    fetchPolicy: 'network-only',
  })
  const [getUniqueID] = useLazyQuery(getUniqueParamCurrentTotal, {
    fetchPolicy: 'network-only',
  })
  const [fetchAppGroupDetails] = useLazyQuery(listAppGroups)
  const [validateCustomLinkAlias] = useLazyQuery(validateShortLinkCandidate, {
    fetchPolicy: 'network-only',
  })

  const [updateUniqueID, { loading: checkingUplifterIDs }] = useMutation(
    updateUniqueParamCurrentSequentialCodeID,
    {
      notifyOnNetworkStatusChange: true,
    },
  )
  const [addCode, { loading: addingCodes }] = useMutation(createNewLink)

  const [linkType, setLinkType] = useState<LinkType>('basic')

  const [
    uplifterIdData,
    setUplifterIdData,
  ] = useState<UplifterIdSequentialTotal | null>(null)
  const [uniqueIDError, setUniqueIDError] = useState(false)

  const [customLinkAliasDetails, setCustomLinkAliasDetails] = useState<
    CustomLinkAliasDetails
  >({
    key: nanoid(),
    alias: '',
    isCustom: false,
    isBatch: false,
    status: '',
    error: false,
  })

  const [createLinkWarning, setCreateLinkWarning] = useState<
    | SoftWarningModalType
    | ExceedCharacterLimitModalType
    | ExceedsLinksLimitModalType
    | null
  >(null)
  const [createLinkError, setCreateLinkError] = useState(false)

  const selectedCustomLinkDomain: AvailableDomain = useMemo(() => {
    return linkType === 'appLink'
      ? availableAppLinkDomains.find(
          ({ domainID }) => domainID === selectedAppLinkDomain,
        ) || availableAppLinkDomains[0]
      : availableShortLinkDomains.find(
          ({ domainID }) => domainID === selectedShortLinkDomain,
        ) || availableShortLinkDomains[0]
  }, [
    linkType,
    selectedAppLinkDomain,
    selectedShortLinkDomain,
    availableShortLinkDomains,
    availableAppLinkDomains,
  ])

  // Set initial value for linkType if not found
  useEffect(() => {
    if (!generatedStructure) return

    if (useAppLinks) {
      setLinkType('appLink')

      return
    }

    // Set linkType based on saved rule, if present
    const forceShortLinkRule = generatedStructure.validationChecks?.find(
      (check) => check.name === 'FORCE_SHORT_LINK',
    )

    let shortLinkValue: any = null
    let selectedRule: string | null = null

    if (
      forceShortLinkRule &&
      forceShortLinkRule.enabled &&
      forceShortLinkRule.value
    ) {
      shortLinkValue = JSON.parse(forceShortLinkRule.value)

      selectedRule =
        shortLinkValue?.find((option: any) => option.selected)?.optionValue ||
        null
    }

    // Check if link type is forced
    if (selectedRule === 'force-short-links') {
      setLinkType('shortLink')
      saveUserData({ linkType: 'shortLink' })

      return
    }

    if (selectedRule === 'force-long-links') {
      setLinkType('basic')
      saveUserData({ linkType: 'basic' })

      return
    }

    // Check local storage
    const savedState = getUserData()
    const initialState = savedState?.linkType

    if (initialState) {
      // Override old values with new ones
      if (initialState === 'full') {
        setLinkType('basic')
        saveUserData({ linkType: 'basic' })
      } else if (initialState === 'short') {
        setLinkType('shortLink')
        saveUserData({ linkType: 'shortLink' })
      } else {
        setLinkType(initialState)
      }

      return
    }

    // Final check - check rule state if recommend instead of force
    switch (selectedRule) {
      case 'recommend-short-links':
        setLinkType('shortLink')
        saveUserData({ linkType: 'shortLink' })
        return
      case 'recommend-long-links':
        setLinkType('basic')
        saveUserData({ linkType: 'basic' })
        return
      default:
        setLinkType('shortLink')
    }
  }, [useAppLinks, generatedStructure])

  /** Check if up_id should be added to end of link */
  useEffect(() => {
    if (!workspaceID || !dataSource) return

    // Adobe accounts don't use UplifterID
    if (dataSource?.connectionSource === 'adobe') {
      setUplifterIdData(null)
      return
    }

    const fetchUplifterID = async () => {
      try {
        const { data, error } = await getUniqueID({
          variables: { fieldType: 'up_id' },
        })

        if (error) {
          throw new Error()
        }

        if (data) {
          setUplifterIdData(data.track.currentSequentialCodeID)
        }
      } catch {
        setUniqueIDError(true)
      }
    }

    fetchUplifterID()
  }, [workspaceID, dataSource])

  /**
   * Rerun Unique ID check until sent etag and currentTotal match new values
   * If checked > 50 times, exit
   */
  const uniqueIDLooper = useCallback(
    async (
      totalLinks: number,
      { currentTotal, etag }: { currentTotal?: string | null; etag: string },
      fieldID = 'up_id',
    ) => {
      const { currentTotal: newCurrentTotal, updatedTotal } = await loopApiCall(
        async (variables) => {
          const { data: newData } = await updateUniqueID({
            variables,
          })

          return (
            newData?.track.updateCurrentSequentialCodeID || {
              fieldType: fieldID,
              currentTotal: currentTotal || '0',
              etag: '',
              updatedTotal: null,
            }
          )
        },
        {
          // If updatedTotal is null, check failed and must be rerun
          successCheck: (res) =>
            !!(
              res.etag &&
              res.updatedTotal !== undefined &&
              res.updatedTotal !== null
            ),
          currCallbackVars: {
            fieldType: fieldID,
            currentTotal: currentTotal || '0',
            etag,
            newTotal: (parseInt(currentTotal || '0', 16) + totalLinks).toString(
              16,
            ),
          },
          varsTransformer: (res) => ({
            fieldType: fieldID,
            currentTotal: res.currentTotal,
            etag: res.etag,
            newTotal: (parseInt(res.currentTotal, 16) + totalLinks).toString(
              16,
            ),
          }),
        },
        50,
      )

      return { newCurrentTotal, updatedTotal }
    },
    [],
  )

  /**
   * Adds sequential Uplifter IDs to built links in place
   * */
  const addUplifterIdsToLinks = useCallback(
    async (fullLinks: FullLinkResult[]) => {
      if (!uplifterIdData || !uplifterIdData.isEnabled) return fullLinks

      // Apply up_id to end of links
      try {
        if (uniqueIDError) throw new Error()

        const { currentTotal, etag, prefix, acctPrefix } = uplifterIdData

        const {
          newCurrentTotal,
          updatedTotal,
        } = await uniqueIDLooper(fullLinks.length, { currentTotal, etag })

        if (updatedTotal === null) {
          throw new Error('Reached limit of attempts to get valid Uplifter IDs')
        }

        // Add Uplifter ID (before hash) for all codes
        fullLinks.forEach((fullLink, fullLinkIndex) => {
          let fullUplifterID = `${prefix}${acctPrefix}`

          fullUplifterID += (
            parseInt(newCurrentTotal, 16) +
            fullLinkIndex +
            1
          ).toString(16)

          // Add negative lookahead to accomodate hash based routing
          // https://example.com/#/# should not have the first hash removed
          const linkAnchor = getAnchorFromString(fullLink.fC)
          const anchorReplaceRegex = new RegExp(
            `#(?!\/)${linkAnchor.replace('#', '')}`,
            'i',
          )

          const fullLinkWithoutAnchor = fullLink.fC.replace(
            anchorReplaceRegex,
            '',
          )
          const fullParamsWithoutAnchor = fullLink.tC.replace(
            anchorReplaceRegex,
            '',
          )

          fullLinks.splice(fullLinkIndex, 1, {
            ...fullLink,
            fC: `${fullLinkWithoutAnchor}${fullUplifterID}${linkAnchor}`,
            tC: `${fullParamsWithoutAnchor}${fullUplifterID}${linkAnchor}`,
            urlLength: `${fullLinkWithoutAnchor}${fullUplifterID}${linkAnchor}`
              .length,
            queryLength: `${fullParamsWithoutAnchor}${fullUplifterID}${linkAnchor}`
              .length,
          })
        })

        return fullLinks
      } catch {
        throw new Error('Issue with Uplifter IDs')
      }
    },
    [uplifterIdData],
  )

  /** Adds copyFrom, fixed and unique values to links, ready to use in CreateNewLinks mutation */
  const addAutoAddedParams = useCallback(
    async (preparedLinks: PreparedLinkResult[]): Promise<FullLinkResult[]> => {
      if (!generatedStructure) return []

      try {
        const uniqueIdParams = generatedStructure.paramDefs.filter(
          ({ fieldType }) => fieldType === 'unique',
        )

        const uniqueParamTotals: { [fieldID: string]: string } = {}

        // Loop through unique params and fetch the totals required
        // eslint-disable-next-line no-restricted-syntax
        for (const uniqueParam of uniqueIdParams) {
          const { fieldID, uniqueIDEtag, uniqueIDTotal } = uniqueParam

          if (!uniqueIDEtag) {
            throw new Error('Unique ID missing')
          }

          const {
            newCurrentTotal,
            updatedTotal,
            // eslint-disable-next-line no-await-in-loop
          } = await uniqueIDLooper(
            preparedLinks.length,
            {
              currentTotal: uniqueIDTotal,
              etag: uniqueIDEtag,
            },
            fieldID,
          )

          if (updatedTotal === null) {
            throw new Error('Reached limit of attempts to get valid Unique IDs')
          }

          uniqueParamTotals[fieldID] = newCurrentTotal
        }

        const fullLinks: FullLinkResult[] = []

        preparedLinks.forEach((preparedLink, preparedLinkIndex) => {
          const {
            fullLinkPdfs,
            fullCode,
            queryString,
            secureLink,
            linkWithoutHash,
          } = addAutomatedParams(
            preparedLink,
            preparedLinkIndex,
            generatedStructure,
            workspaceID,
            uniqueParamTotals,
          )

          const fullLink: FullLinkResult = {
            ...preparedLink,
            pDfs: fullLinkPdfs,
            fC: fullCode,
            tC: queryString,
            urlWithHash: secureLink,
            urlLength: fullCode.length,
            queryLength: fullCode.replace(linkWithoutHash, '').length,
            lP: linkWithoutHash,
            selected: true,
          }

          fullLinks.push(fullLink)
        })

        // Add Uplifter IDs if required
        await addUplifterIdsToLinks(fullLinks)

        return fullLinks
      } catch {
        throw new Error('Issue with adding non-user-set params')
      }
    },
    [workspaceID, generatedStructure, addUplifterIdsToLinks],
  )

  const submitVCardForm = useCallback(
    async (
      formVals: WebLinkForm['vCardFieldValues'],
    ): Promise<{ success: boolean; vCardResult: string }> => {
      setCreateLinkError(false)

      const { softDisable, fieldsWithErrors } = formSubmissionState

      // Do not generate if form has errors
      if (!formVals || softDisable) {
        setFormSubmissionState((curr) => ({
          ...curr,
          showErrorMessages: true,
        }))

        logAction({
          variables: {
            action: 'track-error-missing-required-vcard-field',
            functionName: 'addCodes',
            pagePath: '/track/create-links',
            websiteSection: 'track',
            extra: JSON.stringify(fieldsWithErrors),
          },
        })

        return {
          success: false,
          vCardResult: '',
        }
      }

      // Reset errors states
      setFormSubmissionState({
        softDisable: false,
        fieldsWithErrors: [],
        showErrorMessages: false,
      })

      const vCardArgs = {} as CreateVCardArgs

      Object.keys(formVals).forEach((key) => {
        if (formVals[key]) {
          vCardArgs[vCardFieldDefs[key]?.vCardName || key] = formVals[key]
        }
      })

      logAction({
        variables: {
          action: 'create-vcard',
          functionName: 'submitVCardForm',
          pagePath: '/track/create-links',
          extra: JSON.stringify({
            completedFields: Object.keys(formVals).filter(
              (key) => !!formVals[key],
            ),
          }),
          websiteSection: 'track',
        },
      })

      return {
        success: true,
        vCardResult: createVCard(vCardArgs),
      }
    },
    [formSubmissionState],
  )

  const submitVEventForm = useCallback(
    async (
      formVals: WebLinkForm['vEventFieldValues'],
    ): Promise<{ success: boolean; vEventResult: string }> => {
      setCreateLinkError(false)

      const { softDisable, fieldsWithErrors } = formSubmissionState

      // Do not generate if form has errors
      if (!formVals || softDisable) {
        setFormSubmissionState((curr) => ({
          ...curr,
          showErrorMessages: true,
        }))

        logAction({
          variables: {
            action: 'track-error-missing-required-vevent-field',
            functionName: 'addCodes',
            pagePath: '/track/create-links',
            websiteSection: 'track',
            extra: JSON.stringify(fieldsWithErrors),
          },
        })

        return {
          success: false,
          vEventResult: '',
        }
      }

      // Reset errors states
      setFormSubmissionState({
        softDisable: false,
        fieldsWithErrors: [],
        showErrorMessages: false,
      })

      const vEventArgs = {} as CreateVEventArgs

      Object.keys(formVals).forEach((key) => {
        if (typeof formVals[key] === 'string' && formVals[key]) {
          vEventArgs[vEventFieldDefs[key]?.vEventName || key] = formVals[
            key
          ] as string
        }
      })

      logAction({
        variables: {
          action: 'create-vevent',
          functionName: 'submitVEventForm',
          pagePath: '/track/create-links',
          extra: JSON.stringify({
            completedFields: Object.keys(formVals).filter(
              (key) => !!formVals[key],
            ),
          }),
          websiteSection: 'track',
        },
      })

      return {
        success: true,
        vEventResult: createVEvent(vEventArgs),
      }
    },
    [formSubmissionState],
  )

  const submitForm = useCallback(
    async (
      formVals: WebLinkForm | EmailForm,
      /** Used to ignore soft warnings (e.g. missing URL, anchor present). Should only be added on submit in warning modals */
      ignoreWarnings?: SoftWarning[],
    ): Promise<{
      success: boolean
      fullLinks: FullLinkResult[]
    }> => {
      if (!generatedStructure) {
        return {
          success: false,
          fullLinks: [],
        }
      }

      setCreateLinkError(false)
      setCreateLinkWarning(null)

      const { validationChecks } = generatedStructure

      const { softDisable, fieldsWithErrors } = formSubmissionState

      // Do not generate link if form has errors
      if (softDisable) {
        setFormSubmissionState((curr) => ({
          ...curr,
          showErrorMessages: true,
        }))

        logAction({
          variables: {
            action: 'failed-add-code',
            functionName: 'addCodes',
            pagePath: '/track/create-links',
            extra: JSON.stringify(formVals),
            websiteSection: 'track',
          },
        })

        if (fieldsWithErrors.includes('landingPage')) {
          logAction({
            variables: {
              action: 'track-error-missing-landing-page',
              functionName: 'addCodes',
              pagePath: '/track/create-links',
              websiteSection: 'track',
            },
          })
        }

        const paramErrors = fieldsWithErrors.filter(
          (error) => error !== 'landingPage',
        )

        if (paramErrors.length > 0) {
          logAction({
            variables: {
              action: 'track-error-missing-required-field',
              functionName: 'addCodes',
              pagePath: '/track/create-links',
              websiteSection: 'track',
              extra: JSON.stringify(paramErrors),
            },
          })
        }

        return {
          success: false,
          fullLinks: [],
        }
      }

      // Reset errors states
      setCustomLinkAliasDetails((curr) => ({ ...curr, error: false }))
      setFormSubmissionState({
        softDisable: false,
        fieldsWithErrors: [],
        showErrorMessages: false,
      })

      // Build paramValues for links
      const preparedLinks = prepareLinkCombinations(
        formVals,
        generatedStructure,
        workspaceID,
      )

      // Do not proceed if no valid links
      if (preparedLinks.length === 0) {
        // A separate error message is shown for email form - do not set createLinkError
        if (formIsWebLinkForm(formVals)) {
          setCreateLinkError(true)
        }

        return {
          success: false,
          fullLinks: [],
        }
      }

      const emailDomainsRule = validationChecks.find(
        (v) => v.name === 'EMAIL_DOMAIN_LIST',
      )

      // Only allow links that pass validation checks
      const filteredLinks = preparedLinks.filter(({ link }) => {
        if (
          !formIsWebLinkForm(formVals) &&
          emailDomainsRule?.enabled &&
          emailDomainsRule?.value
        ) {
          return !!emailDomainsRule.value
            .split(/(,|;)/)
            .find((domain) => domain !== '' && link.includes(domain))
        }

        return true
      })

      try {
        if (Number.isFinite(linksLimit) && linksLimit > -1) {
          const existingLinksCount = await getCompanyLinksCount()

          const remainingAvailableLinks = linksLimit - existingLinksCount

          // Check if company subscription level and existing links count allows the new number of links to be created
          if (filteredLinks.length > remainingAvailableLinks) {
            setCreateLinkWarning({
              type: 'exceeds-links-limit',
              linksCount: filteredLinks.length,
              existingLinksCount,
              linksLimit,
            })

            return {
              success: false,
              fullLinks: [],
            }
          }
        }

        // Now prepared links are validated, build full links
        const fullLinks = await addAutoAddedParams(filteredLinks)

        const showLandingPageRule = validationChecks.find(
          (check) => check.name === 'SHOW_LANDING_PAGE',
        )

        // Used to check if URLs are too long - should always be enabled if on email form
        const showLanding = formIsWebLinkForm(formVals)
          ? !!(showLandingPageRule && showLandingPageRule.enabled)
          : true

        const allUrls = [...new Set(filteredLinks.map(({ link }) => link))]

        const allUrlsValid =
          allUrls.length === 0
            ? false
            : allUrls.every((url) => !!getDomain(url))

        // Block link creation if no valid URLs are present
        // This rule also blocks http:// URLs. Ignore it for email form
        if (
          formIsWebLinkForm(formVals) &&
          showLanding &&
          !allUrlsValid &&
          !ignoreWarnings?.includes('no-url')
        ) {
          setCreateLinkWarning({
            type: 'no-url',
            fullLinks,
            ignoreWarnings,
          })

          logAction({
            variables: {
              action: 'track-error-missing-landing-page',
              functionName: 'addCodes',
              pagePath: '/track/create-links',
              websiteSection: 'track',
            },
          })

          return {
            success: false,
            fullLinks,
          }
        }

        // Check if any URLs contain anchors
        const urlsContainAnchors: boolean[] = []

        allUrls.forEach((url) => {
          const anchorPresent = url.match(/#/g)

          // Need to ensure `#` character is not part of URL routing
          // `#` is not always an anchor
          urlsContainAnchors.push(
            !!(
              anchorPresent &&
              !(anchorPresent.length === 1 && url.indexOf('/#/') !== -1)
            ),
          )
        })

        // Block link creation if any URLs contain anchors, unless that warning should be ignored
        if (
          !ignoreWarnings?.includes('has-anchor') &&
          urlsContainAnchors.find((url) => !!url)
        ) {
          setCreateLinkWarning({
            type: 'has-anchor',
            fullLinks,
            ignoreWarnings,
          })

          logAction({
            variables: {
              action: 'track-error-anchor-warning',
              functionName: 'addCodes',
              pagePath: '/track/create-links',
              websiteSection: 'track',
            },
          })

          return {
            success: false,
            fullLinks,
          }
        }

        if (useAppLinks || linkType === 'shortLink') {
          // Shortlink being created but no URL present - don't create link
          if (
            linkType === 'shortLink' &&
            !showLanding &&
            !ignoreWarnings?.includes('no-url-shortlink')
          ) {
            setCreateLinkWarning({
              type: 'no-url-shortlink',
              fullLinks,
              ignoreWarnings,
            })

            logAction({
              variables: {
                action: 'track-error-missing-landing-page',
                functionName: 'addCodes',
                pagePath: '/track/create-links',
                websiteSection: 'track',
              },
            })

            return {
              success: false,
              fullLinks,
            }
          }
        }

        // Check URLs are all shorter than max length rule
        if (showLanding) {
          const maxUrlLengthRule = validationChecks.find(
            (item) => item.name === 'LIMIT_URL_LENGTH',
          )

          const maxUrlLength =
            maxUrlLengthRule &&
            maxUrlLengthRule.enabled &&
            maxUrlLengthRule.value
              ? parseInt(maxUrlLengthRule.value || '', 10)
              : parseInt(
                  defaultValidationChecksValues.LIMIT_URL_LENGTH || '',
                  10,
                )

          const urlLengths = fullLinks.map(({ urlLength }) => urlLength)

          const longestUrl = Math.max(...urlLengths)

          if (longestUrl > maxUrlLength) {
            setCreateLinkWarning({
              type: 'invalid-landing-page-length',
              fullLinks,
              characterLimit: maxUrlLength,
              charactersOverLimit: longestUrl - maxUrlLength,
            })

            logAction({
              variables: {
                action: 'track-error-landing-page-length',
                functionName: 'addCodes',
                pagePath: '/track/create-links',
                websiteSection: 'track',
                extra: JSON.stringify({
                  linkOverLimitBy: longestUrl - maxUrlLength,
                }),
              },
            })

            return {
              success: false,
              fullLinks: [],
            }
          }
        }

        const maxQueryLengthRule = validationChecks.find(
          (item) => item.name === 'LIMIT_QUERY_LENGTH',
        )
        const maxQueryLength =
          maxQueryLengthRule &&
          maxQueryLengthRule.enabled &&
          maxQueryLengthRule.value
            ? parseInt(maxQueryLengthRule.value || '', 10)
            : parseInt(
                defaultValidationChecksValues.LIMIT_QUERY_LENGTH || '',
                10,
              )

        const queryLengths = fullLinks.map(({ queryLength }) => queryLength)

        const longestQuery = Math.max(...queryLengths)

        // Check URL queries are all shorter than max length rule
        if (longestQuery > maxQueryLength) {
          setCreateLinkWarning({
            type: 'invalid-query-length',
            fullLinks,
            characterLimit: maxQueryLength,
            charactersOverLimit: longestQuery - maxQueryLength,
          })

          logAction({
            variables: {
              action: 'track-error-landing-query-length',
              functionName: 'addCodes',
              pagePath: '/track/create-links',
              websiteSection: 'track',
              extra: JSON.stringify({
                queryOverLimitBy: longestQuery - maxQueryLength,
              }),
            },
          })

          return {
            success: false,
            fullLinks: [],
          }
        }

        return { success: true, fullLinks }
      } catch {
        setCreateLinkError(true)

        return {
          success: false,
          fullLinks: [],
        }
      }
    },
    [linkType, formSubmissionState, addAutoAddedParams, generatedStructure],
  )

  /** Calls addCodes mutation and uses shortLink aliases ans UplifterIDs */
  const createLinks = useCallback(
    async (
      fullLinks: FullLinkResult[],
      appLink?: {
        appGroupID: string
        redirectContext?: string[]
        fallbackUrl?: string
      },
      bulkStart?: string,
      emailSource?: EmailSources,
    ): Promise<{
      success: boolean
      fullLinks: FullLinkResult[]
      createdLinks?: MinimalCodeList
    }> => {
      if (!generatedStructure) {
        return {
          success: false,
          fullLinks: [],
        }
      }

      const { paramDefs } = generatedStructure

      try {
        const codeList = fullLinks.map(({ tC, lP, fC, pDfs, shortLinkID }) => ({
          fC,
          lP,
          tC,
          pDfs: pDfs.map(({ submitValue }) => submitValue),
          shortLinkID: shortLinkID || '',
        }))

        // Check recently created links to confirm alias is still available
        if (useAppLinks || linkType === 'shortLink') {
          const newShortLinkIDs = codeList.map(({ shortLinkID }) => shortLinkID)

          // Read cached links to check for duplicates
          const cachedData =
            apolloClient.cache.readFragment({
              id: `MinimalCodeList:{"filteredByCurrentUser":true,"dimensionFilter":null,"sortDirection":"DESC","sortField":"createdTime","versionHistoryLinkID":null,"isCodeIDList":false}`,
              fragment: userShortLinks,
            })?.shortLink || []

          if (cachedData.length > 0) {
            const usedAliases = cachedData.filter((sL) => sL !== '')

            const hasDuplicateAliases = newShortLinkIDs.find((sL) =>
              usedAliases.find((usedSl) => {
                const usedAlias = usedSl.split('/').pop()

                return (
                  usedSl.includes(
                    getCustomDomainID(selectedCustomLinkDomain.domainValue) ||
                      '',
                  ) && usedAlias === sL
                )
              }),
            )

            // Don't create new links - there are duplicates
            if (hasDuplicateAliases) {
              setCustomLinkAliasDetails((curr) => ({
                ...curr,
                error: true,
              }))

              return {
                success: false,
                fullLinks,
              }
            }
          }

          // Check localStorage item still exists
          const aliasesNotInLocalStorage: string[] = []

          const savedAliases: string[] = ((getUserData(
            selectedCustomLinkDomain.domainID,
          ) || []) as SavedCustomLink[]).map(({ shortLinkID }) => shortLinkID)

          newShortLinkIDs.forEach((shortLinkID) => {
            if (!savedAliases.includes(shortLinkID)) {
              aliasesNotInLocalStorage.push(shortLinkID)
            }
          })

          // This can happen e.g. when a user has multiple tabs open
          if (aliasesNotInLocalStorage.length > 0) {
            let allAliasesStillValid = true

            // Revalidate the aliases
            // eslint-disable-next-line no-restricted-syntax
            for (const alias of aliasesNotInLocalStorage) {
              if (!allAliasesStillValid) break

              // eslint-disable-next-line no-continue
              if (!alias) continue

              const {
                data: validateShortLinkData,
                // eslint-disable-next-line no-await-in-loop
              } = await validateCustomLinkAlias({
                variables: {
                  testCandidate: alias,
                  customDomainID:
                    linkType === 'shortLink'
                      ? getCustomDomainID(selectedCustomLinkDomain.domainID)
                      : undefined,
                  deepLinkServiceID: useAppLinks
                    ? selectedCustomLinkDomain.domainID
                    : undefined,
                },
              })

              if (validateShortLinkData) {
                const {
                  isAvailable,
                } = validateShortLinkData.track.validateShortLinkCandidate

                if (!isAvailable) allAliasesStillValid = false
              }
            }

            // The aliases have become unavailable since they were generated
            if (!allAliasesStillValid) {
              setCustomLinkAliasDetails((curr) => ({
                ...curr,
                error: true,
              }))

              return {
                success: false,
                fullLinks,
              }
            }
          }
        }

        let deepLinkConfig: DeepLinkInput | null = null

        if (appLink) {
          const { data: appGroupData } = await fetchAppGroupDetails()

          if (!appGroupData) {
            throw new Error('App group not found')
          }

          const fullAppGroupDetails = appGroupData.track.deepLinkQueries.listAppGroups.find(
            (appGroup) => appGroup.appGroupID === appLink.appGroupID,
          )

          if (!fullAppGroupDetails) {
            throw new Error('App group not found')
          }

          const {
            appGroupID,
            deepLinkServiceID,
            redirectWeb,
          } = fullAppGroupDetails

          deepLinkConfig = {
            appGroupID,
            deepLinkServiceID,
            redirectContext: appLink.redirectContext
              ? appLink.redirectContext
              : undefined,
            fallBackURL: appLink.fallbackUrl || redirectWeb,
          }
        }

        const { data: addCodesData } = await addCode({
          variables: {
            customDomainID: useAppLinks
              ? undefined
              : getCustomDomainID(selectedCustomLinkDomain.domainID),
            codeList,
            deepLinkConfig: deepLinkConfig || undefined,
            bulkStart,
          },
        })

        if (useAppLinks || linkType === 'shortLink') {
          useAliases(
            bulkStart
              ? [bulkStart]
              : fullLinks
                  .map(({ shortLinkID }) => shortLinkID || '')
                  .filter((link) => link !== ''),
            selectedCustomLinkDomain.domainID,
          )

          setCustomLinkAliasDetails((curr) => ({ ...curr, key: nanoid() }))
        }

        // TODO (Stretch): Update the cached object's value for currentTotal instead of refetching
        // Refetch totals for UplifterID
        if (
          (!dataSource || dataSource.connectionSource !== 'adobe') &&
          uplifterIdData?.isEnabled
        ) {
          const { data, error } = await getUniqueID({
            variables: { fieldType: 'up_id' },
          })

          if (error) {
            setUniqueIDError(true)
          } else if (data) {
            setUplifterIdData(data.track.currentSequentialCodeID)
          }
        }

        // Refetch generator to update uniqueIDTotal values for all unique parameters
        if (paramDefs.find((param) => param.fieldType === 'unique')) {
          await refetchGenerator()
        }

        let action = 'add-'

        if (fullLinks.length > 1) {
          action += 'multiple-codes'
        } else {
          action += 'code'
        }

        if (emailSource) {
          action += `-email-${emailSource}`
        }

        if (linkType !== 'basic') {
          action += `-${linkType === 'appLink' ? 'app' : 'short'}-link`
        }

        logAction({
          variables: {
            action,
            functionName: 'addCodes',
            pagePath: '/track/create-links',
            extra: fullLinks
              .filter(({ selected }) => selected)
              .length.toString(),
            websiteSection: 'track',
          },
        })

        // GA Tracking
        // @ts-ignore
        if (window.dataLayer && window.dataLayer.push) {
          // @ts-ignore
          window.dataLayer.push({
            event: 'create_campaign_link',
            link_creation_type: action,
            link_count: fullLinks.filter(({ selected }) => selected).length,
          })
        }

        // Check if onboarding step `createCampaignLink` is completed
        if (
          !userOnboardingSections.find(
            (section) => section.onboardingSectionID === 'createCampaignLink',
          )?.sectionCompleted
        ) {
          // Updates the backend cache for onboarding state
          updateOnboardingSection('createCampaignLink', 'user')
        }

        return {
          success: true,
          fullLinks,
          createdLinks: addCodesData?.addCodes,
        }
      } catch {
        setCreateLinkError(true)

        return {
          success: false,
          fullLinks: [],
        }
      }
    },
    [
      apolloClient,
      useAppLinks,
      linkType,
      selectedCustomLinkDomain,
      userOnboardingSections,
      generatedStructure,
    ],
  )

  return {
    linkType,
    setLinkType,
    selectedCustomLinkDomain,
    customLinkAliasDetails,
    setCustomLinkAliasDetails,
    submitVCardForm,
    submitVEventForm,
    submitForm,
    createLinks,
    createLinkWarning,
    setCreateLinkWarning,
    createLinkLoading: checkingUplifterIDs || addingCodes,
    uplifterIdLoading:
      dataSource && dataSource.connectionSource !== 'adobe' && !uplifterIdData,
    uniqueIDError,
    createLinkError,
  }
}

export default useTrackCreateFormSubmit
