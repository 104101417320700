import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useLazyQuery, useMutation, useReactiveVar } from '@apollo/client'
import { useHistory } from 'react-router-dom'
import { MultiValue } from 'react-select'
import moment from 'moment'
import classNames from 'classnames'
import _ from 'lodash'

import Button, { DeleteButton } from './button'
import StyledDatePicker from './date-picker'
import FileDragAndDrop from './file-drag-and-drop'
import { FormField, FormLabel, FormRow } from './form'
import Input, { ClickEditInput } from './input'
import { MultiInputField } from './input-v2'
import Link from './link'
import { Preloader } from './loader'
import Modal from './modal'
import { RequestFieldModal } from './request-field'
import SelectBox from './select-box'
import Tooltip from './tooltip'
import { BoxedText, ErrorMessage, NoteText } from './typography'
import { currentUserDetails } from '../api/apollo/variables'
import {
  deleteCreativeLink,
  getCreativeList,
} from '../api/graphql/track-create-client'
import { uploadParameterCreatives } from '../api/REST/track-client'
import { brandName } from '../core/constants'
import { isAdminUser } from '../helpers'
import { getUserData, saveUserData } from '../helpers/local-client'
import { GeneratorParameterValues } from '../helpers/track-create'
import {
  defaultValidationChecksFull,
  defaultValidationChecksValues,
} from '../helpers/track-module'
import useLogAction from '../hooks/useLogAction'
import { UpdateFormOptions } from '../hooks/useTrackCreateGeneratorForm'
import styles from '../styles/track-create-parameter-fields.module.scss'
import {
  GetCampaignCodeGeneratorQuery,
  ParamDef,
} from '../__gql-types__/graphql'

interface DeleteCreativeModalProps {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  creativeID: string
}

const DeleteCreativeModal = ({
  setIsOpen,
  creativeID,
}: DeleteCreativeModalProps) => {
  const [deleteCreative, { loading: deletingCreative }] = useMutation(
    deleteCreativeLink,
  )

  return (
    <Modal
      setIsOpen={setIsOpen}
      modalHeader="Delete creative"
      yesText="Delete"
      yesButtonLoading={deletingCreative}
      onYes={async () => {
        await deleteCreative({
          variables: {
            creativeID,
          },
        })

        setIsOpen(false)
      }}
    >
      <p>
        Are you sure you want to delete this creative? It will no longer be
        shown on the <BoxedText>Report &gt; Marketing journeys</BoxedText> page.
      </p>
    </Modal>
  )
}

interface AddCreativeModalProps {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  param: GetCampaignCodeGeneratorQuery['campaignCodeGenerator']['paramDefs'][0]
  currentOptions: { optionName: string; optionID: string }[]
  addParamValueToForm?: (newValue: string) => void
}

const AddCreativeModal = ({
  setIsOpen,
  param,
  currentOptions,
  addParamValueToForm,
}: AddCreativeModalProps) => {
  const { fieldID, fieldName, fieldType, lengthLimit = 45 } = param

  const [
    fetchExistingCreatives,
    { data: existingCreativesData, loading: fetchingExistingCreatives },
  ] = useLazyQuery(getCreativeList, {
    variables: {
      parameterID: fieldID,
    },
    fetchPolicy: 'network-only',
  })

  // Used to pick which existing option to upload for
  const [uploadForOption, setUploadForOption] = useState<string | null>(
    currentOptions.length === 1 ? currentOptions[0].optionID : null,
  )

  const [uploadSuccess, setUploadSuccess] = useState('')
  const [uploadError, setUploadError] = useState('')
  const [showDeleteCreativeModal, setShowDeleteCreativeModal] = useState(false)
  const [creativeIDToDelete, setCreativeIDToDelete] = useState('')

  // Only need to fetch existing creatives if the optionID and optionName are set
  useEffect(() => {
    if (currentOptions.length === 0 || showDeleteCreativeModal) return

    fetchExistingCreatives()
  }, [currentOptions, uploadSuccess, showDeleteCreativeModal])

  const existingCreativesPerOption = useMemo(() => {
    if (!existingCreativesData) return []

    const fulLCreativeList = existingCreativesData.report.getCreativeList

    return currentOptions.map(({ optionName, optionID }) => ({
      optionID,
      optionName,
      creativeList: fulLCreativeList.filter(
        (creative) => creative.optionID === optionID,
      ),
    }))
  }, [currentOptions, existingCreativesData])

  const selectedOptionExistingCreativesCount = useMemo(() => {
    if (!uploadForOption) return 0

    const selectedOption = existingCreativesPerOption.find(
      ({ optionID }) => optionID === uploadForOption,
    )

    return selectedOption?.creativeList.length || 0
  }, [existingCreativesPerOption, uploadForOption])

  return (
    <>
      <Modal
        setIsOpen={setIsOpen}
        modalHeader="Upload content (optional)"
        onYes={uploadSuccess ? () => setIsOpen(false) : undefined}
      >
        <p>
          Display ad creatives (image or video) in{' '}
          <BoxedText>
            <Link href="/report/marketing-journeys">
              Report &gt; Marketing journeys
            </Link>
          </BoxedText>
          .
        </p>
        <p>
          Uploads are saved to the {fieldName} so you won't need to upload it
          again.
        </p>
        {fieldType === 'input' && (
          <NoteText label="Tip:" sameLine>
            <span>Leave {fieldName} empty to use the uploaded file name.</span>
          </NoteText>
        )}
        {fetchingExistingCreatives ? (
          <Preloader />
        ) : (
          <>
            {existingCreativesPerOption.length > 0 && (
              <>
                {existingCreativesPerOption.map(
                  ({ optionName, creativeList }, optionIndex) => {
                    if (creativeList.length === 0)
                      return <FormRow key={optionName} />

                    return (
                      <FormRow
                        key={optionName}
                        bottomBorder={
                          optionIndex === existingCreativesPerOption.length - 1
                        }
                      >
                        <FormLabel>
                          <p style={{ margin: 0 }}>
                            <strong>{optionName}</strong>
                          </p>
                          <span>
                            ({creativeList.length} existing creative
                            {creativeList.length === 1 ? '' : 's'}):
                          </span>
                        </FormLabel>
                        <FormField>
                          <div className={styles.existingCreativesContainer}>
                            {creativeList.map(({ blobURL, creativeID }) => {
                              if (!blobURL) return null

                              const fileExtension =
                                blobURL.split('.').pop() || ''

                              let isImage = true

                              if (
                                ['mp4', 'webm', 'ogg'].includes(fileExtension)
                              ) {
                                isImage = false
                              }

                              return (
                                <div
                                  key={creativeID}
                                  className={styles.existingCreative}
                                >
                                  {isImage ? (
                                    <img
                                      className={styles.creative}
                                      src={blobURL}
                                      alt={creativeID}
                                    />
                                  ) : (
                                    // eslint-disable-next-line jsx-a11y/media-has-caption
                                    <video
                                      className={styles.creative}
                                      controls={false}
                                      src={blobURL}
                                    />
                                  )}
                                  <div className={styles.hoverBackground} />
                                  <DeleteButton
                                    className={styles.deleteButton}
                                    onPress={() => {
                                      setShowDeleteCreativeModal(true)
                                      setCreativeIDToDelete(creativeID)
                                    }}
                                  />
                                </div>
                              )
                            })}
                          </div>
                        </FormField>
                      </FormRow>
                    )
                  },
                )}
                <FormRow includePaddingBottom>
                  <FormLabel>
                    <Tooltip
                      id="upload-for-tooltip"
                      useIcon
                      tooltipMessage="Optionally select an existing value in the form to add the creative to. Leave empty to add the uploaded file's name as a new value."
                    >
                      Upload for:
                    </Tooltip>
                  </FormLabel>
                  <FormField>
                    <SelectBox
                      labelKey="optionName"
                      valueKey="optionID"
                      placeholder="None - add a new value"
                      isClearable
                      value={existingCreativesPerOption.find(
                        ({ optionID }) => optionID === uploadForOption,
                      )}
                      options={existingCreativesPerOption}
                      onChange={(newValue) => {
                        setUploadForOption(newValue?.optionID || null)
                      }}
                    />
                    {selectedOptionExistingCreativesCount >= 5 && (
                      <ErrorMessage>
                        You cannot upload more than 5 creatives for this value.
                      </ErrorMessage>
                    )}
                  </FormField>
                </FormRow>
              </>
            )}
            {selectedOptionExistingCreativesCount < 5 && (
              <FileDragAndDrop
                success={uploadSuccess || undefined}
                maxFileSize={5 * 1024 * 1024}
                uploadError={uploadError}
                acceptedFileTypes={[
                  'image/jpeg',
                  'image/png',
                  'image/gif',
                  'video/mp4',
                  'video/webm',
                  'video/ogg',
                ]}
                onDrop={async (files) => {
                  if (files.length === 0) return

                  setUploadSuccess('')

                  try {
                    const fileName = files[0].name
                      .split('.')
                      .slice(0, -1)
                      .join('.')
                      .toLowerCase()
                      .replace(/[^a-z0-9]/g, '-')
                      .slice(0, lengthLimit as number)

                    const res = await uploadParameterCreatives({
                      parameterID: fieldID,
                      optionID: uploadForOption || fileName,
                      optionName: uploadForOption || fileName,
                      file: files[0],
                    })

                    if (res !== true) {
                      throw new Error()
                    }

                    // Set the field value accordingly - only applies to freetext fields
                    if (!uploadForOption && addParamValueToForm) {
                      addParamValueToForm(fileName)
                    }

                    setUploadSuccess(
                      `Creative successfully uploaded${
                        currentOptions.length > 0
                          ? ''
                          : ` and value '${fileName}' added to parameter`
                      }.`,
                    )
                  } catch {
                    setUploadError(
                      'An error occurred while uploading the file.',
                    )
                  }
                }}
              />
            )}
          </>
        )}
      </Modal>
      {showDeleteCreativeModal && creativeIDToDelete && (
        <DeleteCreativeModal
          setIsOpen={setShowDeleteCreativeModal}
          creativeID={creativeIDToDelete}
        />
      )}
    </>
  )
}

interface ParameterFieldProps {
  param: GetCampaignCodeGeneratorQuery['campaignCodeGenerator']['paramDefs'][0]
  allowMultipleValues?: boolean
  savedValue?: string[]
  onChange: (newValue: string[], options?: UpdateFormOptions) => void
  hasSubmitError?: boolean
  updateValueOnly?: boolean
}

interface ParameterInputFieldProps extends ParameterFieldProps {
  validationChecks: GetCampaignCodeGeneratorQuery['campaignCodeGenerator']['validationChecks']
  /** Hides pill for multi-input field */
  noPillForSingleValue?: boolean
  onEnterKey?: (e: React.KeyboardEvent<HTMLInputElement>) => void
}

type FieldValidationError =
  | 'noSpaces'
  | 'lowerCase'
  | 'noSpecialCharacters'
  | 'exceedsParamLimit'
  | 'valueIsTooLong'

/** Some validation errors should be grey instead of error-red */
const isPassiveError = (input: FieldValidationError | null) => {
  return (
    !!input && ['noSpaces', 'lowerCase', 'noSpecialCharacters'].includes(input)
  )
}

interface InputFieldValidationMessageProps {
  errorType: FieldValidationError
  replaceSpacesValue: string
  lengthLimit: number
  maxQueryLength: number
  maxUrlLength: number
}

const InputFieldValidationMessage = ({
  errorType,
  replaceSpacesValue,
  lengthLimit,
  maxQueryLength,
  maxUrlLength,
}: InputFieldValidationMessageProps) => {
  const { workspaceID, userPermission } = useReactiveVar(currentUserDetails)

  const hasSeenReplaceSpacesMessage =
    errorType !== 'noSpaces'
      ? null
      : getUserData(workspaceID)?.hasSeenReplaceSpacesMessage || false

  switch (errorType) {
    case 'noSpaces':
      return (
        <p className={styles.dropdownNote}>
          {!hasSeenReplaceSpacesMessage && isAdminUser(userPermission) && (
            <span>
              Spaces are replaced with '{replaceSpacesValue}'.{' '}
              <Link
                href="/track/edit-parameters-and-rules"
                onPress={() => {
                  // Prevent message appearing again for this user
                  saveUserData(
                    { hasSeenReplaceSpacesMessage: true },
                    workspaceID,
                  )
                }}
              >
                Edit
              </Link>
            </span>
          )}
        </p>
      )
    case 'lowerCase':
      return (
        <p className={styles.dropdownNote}>
          Forced to lowercase for readability.
        </p>
      )
    case 'noSpecialCharacters':
      return (
        <p className={styles.dropdownNote}>
          Special characters (?&=) are not allowed.
        </p>
      )
    case 'exceedsParamLimit':
      return (
        <ErrorMessage>
          Value exceeds character limit ({lengthLimit})
          {isAdminUser(userPermission) ? (
            <>
              . Edit the limit{' '}
              <Link href="/track/edit-parameters-and-rules">here</Link>
            </>
          ) : (
            ''
          )}
          .
        </ErrorMessage>
      )
    case 'valueIsTooLong':
      return (
        <ErrorMessage>
          Input is too long (max query length: {maxQueryLength}, max full link
          length: {maxUrlLength}).
        </ErrorMessage>
      )
    default:
      return 'Validation rules have been applied to your input.'
  }
}

const ParameterInputField = ({
  param,
  allowMultipleValues = true,
  savedValue,
  onChange,
  validationChecks,
  hasSubmitError,
  noPillForSingleValue,
  onEnterKey,
  updateValueOnly,
}: ParameterInputFieldProps) => {
  const {
    fieldID,
    fieldName,
    required,
    metaParameter,
    forceLowerCase = true,
    isCreativeField,
    lengthLimit = 45,
  } = param

  const logAction = useLogAction()

  const [currentValue, setCurrentValue] = useState<
    { value: string; label: string }[]
  >(
    savedValue
      ?.map((v) => ({ value: v, label: v }))
      .filter(({ value: v }) => v !== '') || [],
  )

  const [showAddCreativeModal, setShowAddCreativeModal] = useState(false)
  const [fieldValidationErrors, setFieldValidationErrors] = useState<
    FieldValidationError[]
  >([])
  const [errorTracked, setErrorTracked] = useState<FieldValidationError[]>([])

  // Remove field validation error when field is cleared
  useEffect(() => {
    if (currentValue.length === 0) {
      setFieldValidationErrors([])
    }
  }, [currentValue])

  // Update field value on e.g. clearing the form
  useEffect(() => {
    if (!savedValue || savedValue.length === 0) {
      setCurrentValue([])
    } else if (savedValue.length > currentValue.length) {
      setCurrentValue(
        savedValue
          ?.map((v) => ({ value: v, label: v }))
          .filter(({ value: v }) => v !== '') || [],
      )
    }
  }, [savedValue])

  /** Get formatting rules */
  const {
    replaceSpaces,
    globalLowerCase,
    noSpecialCharacters,
    maxUrlLength,
    maxQueryLength,
  } = useMemo(() => {
    const replaceSpacesWith = validationChecks.find(
      (check) => check.name === 'REPLACE_SPACES_WITH',
    ) || { enabled: true, name: 'REPLACE_SPACES_WITH', value: '_' }

    const lowerCaseCheck = validationChecks.find(
      (check) => check.name === 'ALL_LOWER_CASE',
    )
    const noSpecialCharsCheck = validationChecks.find(
      (check) => check.name === 'NO_SPECIAL_CHARS',
    )
    const urlLength = validationChecks.find(
      (check) => check.name === 'LIMIT_URL_LENGTH',
    )?.value
    const queryLength = validationChecks.find(
      (check) => check.name === 'LIMIT_QUERY_LENGTH',
    )?.value

    let replaceSpacesValue = replaceSpacesWith.value

    // If value is not a string, use the default value. This allows spaces to be replaced with empty string (falsy)
    if (!replaceSpacesValue && replaceSpacesValue !== '') {
      replaceSpacesValue =
        defaultValidationChecksFull
          .find(({ category }) => category === 'Parameter validation')
          ?.validationChecks.find(
            (check) => check.name === 'REPLACE_SPACES_WITH',
          )?.value || '_'
    }

    return {
      replaceSpaces: {
        enabled: replaceSpacesWith?.enabled,
        value: replaceSpacesValue,
      },
      globalLowerCase: lowerCaseCheck ? lowerCaseCheck.enabled : true,
      noSpecialCharacters: noSpecialCharsCheck
        ? noSpecialCharsCheck.enabled
        : true,
      maxUrlLength: parseInt(
        urlLength || defaultValidationChecksValues.LIMIT_URL_LENGTH,
        10,
      ),
      maxQueryLength: parseInt(
        queryLength || defaultValidationChecksValues.LIMIT_QUERY_LENGTH,
        10,
      ),
    }
  }, [validationChecks])

  // TODO: Extract this to static function with validation rules as input
  /** Formats text fields according to generator rules */
  const formatInput = useCallback(
    (inputValue: string) => {
      setFieldValidationErrors([])

      let outputValue = inputValue
      const newFieldValidationErrors: FieldValidationError[] = []

      // Lowercase rule
      if ((globalLowerCase || forceLowerCase) && /[A-Z]/g.test(inputValue)) {
        outputValue = outputValue.toLowerCase()

        newFieldValidationErrors.push('lowerCase')

        if (!errorTracked.includes('lowerCase')) {
          // Ensure this does not fire on every character. Reset on blur
          logAction({
            variables: {
              action: 'track-error-parameter-casing-error',
              extra: '',
              websiteSection: 'track-create',
              functionName: 'updateValue',
              pagePath: '/track/create-links',
            },
          })
        }
      }

      /** Check length of input values, excluding separator characters */
      const separatedValues = allowMultipleValues
        ? outputValue.split(/[,;\n\t]/).filter((v) => v.length > 0)
        : [outputValue]

      const longestVal = Math.max(...separatedValues.map((v) => v.length))

      // Check input length against limits
      if (longestVal > (lengthLimit as number)) {
        newFieldValidationErrors.push('exceedsParamLimit')

        if (!errorTracked.includes('exceedsParamLimit')) {
          // Ensure this does not fire on every character. Reset on blur
          logAction({
            variables: {
              action: 'track-error-parameter-exceeds-limit',
              extra: '',
              websiteSection: 'track-create',
              functionName: 'updateValue',
              pagePath: '/track/create-links',
            },
          })
        }
      } else if (
        !metaParameter &&
        longestVal > Math.min(maxUrlLength, maxQueryLength)
      ) {
        newFieldValidationErrors.push('valueIsTooLong')

        if (!errorTracked.includes('valueIsTooLong')) {
          // Ensure this does not fire on every character. Reset on blur
          logAction({
            variables: {
              action: 'track-error-parameter-value-too-long',
              extra: '',
              websiteSection: 'track-create',
              functionName: 'updateValue',
              pagePath: '/track/create-links',
            },
          })
        }
      }

      // Only lowercase and param length rules should apply to meta parameters - stop here
      if (metaParameter) {
        if (outputValue.length > 0 && newFieldValidationErrors.length > 0) {
          setFieldValidationErrors(newFieldValidationErrors)
        }

        setErrorTracked((curr) => [
          ...new Set([...curr, ...newFieldValidationErrors]),
        ])

        return outputValue
      }

      // Spaces rule
      if (replaceSpaces.enabled && /\s/.test(inputValue)) {
        outputValue = outputValue.replaceAll(/\s/g, replaceSpaces.value)

        // Show message about spaces being replaced
        newFieldValidationErrors.push('noSpaces')

        if (!errorTracked.includes('noSpaces')) {
          // Ensure this does not fire on every character. Reset on blur
          logAction({
            variables: {
              action: 'track-error-parameter-spaces-error',
              extra: '',
              websiteSection: 'track-create',
              functionName: 'updateValue',
              pagePath: '/track/create-links',
            },
          })
        }
      }

      // No special characters rule
      if (noSpecialCharacters) {
        const specialCharsRegex = new RegExp(
          defaultValidationChecksValues.NO_SPECIAL_CHARS,
          'g',
        )

        if (specialCharsRegex.test(inputValue)) {
          outputValue = outputValue.replaceAll(specialCharsRegex, '')

          newFieldValidationErrors.push('noSpecialCharacters')

          if (!errorTracked.includes('noSpecialCharacters')) {
            // Ensure this does not fire on every character. Reset on blur
            logAction({
              variables: {
                action: 'track-error-parameter-special-chars',
                extra: '',
                websiteSection: 'track-create',
                functionName: 'updateValue',
                pagePath: '/track/create-links',
              },
            })
          }
        }
      }

      if (outputValue.length > 0 && newFieldValidationErrors.length > 0) {
        setFieldValidationErrors(newFieldValidationErrors)
      }

      setErrorTracked((curr) => [
        ...new Set([...curr, ...newFieldValidationErrors]),
      ])

      return outputValue
    },
    [
      allowMultipleValues,
      metaParameter,
      errorTracked,
      replaceSpaces,
      globalLowerCase,
      forceLowerCase,
      noSpecialCharacters,
      lengthLimit,
      maxUrlLength,
      maxQueryLength,
    ],
  )

  if (updateValueOnly) {
    return (
      <>
        <ClickEditInput
          id={fieldID}
          name={fieldName}
          className={classNames(styles.inputField, {
            [styles.hideBorder]: updateValueOnly,
          })}
          value={currentValue.length > 0 ? currentValue[0].value : ''}
          beforeChange={formatInput}
          onChange={(newVal) => {
            setCurrentValue(newVal ? [{ value: newVal, label: newVal }] : [])

            onChange(
              newVal ? [newVal] : [],
              (required && !newVal) ||
                (!metaParameter &&
                  newVal.length > Math.min(maxUrlLength, maxQueryLength))
                ? { errorsToAdd: [fieldID] }
                : { errorsToRemove: [fieldID] },
            )
          }}
        />
        {fieldValidationErrors.map((errorType) => (
          <InputFieldValidationMessage
            errorType={errorType}
            replaceSpacesValue={replaceSpaces.value}
            lengthLimit={lengthLimit as number}
            maxQueryLength={maxQueryLength}
            maxUrlLength={maxUrlLength}
          />
        ))}
      </>
    )
  }

  return (
    <>
      {allowMultipleValues ? (
        <MultiInputField
          id={fieldID}
          containerClassName={styles.formInputContainer}
          className={classNames(styles.inputField, {
            [styles.hasCreativeButton]: isCreativeField,
          })}
          placeholder={`${
            !required ? '(Optional) ' : ''
          }Type ${fieldName.toLowerCase()}${
            isCreativeField ? ' or upload' : ''
          }`}
          isClearable
          error={
            hasSubmitError ||
            !!fieldValidationErrors.find(
              (errorType) => !isPassiveError(errorType),
            )
          }
          value={currentValue}
          setValue={setCurrentValue}
          // Only hide pill if none of the parameter fields have more than one value
          noPillForSingleValue={noPillForSingleValue}
          /** Formats text fields according to generator rules */
          formatInputValue={formatInput}
          duplicateCheckerIgnoreString="https://"
          modifyInputValues={(values) => {
            // Block parameters that are too long
            const blockedValues = values.filter(({ value }) => {
              return (
                value.length > (lengthLimit as number) ||
                (!metaParameter &&
                  value.length > Math.min(maxUrlLength, maxQueryLength))
              )
            })

            const updatedValues = values.filter(({ value }) => {
              return !(
                value.length > (lengthLimit as number) ||
                (!metaParameter &&
                  value.length > Math.min(maxUrlLength, maxQueryLength))
              )
            })

            return { allowedValues: updatedValues, blockedValues }
          }}
          // Save values to localStorage
          customOnChange={(newValues) =>
            onChange(
              newValues,
              required && newValues.length === 0
                ? { errorsToAdd: [fieldID] }
                : { errorsToRemove: [fieldID] },
            )
          }
          addInputOnBlur
          onBlur={() => setErrorTracked([])}
        >
          {isCreativeField && (
            <Button
              variant="secondary"
              className={styles.addCreative}
              onPress={() => setShowAddCreativeModal(true)}
            >
              Upload
            </Button>
          )}
        </MultiInputField>
      ) : (
        <div className={styles.formInputContainer}>
          <Input
            id={fieldID}
            name={fieldName}
            className={classNames(styles.inputField, {
              [styles.hasCreativeButton]: isCreativeField,
            })}
            placeholder={`${
              !required ? '(Optional) ' : ''
            }Type ${fieldName.toLowerCase()}${
              isCreativeField ? ' or upload' : ''
            }`}
            showClear
            error={
              hasSubmitError ||
              !!fieldValidationErrors.find(
                (errorType) => !isPassiveError(errorType),
              )
            }
            value={currentValue.length > 0 ? currentValue[0].value : ''}
            beforeChange={formatInput}
            onValueChange={(newVal) => {
              setCurrentValue(newVal ? [{ value: newVal, label: newVal }] : [])

              onChange(
                newVal ? [newVal] : [],
                (required && !newVal) ||
                  (!metaParameter &&
                    newVal.length > Math.min(maxUrlLength, maxQueryLength))
                  ? { errorsToAdd: [fieldID] }
                  : { errorsToRemove: [fieldID] },
              )
            }}
            onBlur={() => setErrorTracked([])}
            onPaste={(e) => {
              const newPastedText = formatInput(e.clipboardData.getData('Text'))

              setCurrentValue([{ value: newPastedText, label: newPastedText }])

              onChange(
                [newPastedText],
                (required && !newPastedText) ||
                  (!metaParameter &&
                    newPastedText.length >
                      Math.min(maxUrlLength, maxQueryLength))
                  ? { errorsToAdd: [fieldID] }
                  : { errorsToRemove: [fieldID] },
              )
            }}
            onKeyDown={onEnterKey}
          />
          {isCreativeField && (
            <Button
              variant="secondary"
              className={styles.addCreative}
              onPress={() => setShowAddCreativeModal(true)}
            >
              Upload
            </Button>
          )}
        </div>
      )}
      {fieldValidationErrors.map((errorType) => (
        <InputFieldValidationMessage
          errorType={errorType}
          replaceSpacesValue={replaceSpaces.value}
          lengthLimit={lengthLimit as number}
          maxQueryLength={maxQueryLength}
          maxUrlLength={maxUrlLength}
        />
      ))}
      {showAddCreativeModal && (
        <AddCreativeModal
          setIsOpen={setShowAddCreativeModal}
          param={param}
          currentOptions={currentValue.map(({ value }) => ({
            optionID: value,
            optionName: value,
          }))}
          addParamValueToForm={(newVal) => {
            const updatedFormValue = [
              ...new Set([...currentValue.map(({ value }) => value), newVal]),
            ]

            setCurrentValue(
              updatedFormValue.map((v) => ({ value: v, label: v })),
            )

            onChange(
              updatedFormValue,
              !metaParameter &&
                newVal.length > Math.min(maxUrlLength, maxQueryLength)
                ? { errorsToAdd: [fieldID] }
                : { errorsToRemove: [fieldID] },
            )
          }}
        />
      )}
    </>
  )
}

interface DropdownOption {
  __typename?: 'SelectField'
  hide: boolean
  optionID: string
  optionName: string
  optionValue: string
  optionFilter?: Array<{
    __typename?: 'OptionFilter'
    parentFieldID: string
    parentOptionIDs: Array<string>
  }> | null
}

interface GroupedDropdownOption {
  parentOptionID?: string
  label?: string
  options: DropdownOption[]
}

interface ParameterSelectFieldProps extends ParameterFieldProps {
  paramDefs: GetCampaignCodeGeneratorQuery['campaignCodeGenerator']['paramDefs']
  formValues: GeneratorParameterValues
  portal?: React.RefObject<HTMLDivElement>
}

const ParameterSelectField = ({
  param,
  allowMultipleValues = true,
  savedValue,
  paramDefs,
  formValues,
  onChange,
  hasSubmitError,
  updateValueOnly,
  portal,
}: ParameterSelectFieldProps) => {
  const { userPermission, workspaceID } = useReactiveVar(currentUserDetails)

  const isAdmin = isAdminUser(userPermission)

  const history = useHistory()

  const { fieldID, fieldName, required, selectFields, isCreativeField } = param

  const [currentValue, setCurrentValue] = useState(
    savedValue?.filter((v) => v !== '') || [],
  )

  // Update field value on e.g. clearing the form
  useEffect(() => {
    if (!savedValue || savedValue.length === 0) {
      setCurrentValue([])
    } else if (savedValue.length > currentValue.length) {
      setCurrentValue(savedValue?.filter((v) => v !== '') || [])
    }
  }, [savedValue])

  const [showAddCreativeModal, setShowAddCreativeModal] = useState(false)
  const [showRequestFieldModal, setShowRequestFieldModal] = useState(false)

  /** Filtered options based on parent-child dependencies */
  const availableOptions: GroupedDropdownOption[] = useMemo(() => {
    if (!selectFields) return []

    const groupedOptions: GroupedDropdownOption[] = []

    const allValidOptions = selectFields.filter(({ hide }) => !hide)

    const optionsWithParents = allValidOptions.filter(({ optionFilter }) => {
      if (optionFilter && optionFilter.length > 0) {
        const { parentOptionIDs } = optionFilter[0]
        return !!(parentOptionIDs && parentOptionIDs.length > 0)
      }

      return false
    })

    let remainingValidOptions = _.cloneDeep(allValidOptions)

    // Build groups for options with parent dependencies
    optionsWithParents.forEach((option) => {
      const { optionFilter } = option

      if (!optionFilter || optionFilter.length === 0) return

      const { parentFieldID } = optionFilter[0]

      // Do not create groups for workspace-restricted options
      if (parentFieldID === 'account') return

      const optionParentValues = optionFilter[0].parentOptionIDs

      optionParentValues.forEach((parentOptionID) => {
        // Grouping should not be applied
        if (
          !formValues[parentFieldID] ||
          formValues[parentFieldID].length === 0
        ) {
          return
        }

        const optionIndex = remainingValidOptions.findIndex(
          (o) => o.optionID === option.optionID,
        )

        // Prevent this option from being added to the 'Always visible' group
        if (optionIndex > -1) {
          remainingValidOptions.splice(optionIndex, 1)
        }

        if (formValues[parentFieldID].includes(parentOptionID)) {
          // Parent's value has been selected
          // This option should be visible
          const groupToUpdate = groupedOptions.find(
            (group) => group.parentOptionID === parentOptionID,
          )

          if (groupToUpdate) {
            // If group already exists, add option to it
            groupToUpdate.options.push(option)
          } else {
            // Else, create new group with this option
            const parentParamFull = paramDefs.find(
              (p) => p.fieldID === parentFieldID,
            )

            if (
              !parentParamFull ||
              !parentParamFull.selectFields ||
              parentParamFull.selectFields.length === 0
            ) {
              return
            }

            const parentOptionFull = parentParamFull.selectFields.find(
              (field) => field.optionID === parentOptionID,
            )

            groupedOptions.push({
              parentOptionID,
              label: `${parentParamFull.fieldName}: ${parentOptionFull?.optionName}`,
              options: [option],
            })
          }
        }
      })
    })

    // Filter out options that don't meet workspace criteria
    remainingValidOptions = remainingValidOptions.filter((option) => {
      const { optionFilter } = option

      if (
        optionFilter &&
        optionFilter.length > 0 &&
        optionFilter[0].parentFieldID === 'account'
      ) {
        return optionFilter[0].parentOptionIDs.includes(workspaceID)
      }

      return true
    })

    groupedOptions.push({
      label: 'Always shown / Not assigned',
      options: remainingValidOptions,
    })

    // No need to filter grouped options for multi-journey
    // Combinations are handled at a form level, not per parameter
    if (allowMultipleValues) {
      return groupedOptions
    }

    // Filter all groups based on values of children, if any
    return groupedOptions.map((group) => {
      // If parameter is a parent (other 'select' parameters have options that depend on it), it should only show valid options based on child values
      const childParams = paramDefs.filter((p) => {
        if (
          p.fieldID === fieldID ||
          p.fieldType !== 'select' ||
          !p.selectFields
        ) {
          return false
        }

        // Check if any select params have this param's ID in their optionFilters
        return p.selectFields.find(({ hide, optionFilter }) => {
          if (hide || !optionFilter || optionFilter.length === 0) {
            return false
          }

          const { parentFieldID, parentOptionIDs } = optionFilter[0]

          return !!(
            parentFieldID === fieldID &&
            parentOptionIDs &&
            parentOptionIDs.length > 0
          )
        })
      })

      if (childParams.length === 0) {
        return group
      }

      const filteredGroup = { ...group }

      // Check if child parameter has any values
      childParams.forEach((childParam) => {
        const childParamValues = formValues[childParam.fieldID] || []

        if (!childParam.selectFields || childParamValues.length === 0) {
          return
        }

        const selectedChildParamOptions = childParam.selectFields.filter(
          ({ optionID }) => {
            return childParamValues.includes(optionID)
          },
        )

        if (selectedChildParamOptions.length > 0) {
          // Filter group's options to valid values based on child values
          filteredGroup.options = group.options.filter(({ optionID }) => {
            return selectedChildParamOptions.find(({ optionFilter }) => {
              if (!optionFilter || optionFilter.length === 0) {
                return true
              }

              const { parentOptionIDs } = optionFilter[0]
              return parentOptionIDs.includes(optionID)
            })
          })
        }
      })

      return filteredGroup
    })
  }, [formValues, selectFields])

  /** Current values flattened out of their groups */
  const selectedValue = useMemo(() => {
    const flatOptions = availableOptions.reduce<DropdownOption[]>(
      (acc, curr, index) => {
        if (index === 0) acc.push(...curr.options)
        else {
          const optionsToAdd = curr.options.filter(
            ({ optionID }) =>
              !acc.find((existing) => existing.optionID === optionID),
          )

          acc.push(...optionsToAdd)
        }

        return acc
      },
      [],
    )

    return flatOptions.filter(({ optionID }) => currentValue.includes(optionID))
  }, [currentValue, availableOptions])

  return (
    <>
      <div className={styles.formInputContainer}>
        <SelectBox
          id={`select-${fieldID}`}
          className={classNames(styles.selectField, {
            [styles.hasCreativeButton]: !updateValueOnly && isCreativeField,
            [styles.hideBorder]: updateValueOnly,
          })}
          menuPortalTarget={portal?.current}
          isMulti={allowMultipleValues}
          isClearable={!updateValueOnly || !required}
          labelKey="optionName"
          valueKey="optionID"
          placeholder={`${!required ? '(Optional) ' : ''}Select`}
          aria-errormessage={`${fieldID}-error`}
          noOptionsMessage={() => ''}
          styles={{
            menu: (css) => ({ ...css, minWidth: 250 }),
            noOptionsMessage: (css) => ({ ...css, display: 'none' }),
          }}
          error={hasSubmitError}
          value={selectedValue}
          noPillForSingleValue={
            !Object.values(formValues).find((val) => val.length > 1)
          }
          options={availableOptions}
          onChange={(newValue) => {
            const newValIDs: string[] = []

            if (allowMultipleValues) {
              newValIDs.push(
                ...(newValue as MultiValue<DropdownOption>).map(
                  ({ optionID }) => optionID,
                ),
              )
            } else if (newValue) {
              newValIDs.push((newValue as DropdownOption).optionID)
            }

            setCurrentValue(newValIDs)

            onChange(
              newValIDs,
              required && newValIDs.length === 0
                ? { errorsToAdd: [fieldID] }
                : { errorsToRemove: [fieldID] },
            )
          }}
        >
          <Button
            variant="text"
            className={styles.addButton}
            onPressStart={() => {
              if (isAdmin) {
                history.push(`/track/edit-dropdowns?fieldID=${fieldID}`)
              } else {
                setShowRequestFieldModal(true)
              }
            }}
          >
            {isAdmin ? 'Add' : 'Request'} new {fieldName} +
          </Button>
        </SelectBox>
        {!updateValueOnly && isCreativeField && (
          <Button
            variant="secondary"
            className={styles.addCreative}
            isDisabled={currentValue.length === 0}
            onPress={() => setShowAddCreativeModal(true)}
          >
            Upload
          </Button>
        )}
      </div>
      {/* This happens when parent-child relationships remove options from the dropdown, but that option is still a saved value */}
      {currentValue.length > selectedValue.length && (
        <p className={styles.dropdownNote}>
          (Some selections are hidden because they can't be combined with
          selections for other parameters)
        </p>
      )}
      {showRequestFieldModal && (
        <RequestFieldModal
          active={showRequestFieldModal}
          toggleActive={setShowRequestFieldModal}
          requestFieldName={fieldName}
        />
      )}
      {showAddCreativeModal && (
        <AddCreativeModal
          setIsOpen={setShowAddCreativeModal}
          param={param}
          currentOptions={selectedValue.map(({ optionName, optionID }) => ({
            optionName,
            optionID,
          }))}
        />
      )}
    </>
  )
}

interface ParameterDateFieldProps extends ParameterFieldProps {
  portalId?: string
}

const ParameterDateField = ({
  param,
  savedValue,
  onChange,
  hasSubmitError,
  updateValueOnly,
  portalId,
}: ParameterDateFieldProps) => {
  const { fieldID, required, dateFormat } = param

  const nonNullDateFormat = dateFormat || 'DD/MM/YYYY'

  const adjustedDateFormat = nonNullDateFormat
    .replace(/Y/gi, 'y')
    .replace(/D/gi, 'd')
    .replace(/(\[Q\])/gi, 'QQ')

  const [currentValue, setCurrentValue] = useState(
    savedValue ? savedValue[0] : '',
  )

  // Update field value on e.g. clearing the form
  useEffect(() => {
    if (!savedValue || savedValue.length === 0) {
      setCurrentValue('')
    } else if (savedValue.length > currentValue.length) {
      setCurrentValue(savedValue[0] || '')
    }
  }, [savedValue])

  let dateValue: null | Date = null

  if (
    currentValue !== '' &&
    moment(currentValue, nonNullDateFormat).isValid()
  ) {
    const dateFormatted = moment(currentValue, nonNullDateFormat).toDate()
    dateValue = dateFormatted
  }

  return (
    <StyledDatePicker
      id={fieldID}
      portalId={portalId}
      wrapperClassName={classNames(styles.dateField, {
        [styles.hideBorder]: updateValueOnly,
      })}
      className={styles.dateInput}
      isClearable={!updateValueOnly || !required}
      placeholderText={`${!required ? '(Optional) ' : ''}${nonNullDateFormat}`}
      dateFormat={adjustedDateFormat}
      showYearPicker={nonNullDateFormat === 'yyyy'}
      showMonthYearPicker={nonNullDateFormat.toLowerCase() === 'yyyymm'}
      showQuarterYearPicker={nonNullDateFormat.toLowerCase() === 'yyyy[q]q'}
      selected={dateValue}
      isError={hasSubmitError}
      onChange={(date) => {
        // The value can never be a null
        // only empty string is permitted in this case
        let val = ''

        if (date !== null) {
          const dateF = moment(date.toString()).format(nonNullDateFormat)
          val = dateF
        }

        setCurrentValue(val)

        onChange(
          val ? [val] : [],
          required && !val
            ? { errorsToAdd: [fieldID] }
            : { errorsToRemove: [fieldID] },
        )
      }}
    />
  )
}

interface GeneratorParameterFieldProps {
  generatedStructure: GetCampaignCodeGeneratorQuery['campaignCodeGenerator']
  param: GetCampaignCodeGeneratorQuery['campaignCodeGenerator']['paramDefs'][0]
  allowMultipleValues?: boolean
  savedValue?: string[]
  formValues: GeneratorParameterValues
  hasSubmitError?: boolean
  onChange: (newVal: string[], options?: UpdateFormOptions) => void
  /** If form not ready to submit, used to tab to next field in form instead of submitting */
  onEnterKey?: (e: React.KeyboardEvent<HTMLInputElement>) => void
  /** Prevents field from being cleared, only shows editable area on hover, and hides creative buttons */
  updateValueOnly?: boolean
  /** Used so dropdown and date menus show correctly when in modals */
  portal?: React.RefObject<HTMLDivElement>
}

export const GeneratorParameterField = ({
  generatedStructure,
  param,
  allowMultipleValues = true,
  savedValue,
  formValues,
  hasSubmitError,
  onChange,
  onEnterKey,
  updateValueOnly,
  portal,
}: GeneratorParameterFieldProps) => {
  const { paramDefs, validationChecks } = generatedStructure || {}

  const { fieldID, fieldType } = param

  switch (fieldType) {
    case 'input':
      return (
        <ParameterInputField
          param={param}
          allowMultipleValues={allowMultipleValues}
          savedValue={savedValue}
          onChange={onChange}
          validationChecks={validationChecks}
          hasSubmitError={hasSubmitError}
          updateValueOnly={updateValueOnly}
          noPillForSingleValue={
            !Object.values(formValues).find((val) => val.length > 1)
          }
          onEnterKey={onEnterKey}
        />
      )
    case 'select':
      return (
        <ParameterSelectField
          key={fieldID}
          param={param}
          allowMultipleValues={allowMultipleValues}
          savedValue={savedValue}
          paramDefs={paramDefs}
          formValues={formValues}
          onChange={onChange}
          hasSubmitError={hasSubmitError}
          updateValueOnly={updateValueOnly}
          portal={portal}
        />
      )
    case 'date':
      return (
        <ParameterDateField
          key={fieldID}
          param={param}
          savedValue={savedValue}
          onChange={onChange}
          hasSubmitError={hasSubmitError}
          updateValueOnly={updateValueOnly}
          portalId={portal?.current?.id}
        />
      )
    default:
      return null
  }
}

interface GeneratorParameterFieldsProps {
  generatedStructure:
    | GetCampaignCodeGeneratorQuery['campaignCodeGenerator']
    | null
  formValues: GeneratorParameterValues
  allowMultipleValues?: boolean
  onChange: (
    fieldID: string,
    newVal: string[],
    options?: UpdateFormOptions,
  ) => void
  showErrorMessages?: boolean
  fieldsWithErrors?: string[] | null
}

const GeneratorParameterFields = ({
  generatedStructure,
  formValues,
  allowMultipleValues = true,
  onChange,
  showErrorMessages,
  fieldsWithErrors,
}: GeneratorParameterFieldsProps) => {
  const { workspaceID } = useReactiveVar(currentUserDetails)

  const formRowRefs = useRef<(HTMLDivElement | null)[]>([])

  const { paramDefs } = generatedStructure || {
    paramDefs: [],
    validationChecks: [],
  }

  // Ensures 'Parameters' heading is shown at the top of this section
  let firstShownParamIndex = paramDefs.findIndex(
    ({ fieldAvailable, fieldType, copyFromField }) =>
      fieldAvailable &&
      !['fixed', 'unique'].includes(fieldType) &&
      (!copyFromField || copyFromField.length === 0),
  )

  // Applies header to first param if non-hidden params found
  if (firstShownParamIndex === -1) firstShownParamIndex = 0

  if (!generatedStructure) return null

  return paramDefs.map((param: ParamDef, paramIndex: number) => {
    const {
      fieldAvailable,
      fieldType,
      fieldID,
      fieldName,
      required,
      metaParameter,
      helpText,
      copyFromField,
      parameterDependsOn,
    } = param

    // Only available fields should show in the form
    if (!fieldAvailable) return null

    // Fixed fields can't be changed - no need to show them
    if (['fixed', 'unique'].includes(fieldType)) return null

    const copiedParams: string[] = []

    // Check if copied params exist in the generator
    if (copyFromField && copyFromField.length > 0) {
      copyFromField.forEach(({ copyFromID }) => {
        const foundParam = paramDefs.find((p) => p.fieldID === copyFromID)

        if (foundParam) {
          copiedParams.push(copyFromID)
        }
      })
    }

    // Copied params should not show in the form - their values are autogenerated
    if (copiedParams.length > 0) return null

    // If the field's dependencies aren't met, we must ignore its `required` value
    let paramIsRequired = parameterDependsOn ? false : required

    let tooltipExtra = ''

    // Only show the field if the dependency condition is met
    if (parameterDependsOn) {
      tooltipExtra +=
        '\n\n**This parameter only appears when certain dropdowns are selected.**'

      if (parameterDependsOn.parentFieldID === 'account') {
        // Specific restriction based on current workspace
        if (!parameterDependsOn.parentOptionIDs.includes(workspaceID)) {
          // Current workspace is not the correct one for this field
          if (formValues[fieldID] && formValues[fieldID].length > 0) {
            // Remove field's values from form and hide field
            onChange(fieldID, [], { errorsToRemove: [fieldID] })
          }

          return null
        }

        // If the parameter's visibility is workspace-dependent, use its `required` rule
        paramIsRequired = required
      } else {
        // Find the parent field in the generator
        const parentParam = paramDefs.find(
          (parent) => parent.fieldID === parameterDependsOn.parentFieldID,
        )

        if (parentParam) {
          // Check if parent param has any values that the current param depends on
          if (
            !formValues[parentParam.fieldID] ||
            formValues[parentParam.fieldID].length === 0
          ) {
            // If not, remove current param's values (if any) and hide it
            if (
              (formValues[fieldID] && formValues[fieldID].length > 0) ||
              fieldsWithErrors?.includes(fieldID)
            ) {
              onChange(fieldID, [], { errorsToRemove: [fieldID] })
            }

            return null
          }

          let canShowParam = false
          let valueIndex = 0

          // Check if parent param's current value contains a value the dependent param needs
          while (valueIndex < formValues[parentParam.fieldID].length) {
            if (
              parameterDependsOn.parentOptionIDs.indexOf(
                formValues[parentParam.fieldID][valueIndex],
              ) > -1
            ) {
              canShowParam = true
              // Update param's required status
              paramIsRequired = required
              break
            }

            valueIndex += 1
          }

          if (!canShowParam) {
            // Remove param's values from form and hide
            if (
              (formValues[fieldID] &&
                formValues[fieldID].length > 0 &&
                !(
                  formValues[fieldID].length === 1 &&
                  formValues[fieldID][0] === ''
                )) ||
              fieldsWithErrors?.includes(fieldID)
            ) {
              onChange(fieldID, [], { errorsToRemove: [fieldID] })
            }

            return null
          }
        }
      }

      // If param does not have a value but is required, add error message
      if (
        paramIsRequired &&
        (!formValues[fieldID] || !formValues[fieldID][0]) &&
        !fieldsWithErrors?.includes(fieldID)
      ) {
        onChange(fieldID, [], { errorsToAdd: [fieldID] })
      }
    }

    // Optional text for label is removed - shows in field placeholder instead
    // let optionalText = 'paramIsRequired ? '' : 'optional''
    let optionalText = ''

    if (metaParameter) {
      optionalText += `${paramIsRequired ? '' : ' '}meta`
      tooltipExtra = `\n\nThis metadata will only be visible in ${brandName}.`
    }

    const hasSubmitError =
      showErrorMessages && fieldsWithErrors?.includes(fieldID)

    return (
      <FormRow
        key={fieldID}
        ref={(el) => {
          formRowRefs.current[paramIndex] = el
        }}
        heading={
          paramIndex === firstShownParamIndex ? (
            <Tooltip
              id="parameters-heading-tooltip"
              useIcon
              tooltipMessage="Analytics (query string) parameters added to the end of your landing page to tell your onsite analytics platform where users came from. With short links, parameters are added when redirected to the landing page."
            >
              Parameters
            </Tooltip>
          ) : undefined
        }
      >
        <FormLabel
          id={fieldID}
          optional={optionalText ? `${optionalText}` : ''}
          tooltip={`${helpText}${tooltipExtra}`}
        >
          {fieldName}
        </FormLabel>
        <FormField>
          <GeneratorParameterField
            param={param}
            allowMultipleValues={allowMultipleValues}
            generatedStructure={generatedStructure}
            savedValue={formValues[fieldID]}
            formValues={formValues}
            hasSubmitError={hasSubmitError}
            onChange={(newVal, options) => onChange(fieldID, newVal, options)}
            onEnterKey={(e) => {
              // If form is not ready to submit, tab to the next field instead
              if (
                e.key === 'Enter' &&
                fieldsWithErrors &&
                fieldsWithErrors.length > 0
              ) {
                e.preventDefault()

                let nextRowIndex = paramIndex + 1
                let nextRow = formRowRefs.current[nextRowIndex]

                while (nextRowIndex < paramDefs.length) {
                  if (nextRow) {
                    break
                  }

                  nextRowIndex += 1
                  nextRow = formRowRefs.current[nextRowIndex]
                }

                if (nextRow) {
                  const nextRowInput = nextRow.querySelector(
                    'input',
                  ) as HTMLInputElement | null

                  if (nextRowInput) {
                    nextRowInput.focus()
                  }
                }
              }
            }}
          />
          {hasSubmitError && (
            <ErrorMessage>
              You must enter a valid value for {fieldName.toLowerCase()}.
            </ErrorMessage>
          )}
        </FormField>
      </FormRow>
    )
  })
}

export default GeneratorParameterFields
