import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useReactiveVar } from '@apollo/client'
import classNames from 'classnames'
import _ from 'lodash'
import numeral from 'numeraljs'

import Button, { ClearButton } from './button'
import { BetaLabel } from './counter'
import { RequestAppLinksModal } from './feature-blocker-modals'
import { FormField, FormLabel, FormLoading, FormRow } from './form'
import Input from './input'
import { RadioButtonProps, RadioGroup } from './input-v2'
import Link from './link'
import NoDataMessage from './no-data-message'
import Tooltip from './tooltip'
import AppFields from './track-create-app-fields'
import TrackCreateWarningModals from './track-create-form-warning-modals'
import LandingPageField from './track-create-landing-page-field'
import GeneratorParameterFields from './track-create-parameter-fields'
import TrackCreateMultiLinkPreviewModal from './track-create-multi-link-preview-modal'
import TrackCreateSubmitFields from './track-create-submit'
import TrackCreateVCardFields from './track-create-vcard-fields'
import TrackCreateVEventFields from './track-create-vevent-fields'
import { InnerBox, OuterBox } from './two-columns'
import { BoxedText, ErrorMessage } from './typography'
import {
  currentUserDetails,
  showAppDeepLinkContent,
} from '../api/apollo/variables'
import { supportEmail, tooltips, uplifterWebsite } from '../core/constants'
import {
  buildMinCodesByUserList,
  maxBatchShortLinks,
  MinCodesByUserResult,
} from '../helpers/track-module'
import {
  FullLinkResult,
  getTotalPossibleCombinations,
  LinkToOptions,
  PreparedLinkResult,
  prepareLinkCombinations,
  SoftWarning,
  UpdateFormValuesVars,
  WebLinkForm,
} from '../helpers/track-create'
import useCustomLinks from '../hooks/useCustomLinks'
import useSubscriptionLevel from '../hooks/useSubscriptionLevel'
import useTrackGenerator from '../hooks/useTrackGenerator'
import { UpdateFormOptions } from '../hooks/useTrackCreateGeneratorForm'
import useTrackCreateFormSubmit from '../hooks/useTrackCreateSubmit'
import styles from '../styles/track-create-form.module.scss'
import { MinimalCodeList } from '../__gql-types__/graphql'

interface ClearFormButtonProps {
  className?: string
  onPress: () => void
}

const ClearFormButton = ({ className, onPress }: ClearFormButtonProps) => {
  return (
    <ClearButton
      className={classNames(className, styles.clearAllButton)}
      iconAfter
      onPress={onPress}
    >
      Clear all
    </ClearButton>
  )
}

interface TrackCreateFormMainProps {
  useDummyData?: boolean
  formValues: WebLinkForm
  updateFormValues: (
    newValues: UpdateFormValuesVars,
    options?: UpdateFormOptions,
  ) => void
  formSubmissionState: {
    softDisable: boolean
    fieldsWithErrors: string[]
    showErrorMessages: boolean
  }
  setFormSubmissionState: React.Dispatch<
    React.SetStateAction<{
      softDisable: boolean
      fieldsWithErrors: string[]
      showErrorMessages: boolean
    }>
  >
  /** Used to set links that should appear in recentlyCreated section */
  setNewlyCreatedLinks: React.Dispatch<
    React.SetStateAction<MinCodesByUserResult[]>
  >
  setLatestVCard: React.Dispatch<React.SetStateAction<string>>
  setLatestVEvent: React.Dispatch<React.SetStateAction<string>>
  /** Used in error message when user tries to create too many links */
  switchToBulkCsv: () => void
  qrData: { fgColour: string; bgColour: string; logoImage: string }
  onChangeQrData: (type: 'fg' | 'bg' | 'logo', value: string) => void
}

const TrackCreateFormMain = ({
  useDummyData,
  formValues,
  updateFormValues,
  formSubmissionState,
  setFormSubmissionState,
  setNewlyCreatedLinks,
  setLatestVCard,
  setLatestVEvent,
  switchToBulkCsv,
  qrData,
  onChangeQrData,
}: TrackCreateFormMainProps) => {
  const { workspaceID, workspaceHomepage } = useReactiveVar(currentUserDetails)

  const showAppContent = useReactiveVar(showAppDeepLinkContent)

  const {
    generatedStructure,
    loadingGenerator,
    generatorError,
  } = useTrackGenerator({ useDummyData })

  const { validationChecks } = generatedStructure || {}

  const {
    softDisable,
    fieldsWithErrors,
    showErrorMessages,
  } = formSubmissionState

  const formRef = useRef<HTMLFormElement>(null)

  const { isEnterprise } = useSubscriptionLevel()

  const { updateSelectedDomain } = useCustomLinks()

  const {
    linkType,
    setLinkType,
    selectedCustomLinkDomain,
    customLinkAliasDetails,
    setCustomLinkAliasDetails,
    submitVCardForm,
    submitVEventForm,
    submitForm,
    createLinks,
    createLinkWarning,
    setCreateLinkWarning,
    createLinkLoading,
    uplifterIdLoading,
    uniqueIDError,
    createLinkError,
  } = useTrackCreateFormSubmit({
    formSubmissionState,
    setFormSubmissionState,
    useAppLinks: formValues.linkTo === 'app',
  })

  // Use this to count eligible links in the form
  const [totalPossibleCombinations, setTotalPossibleCombinations] = useState(1)
  const [preparedLinks, setPreparedLinks] = useState<PreparedLinkResult[]>([])

  // Used in preview modal, only set onSubmit
  const [linksToPreview, setLinksToPreview] = useState<FullLinkResult[]>([])
  const [showLinksPreviewModal, setShowLinksPreviewModal] = useState(false)

  // Used to ensure submit button isn't triggered by multiple clicks in quick succession
  const [codeCreatedTimeout, setCodeCreatedTimeout] = useState(false)

  // Used if account is not enterprise and user tries to create an app link
  const [showAppLinksUpgradeModal, setShowAppLinksUpgradeModal] = useState(
    false,
  )

  // Used to update the QR code preview to be the alias with the correct domain
  const [previewLink, setPreviewLink] = useState<{
    value: string
    isShortLink: boolean
  }>({
    value: formValues.url[0] || '',
    isShortLink: false,
  })

  const showClearButton = useMemo(() => {
    if (formValues.linkTo === 'vCard') {
      return (
        formValues.vCardFieldValues &&
        Object.values(formValues.vCardFieldValues).some(
          (field) => field.length > 0,
        )
      )
    }

    if (formValues.linkTo === 'vEvent') {
      return (
        formValues.vEventFieldValues &&
        Object.values(formValues.vEventFieldValues).some(
          (field) => typeof field === 'string' && field.length > 0,
        )
      )
    }

    if (formValues.url.length > 0 && formValues.url[0] !== '') {
      return true
    }

    if (
      formValues.linkTo === 'app' &&
      (formValues.appValues?.appGroupID || formValues.appValues?.appScreen)
    ) {
      return true
    }

    return Object.values(formValues.generatorParameterValues).some(
      (paramValue) => paramValue && paramValue.length > 0,
    )
  }, [formValues])

  const { showVcardOption, showVeventOption } = useMemo(() => {
    if (!validationChecks) {
      return { showVcardOption: true, showVeventOption: true }
    }

    const vCardRule = validationChecks.find(
      (rule) => rule.name === 'ALLOW_VCARDS',
    )?.enabled
    const vEventRule = validationChecks.find(
      (rule) => rule.name === 'ALLOW_VEVENTS',
    )?.enabled

    return {
      showVcardOption: vCardRule !== false,
      showVeventOption: vEventRule !== false,
    }
  }, [validationChecks])

  // Landing page field should never be optional if isApp
  const showLandingPageField = useMemo(() => {
    if (formValues.linkTo === 'vCard') return false

    if (formValues.linkTo === 'app') return true

    const showLandingCheck = validationChecks?.find(
      (check) => check.name === 'SHOW_LANDING_PAGE',
    )?.enabled

    return typeof showLandingCheck === 'boolean' ? showLandingCheck : true
  }, [formValues.linkTo, validationChecks])

  const linkToOptions = useMemo(() => {
    const options: Omit<
      RadioButtonProps,
      'checked' | 'onChange' | 'labelClassName'
    >[] = [
      {
        label: (
          <div className={styles.appRadio}>
            <Tooltip
              id="web-link-radio-tooltip"
              className={styles.betaTooltip}
              tooltipMessage={tooltips.webLink}
            >
              Web
            </Tooltip>
          </div>
        ),
        value: 'url',
      },
    ]

    if (showAppContent) {
      options.push({
        label: (
          <div className={styles.appRadio}>
            <Tooltip
              id="app-link-radio-tooltip"
              className={styles.betaTooltip}
              tooltipMessage={tooltips.appLink}
            >
              App
            </Tooltip>
          </div>
        ),
        value: 'app',
      })
    }

    if (showVcardOption) {
      options.push({
        label: (
          <div className={styles.appRadio}>
            <Tooltip
              id="contact-link-radio-tooltip"
              className={styles.betaTooltip}
              tooltipMessage={tooltips.contactLink}
            >
              Contact
            </Tooltip>{' '}
            <Tooltip
              id="contact-link-beta-tooltip"
              className={styles.betaTooltip}
              clickable
              tooltipMessage={
                <p>
                  New feature in testing. Email{' '}
                  <Link href={supportEmail}>{supportEmail}</Link> if you spot a
                  bug or have feedback.
                </p>
              }
            />
          </div>
        ),
        value: 'vCard',
      })
    }

    if (showVeventOption) {
      options.push({
        label: (
          <div className={styles.appRadio}>
            <Tooltip
              id="event-link-radio-tooltip"
              className={styles.betaTooltip}
              tooltipMessage={tooltips.eventLink}
            >
              Event
            </Tooltip>{' '}
            <Tooltip
              id="contact-link-beta-tooltip"
              className={styles.betaTooltip}
              clickable
              tooltipMessage={
                <p>
                  New feature in testing. Email{' '}
                  <Link href={supportEmail}>{supportEmail}</Link> if you spot a
                  bug or have feedback.
                </p>
              }
            >
              <BetaLabel className={styles.betaPill} title="Beta" />
            </Tooltip>
          </div>
        ),
        value: 'vEvent',
      })
    }

    return options
  }, [showAppContent, showVcardOption, showVeventOption])

  // Set linkTo to 'url' if it is currently app and user is not Enterprise
  useEffect(() => {
    if ((showAppContent && isEnterprise) || formValues.linkTo !== 'app') {
      return
    }

    const showLandingCheck = validationChecks?.find(
      (check) => check.name === 'SHOW_LANDING_PAGE',
    )?.enabled

    updateFormValues(
      {
        linkTo: 'url',
        // Remove URL value for web form if hidden
        url: showLandingCheck === false ? [] : formValues.url,
        // Remove app values for URL form, else don't update
        appValues: null,
        generatorParameterValues: formValues.generatorParameterValues,
      },
      { resetSubmissionState: true },
    )
  }, [showAppContent, isEnterprise, formValues.linkTo, validationChecks])

  // Recalculate prepared links whenever a form value changes and the form is ready to submit
  useEffect(() => {
    if (!generatedStructure) return

    if (softDisable) {
      setTotalPossibleCombinations(1)
      setPreparedLinks([])
      return
    }

    const totalCombinations = getTotalPossibleCombinations(formValues)

    setTotalPossibleCombinations(totalCombinations)

    // Preparing link combinations based on generator rules is a heavy operation
    // Only do this if possible combinations is less than maxBatchShortLinks
    if (totalCombinations < maxBatchShortLinks) {
      setPreparedLinks(
        prepareLinkCombinations(formValues, generatedStructure, workspaceID),
      )
    }
  }, [
    workspaceID,
    generatedStructure,
    softDisable,
    formValues.url,
    formValues.generatorParameterValues,
  ])

  const clearForm = useCallback(() => {
    // Set all form values back to empty
    const generatorParameterValues = {}
    Object.keys(formValues.generatorParameterValues).forEach((param) => {
      generatorParameterValues[param] = []
    })

    updateFormValues(
      {
        linkTo: formValues.linkTo,
        url: [],
        appValues: null,
        generatorParameterValues,
        vCardFieldValues: null,
      },
      { resetSubmissionState: true },
    )
  }, [updateFormValues, formValues.linkTo, formValues.generatorParameterValues])

  const onSubmit = useCallback(
    async (formVals: WebLinkForm, ignoreWarnings?: SoftWarning[]) => {
      let fullSuccess = false

      if (formVals.linkTo === 'vCard') {
        const { success, vCardResult } = await submitVCardForm(
          formVals.vCardFieldValues,
        )

        fullSuccess = success

        setLatestVCard(vCardResult)
      } else if (formVals.linkTo === 'vEvent') {
        const { success, vEventResult } = await submitVEventForm(
          formVals.vEventFieldValues,
        )

        fullSuccess = success

        setLatestVEvent(vEventResult)
      } else {
        const { success, fullLinks } = await submitForm(
          formVals,
          ignoreWarnings,
        )

        fullSuccess = success

        if (success) {
          // Do not create links if >1 - preview them instead
          if (fullLinks.length > 1) {
            setLinksToPreview(fullLinks)
            setShowLinksPreviewModal(true)

            return
          }

          const useShortLinks =
            formVals.linkTo === 'app' || linkType === 'shortLink'

          const newlyCreatedLink = { ...fullLinks[0] }

          // Add short link ID here
          if (useShortLinks) {
            // eslint-disable-next-line prefer-destructuring
            newlyCreatedLink.shortLinkID = customLinkAliasDetails.alias
          }

          const { success: createSuccess, createdLinks } = await createLinks(
            [newlyCreatedLink],
            linkType === 'appLink' && formVals.appValues
              ? {
                  appGroupID: formVals.appValues.appGroupID,
                  redirectContext: [formVals.appValues.appScreen],
                  // Should this be fC, lP or link?
                  fallbackUrl: newlyCreatedLink.fC,
                }
              : undefined,
          )

          if (createSuccess && createdLinks) {
            setNewlyCreatedLinks(
              buildMinCodesByUserList(createdLinks, createdLinks.totalCodes),
            )
          }
        }
      }

      // Scroll to first errored field if any
      if (!fullSuccess) {
        const errorFields = formRef.current?.querySelectorAll(
          "div[class*='error'], span[class*='error']",
        )

        if (errorFields && errorFields.length > 0) {
          errorFields[0].scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          })
        }
      }
    },
    [
      linkType,
      customLinkAliasDetails,
      submitForm,
      createLinks,
      formRef.current,
      selectedCustomLinkDomain,
    ],
  )

  // Ensures preview link is always using the correct domain
  useEffect(() => {
    // Initialise preview link when switching to vCard
    if (formValues.linkTo === 'vCard') {
      setPreviewLink({
        value: workspaceHomepage || uplifterWebsite,
        isShortLink: false,
      })
      return
    }

    if (linkType === 'shortLink' && selectedCustomLinkDomain?.domainValue) {
      setPreviewLink({
        value: `https://${selectedCustomLinkDomain.domainValue}/${customLinkAliasDetails.alias}`,
        isShortLink: true,
      })
    } else {
      setPreviewLink({
        value: formValues?.url[0] || '',
        isShortLink: false,
      })
    }
  }, [
    linkType,
    selectedCustomLinkDomain,
    customLinkAliasDetails,
    formValues.url,
    formValues.linkTo,
  ])

  if (generatorError) {
    return (
      <OuterBox className={styles.outerBox}>
        <InnerBox>
          <NoDataMessage
            errorMsg={
              <>
                <p>Failed to retrieve link generator parameters.</p>
                <p>
                  <Link type="arrowForward" href={`mailto:${supportEmail}`}>
                    Contact {supportEmail}
                  </Link>
                </p>
              </>
            }
            showSupportLink={false}
          />
        </InnerBox>
      </OuterBox>
    )
  }

  if (loadingGenerator || uplifterIdLoading) {
    return (
      <OuterBox className={styles.outerBox}>
        <InnerBox>
          <FormLoading rowCount={6} includeBorders>
            <Button isDisabled style={{ width: 'fit-content' }}>
              Fetching link builder
            </Button>
          </FormLoading>
        </InnerBox>
      </OuterBox>
    )
  }

  return (
    <>
      <OuterBox className={styles.outerBox}>
        <InnerBox className={styles.innerBox}>
          <form
            ref={formRef}
            className={styles.trackCreateForm}
            onSubmit={async (e) => {
              e.preventDefault()

              if (codeCreatedTimeout) return

              setCodeCreatedTimeout(true)

              await onSubmit(formValues)

              window.setTimeout(() => setCodeCreatedTimeout(false), 800)
            }}
          >
            {showClearButton && (
              <ClearFormButton
                // Has to be absolutely positioned when app row is not shown - top row becomes landing page field and that must be full width
                className={styles.floatingClearAllButton}
                onPress={clearForm}
              />
            )}
            {linkToOptions.length > 1 && (
              <FormRow
                className={classNames({
                  [styles.noPaddingTop]: !showClearButton,
                })}
              >
                <FormLabel
                  id="link-to"
                  tooltip={
                    <>
                      <p>
                        What is your link going to open on the users device?
                      </p>
                      <p>
                        <strong>Web URL:</strong> {tooltips.webLink}
                      </p>
                      {showAppContent && (
                        <p>
                          <strong>App:</strong> {tooltips.appLink}
                        </p>
                      )}
                      {showVcardOption && (
                        <p>
                          <strong>Contact (vCard):</strong>{' '}
                          {tooltips.contactLink}
                        </p>
                      )}
                      {showVeventOption && (
                        <p>
                          <strong>Event:</strong> {tooltips.eventLink}
                        </p>
                      )}
                    </>
                  }
                >
                  <span className={styles.destinationLabel}>Destination</span>
                </FormLabel>
                <FormField>
                  <div className={styles.topFormRowField}>
                    <RadioGroup
                      groupName="linkTo"
                      horizontal
                      className={styles.linkToRadioGroup}
                      optionsClassName={styles.radioButton}
                      selectedValue={formValues.linkTo || 'url'}
                      options={linkToOptions}
                      onChange={(option) => {
                        // App links are enterprise only
                        if (!isEnterprise && option === 'app') {
                          setShowAppLinksUpgradeModal(true)
                          return
                        }

                        // Reset selected app link domain if switching from app to URL
                        if (option === 'url') {
                          updateSelectedDomain('default', 'appLink')
                        }

                        // The URL value must be reset when it's hidden from the form on 'url' version
                        // It should not be a part of the submission form
                        const showLandingCheck = validationChecks?.find(
                          (check) => check.name === 'SHOW_LANDING_PAGE',
                        )?.enabled

                        updateFormValues(
                          {
                            linkTo: option as LinkToOptions,
                            // Remove URL value for web form if hidden
                            url:
                              (option === 'url' &&
                                showLandingCheck === false) ||
                              ['vCard', 'vEvent'].includes(option)
                                ? []
                                : formValues.url,
                            // Remove app values for URL form, else don't update
                            appValues: option === 'url' ? null : undefined,
                            generatorParameterValues:
                              formValues.generatorParameterValues,
                            vCardFieldValues: formValues.vCardFieldValues,
                            vEventFieldValues: formValues.vEventFieldValues,
                          },
                          { resetSubmissionState: true },
                        )
                      }}
                    />
                  </div>
                </FormField>
              </FormRow>
            )}
            {formValues.linkTo === 'vEvent' && (
              <TrackCreateVEventFields
                formValues={formValues.vEventFieldValues}
                onChange={(fieldName, newVal, options) => {
                  updateFormValues(
                    {
                      vEventFieldValues: {
                        [fieldName]: newVal || '',
                      },
                    },
                    options,
                  )
                }}
                showErrorMessages={showErrorMessages}
                fieldsWithErrors={fieldsWithErrors}
                qrData={qrData}
                onChangeQrData={onChangeQrData}
                submitDisabled={codeCreatedTimeout}
              />
            )}
            {formValues.linkTo === 'vCard' && (
              <TrackCreateVCardFields
                formValues={formValues.vCardFieldValues}
                onChange={(fieldName, newVal, options) => {
                  updateFormValues(
                    {
                      vCardFieldValues: {
                        [fieldName]: newVal || '',
                      },
                    },
                    options,
                  )
                }}
                showErrorMessages={showErrorMessages}
                fieldsWithErrors={fieldsWithErrors}
                qrData={qrData}
                onChangeQrData={onChangeQrData}
                submitDisabled={codeCreatedTimeout}
              />
            )}
            {!['vCard', 'vEvent'].includes(formValues.linkTo) && (
              <>
                {formValues.linkTo === 'app' && (
                  <AppFields
                    appValues={formValues.appValues}
                    appGroupIDError={
                      showErrorMessages &&
                      formSubmissionState.fieldsWithErrors.includes(
                        'appGroupID',
                      )
                    }
                    appScreenError={
                      showErrorMessages &&
                      formSubmissionState.fieldsWithErrors.includes('appScreen')
                    }
                    updateFormValues={updateFormValues}
                  />
                )}
                {showLandingPageField && (
                  <LandingPageField
                    isApp={formValues.linkTo === 'app'}
                    validationChecks={
                      generatedStructure?.validationChecks || null
                    }
                    savedValue={formValues.url}
                    onChange={(newVal, options) => {
                      updateFormValues({ url: newVal }, options)
                    }}
                    submitError={
                      showErrorMessages &&
                      fieldsWithErrors.includes('landingPage')
                    }
                  />
                )}
                <div style={{ paddingBottom: 16 }}>
                  <GeneratorParameterFields
                    generatedStructure={generatedStructure}
                    formValues={formValues.generatorParameterValues}
                    onChange={(fieldID, newVal, options) => {
                      updateFormValues(
                        {
                          generatorParameterValues: {
                            [fieldID]: newVal || [],
                          },
                        },
                        options,
                      )
                    }}
                    showErrorMessages={showErrorMessages}
                    fieldsWithErrors={fieldsWithErrors}
                  />
                  <FormRow>
                    <FormLabel disabled>Send weekly report</FormLabel>
                    <FormField className={styles.sendWeeklyReportField}>
                      <Input
                        type="checkbox"
                        name="send-weekly-report"
                        id="send-weekly-report"
                        disabled
                        className={styles.weeklyReportCheckbox}
                        checked
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          // TODO
                        }}
                      />
                      <BetaLabel
                        className={styles.betaPill}
                        title="Coming soon"
                      />
                    </FormField>
                  </FormRow>
                </div>
                <TrackCreateSubmitFields
                  validationChecks={
                    generatedStructure?.validationChecks || null
                  }
                  linkType={linkType}
                  setLinkType={setLinkType}
                  customLinkAliasDetails={customLinkAliasDetails}
                  setCustomLinkAliasDetails={setCustomLinkAliasDetails}
                  linkCount={preparedLinks.length || 1}
                  submitLoading={createLinkLoading}
                  softDisable={!createLinkLoading && softDisable}
                  submitDisabled={
                    codeCreatedTimeout ||
                    (!softDisable && preparedLinks.length === 0) ||
                    !!uniqueIDError ||
                    totalPossibleCombinations > maxBatchShortLinks
                  }
                  previewLink={previewLink.value}
                  qrData={qrData}
                  onChangeQrData={onChangeQrData}
                >
                  {totalPossibleCombinations <= maxBatchShortLinks &&
                    preparedLinks.length > 1 &&
                    preparedLinks.length <= maxBatchShortLinks &&
                    !softDisable && (
                      <p className={styles.validCombinationsNote}>
                        {numeral(preparedLinks.length).format('0,0')} valid
                        combinations for you to review.
                      </p>
                    )}
                  {totalPossibleCombinations > maxBatchShortLinks && (
                    <ErrorMessage>
                      You are trying to create up to{' '}
                      {numeral(totalPossibleCombinations).format('0,0')} links.
                      Please reduce this to{' '}
                      {numeral(maxBatchShortLinks).format('0, 0')} or less. To
                      create more, try the{' '}
                      <BoxedText>
                        <Button
                          variant="text"
                          color="blue"
                          className={styles.bulkCsvButton}
                          onPress={switchToBulkCsv}
                        >
                          Bulk CSV
                        </Button>
                      </BoxedText>{' '}
                      tab.
                    </ErrorMessage>
                  )}
                  {showErrorMessages && fieldsWithErrors.length > 0 && (
                    <ErrorMessage>
                      Please have a value for every required parameter.
                    </ErrorMessage>
                  )}
                  {uniqueIDError && (
                    <ErrorMessage>
                      Error generating a unique link ID. Please reload the page
                      and try again, or contact{' '}
                      <Link href={supportEmail}>{supportEmail}</Link>.
                    </ErrorMessage>
                  )}
                  {createLinkError && (
                    <ErrorMessage>
                      {preparedLinks.length === 0
                        ? 'No valid links to create.'
                        : `Error creating link${
                            preparedLinks.length === 1 ? '' : 's'
                          },
                  please refresh and try again.`}
                    </ErrorMessage>
                  )}
                  {customLinkAliasDetails.error && (
                    <ErrorMessage>
                      There was an error creating your{' '}
                      {linkType === 'shortLink' ? 'short' : 'deep'} link. Please
                      refresh it and try again.
                    </ErrorMessage>
                  )}
                </TrackCreateSubmitFields>
              </>
            )}
          </form>
        </InnerBox>
      </OuterBox>
      {showLinksPreviewModal && linksToPreview.length > 0 && (
        <TrackCreateMultiLinkPreviewModal
          generatedStructure={generatedStructure}
          setIsOpen={setShowLinksPreviewModal}
          linksToPreview={linksToPreview}
          setLinksToPreview={setLinksToPreview}
          linkType={linkType}
          appValues={formValues.appValues}
          customLinkAliasDetails={customLinkAliasDetails}
          setCustomLinkAliasDetails={setCustomLinkAliasDetails}
          onSubmit={async (linksToCreate, bulkStart, editedLinkAliases) => {
            const appLinkValues =
              linkType === 'appLink' && formValues.appValues
                ? {
                    appGroupID: formValues.appValues.appGroupID,
                    redirectContext: [formValues.appValues.appScreen],
                    // Should this be fC, lP or link?
                    fallbackUrl: linksToCreate[0].fC,
                  }
                : undefined

            const uneditedLinks = _.cloneDeep(linksToCreate)
            const editedLinks: FullLinkResult[] = []

            // Change alias values for edited links
            // These will be in a secondary createLinks call, not using the bulkStart value
            if (
              editedLinkAliases &&
              Object.keys(editedLinkAliases).length > 0
            ) {
              Object.keys(editedLinkAliases).forEach((fC) => {
                const editedLinkIndex = uneditedLinks.findIndex(
                  (link) => link.fC === fC,
                )

                // Might happen if an alias is edited but not selected
                if (editedLinkIndex === -1) return

                const editedLink = uneditedLinks.splice(editedLinkIndex, 1)

                editedLinks.push({
                  ...editedLink[0],
                  shortLinkID: editedLinkAliases[fC].alias,
                })
              })
            }

            let fullSuccess = false
            let fullCreatedLinks: MinimalCodeList | null = null

            // Do edited links first to prevent weird alias changes in the modal
            if (editedLinks.length > 0) {
              const {
                success: individualLinksSuccess,
                createdLinks,
              } = await createLinks(editedLinks, appLinkValues)

              fullSuccess = individualLinksSuccess
              fullCreatedLinks = createdLinks ? _.cloneDeep(createdLinks) : null
            } else {
              fullSuccess = true
            }

            if (fullSuccess && uneditedLinks.length > 0) {
              const { success, createdLinks } = await createLinks(
                uneditedLinks,
                appLinkValues,
                bulkStart,
              )

              fullSuccess = success

              if (fullCreatedLinks && createdLinks) {
                fullCreatedLinks.author.push(...createdLinks.author)
                fullCreatedLinks.codeDef.push(...createdLinks.codeDef)
                fullCreatedLinks.codeID.push(...createdLinks.codeID)
                fullCreatedLinks.createdTime.push(...createdLinks.createdTime)
                fullCreatedLinks.fullLink.push(...createdLinks.fullLink)
                fullCreatedLinks.versionNumber.push(
                  ...createdLinks.versionNumber,
                )

                if (fullCreatedLinks.shortLink && createdLinks.shortLink) {
                  fullCreatedLinks.shortLink.push(...createdLinks.shortLink)
                } else if (createdLinks.shortLink) {
                  fullCreatedLinks.shortLink = createdLinks.shortLink
                }

                fullCreatedLinks.totalCodes += createdLinks.totalCodes
              } else {
                fullCreatedLinks = createdLinks || null
              }
            }

            if (fullSuccess && fullCreatedLinks) {
              setNewlyCreatedLinks(
                buildMinCodesByUserList(
                  fullCreatedLinks,
                  fullCreatedLinks.totalCodes,
                ),
              )

              setLinksToPreview([])
              setShowLinksPreviewModal(false)
            }
          }}
          createLinkError={createLinkError}
          customLinkError={customLinkAliasDetails.error}
        />
      )}
      {createLinkWarning && (
        <TrackCreateWarningModals
          createLinkWarning={createLinkWarning}
          setCreateLinkWarning={setCreateLinkWarning}
          formValues={formValues}
          onSubmit={onSubmit}
        />
      )}
      {showAppLinksUpgradeModal && (
        <RequestAppLinksModal onHideModal={setShowAppLinksUpgradeModal} />
      )}
    </>
  )
}

export default TrackCreateFormMain
