import React, { useEffect, useMemo } from 'react'
import { useLazyQuery, useQuery, useReactiveVar } from '@apollo/client'
import { useHistory } from 'react-router-dom'

import Button from './button'
import { FormField, FormLabel, FormRow } from './form'
import SelectBox from './select-box'
import { ErrorMessage } from './typography'
import { currentUserDetails } from '../api/apollo/variables'
import {
  listAppContextOptions,
  listAppGroups,
} from '../api/graphql/track-edit-client'
import { isAdminUser } from '../helpers'
import { AppValues, UpdateFormValuesVars } from '../helpers/track-create'
import useCustomLinks from '../hooks/useCustomLinks'
import { UpdateFormOptions } from '../hooks/useTrackCreateGeneratorForm'
import styles from '../styles/track-create-app-fields.module.scss'

interface AppFieldsProps {
  appValues?: AppValues
  appGroupIDError?: boolean
  appScreenError?: boolean
  updateFormValues: (
    newValues: UpdateFormValuesVars,
    options?: UpdateFormOptions,
  ) => void
}

const AppFields = ({
  appValues,
  appGroupIDError,
  appScreenError,
  updateFormValues,
}: AppFieldsProps) => {
  const { appGroupID, appScreen } = appValues || {}

  const { userPermission } = useReactiveVar(currentUserDetails)

  const isAdmin = isAdminUser(userPermission)

  const history = useHistory()

  const { updateSelectedDomain } = useCustomLinks()

  const { data: appGroupsData } = useQuery(listAppGroups)
  const [getAppContextOptions, { data: appContextData }] = useLazyQuery(
    listAppContextOptions,
  )

  const appGroups = useMemo(() => {
    if (!appGroupsData || !appGroupsData.track.deepLinkQueries.listAppGroups) {
      return []
    }

    return appGroupsData.track.deepLinkQueries.listAppGroups
  }, [appGroupsData])

  // Get app group's context options
  useEffect(() => {
    if (!appGroupID) return

    getAppContextOptions({ variables: { appGroupID } })
  }, [appGroupID])

  // Get app group's deep link service domain
  // Used in presentation fields
  useEffect(() => {
    const currentDeepLinkServiceID = appGroups.find(
      (group) => group.appGroupID === appGroupID,
    )?.deepLinkServiceID

    if (currentDeepLinkServiceID) {
      updateSelectedDomain(currentDeepLinkServiceID, 'appLink')
    }

    // Auto-select app group if only one available
    if (appGroups.length === 1) {
      updateFormValues({
        url: [appGroups[0].redirectWeb],
        appValues: {
          appGroupID: appGroups[0].appGroupID,
        },
      })
    }
  }, [appGroups, appGroupID, updateFormValues])

  const registeredApps = useMemo(() => {
    if (!appContextData) return []

    return appContextData.track.deepLinkQueries.listAppContextOptions
  }, [appContextData])

  return (
    <>
      <FormRow>
        <FormLabel
          id="app-environment"
          tooltip="What apps you want the link to open on an Android/iOS device."
        >
          Apps
        </FormLabel>
        <FormField>
          <SelectBox
            key={appGroupID}
            id="ios-app"
            placeholder="Select"
            isClearable={appGroups.length > 1}
            error={appGroupIDError}
            labelKey="appGroupName"
            valueKey="appGroupID"
            value={appGroups.find((option) => appGroupID === option.appGroupID)}
            options={appGroups}
            onChange={(newValue) => {
              const errorsToRemove = newValue?.appGroupID ? ['appGroupID'] : []
              const errorsToAdd = newValue?.appGroupID ? [] : ['appGroupID']

              if (newValue?.redirectWeb) {
                errorsToRemove.push('landingPage')
              }

              updateFormValues(
                {
                  url: newValue ? [newValue.redirectWeb] : undefined,
                  appValues: {
                    appGroupID: newValue ? newValue.appGroupID : null,
                    appScreen: null,
                  },
                },
                newValue?.appGroupID ? { errorsToRemove } : { errorsToAdd },
              )
            }}
          >
            {isAdmin && (
              <Button
                variant="text"
                className={styles.addButton}
                onPressStart={() => {
                  history.push('/track/edit-app-destinations')
                }}
              >
                Add new app +
              </Button>
            )}
          </SelectBox>
          {appGroupIDError && (
            <ErrorMessage>You must select an app environment.</ErrorMessage>
          )}
        </FormField>
      </FormRow>
      <FormRow>
        <FormLabel
          id="app-screen"
          tooltip="What app screens (pages/content) you want to open on Android/iOS apps."
        >
          App screen
        </FormLabel>
        <FormField>
          <SelectBox
            key={appScreen}
            id="app-screen"
            placeholder="(Optional) Select"
            isClearable
            isDisabled={!appGroupID}
            error={appScreenError}
            labelKey="optionDisplayName"
            valueKey="optionID"
            value={registeredApps.find(
              (option) => appScreen === option.optionID,
            )}
            options={registeredApps}
            onChange={(newValue) => {
              updateFormValues({
                appValues: { appScreen: newValue ? newValue.optionID : null },
              })
            }}
          >
            {isAdmin && (
              <Button
                variant="text"
                className={styles.addButton}
                onPressStart={() => {
                  history.push('/track/edit-app-destinations')
                }}
              >
                Add new app screen +
              </Button>
            )}
          </SelectBox>
          {appScreenError && (
            <ErrorMessage>You must select an app screen.</ErrorMessage>
          )}
        </FormField>
      </FormRow>
    </>
  )
}

export default AppFields
