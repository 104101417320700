import React, { useEffect, useMemo, useState } from 'react'
import {
  useLazyQuery,
  useMutation,
  useQuery,
  useReactiveVar,
} from '@apollo/client'
import { useHistory } from 'react-router-dom'
import { nanoid } from 'nanoid'
import numeral from 'numeraljs'

import Button from './button'
import { BetaLabel } from './counter'
import Input from './input'
import Link from './link'
import Modal from './modal'
import NoDataMessage from './no-data-message'
import ProgressBar from './progress-bar'
import SelectBox from './select-box'
import Tooltip from './tooltip'
import {
  BoldText,
  ErrorMessage,
  Heading,
  SuccessText,
  TickListItem,
} from './typography'
import { currentUserDetails, linkOrCode } from '../api/apollo/variables'
import {
  cancelMicrosoftMarketplaceSubscription,
  cancelPaddlePlan,
  changeMicrosoftMarketplacePlan,
  deleteCompany,
  getCompanyDetails,
  getPaddleSubscriptionDetails,
  listAzureMarketplaceQueries,
  updateMicrosoftMarketplaceSeats,
  updatePaddlePlan,
  updatePaddleSeatsQuantity,
} from '../api/graphql/company-client'
import { sendFeedback } from '../api/graphql/onboarding-client'
import { sendFeatureRequest } from '../api/graphql/support-client'
import { logout } from '../api/REST/auth-client'
import { PeopleIcon } from '../assets/svgs/people-icons'
import UpgradeRocket from '../assets/svgs/upgrade-rocket.svg'
import {
  calendarBookingLink,
  currencyLookup,
  deleteWord,
  supportEmail,
} from '../core/constants'
import useLogAction from '../hooks/useLogAction'
import usePaddle, { usePaddleSandboxAccount } from '../hooks/usePaddle'
import useSubscriptionLevel, {
  subscriptionLevelDetails,
} from '../hooks/useSubscriptionLevel'
import styles from '../styles/subscription-management-modals.module.scss'

interface UpdateSubscriptionUsersModalProps {
  onHideModal: React.Dispatch<React.SetStateAction<boolean>>
  showWarningMessage?: string
}

export const UpdateSubscriptionUsersModal = ({
  onHideModal,
  showWarningMessage,
}: UpdateSubscriptionUsersModalProps) => {
  const history = useHistory()

  const { companyID, workspaceID, userID } = useReactiveVar(currentUserDetails)

  const logAction = useLogAction()

  useEffect(() => {
    logAction({
      variables: {
        action: 'view-user-limit-buy-more-licences-modal',
        pagePath: window.location.pathname,
        websiteSection: 'upgrade',
        functionName: 'requestCustomDomain',
        extra: JSON.stringify({ companyID, workspaceID, userID }),
      },
    })
  }, [])

  const {
    subscriptionCategory,
    isMicrosoftMarketplace,
    paddleData,
    microsoftSubscriptionData,
    featureLimits: { users: userLimit },
  } = useSubscriptionLevel()

  const { data: companyData, loading: loadingCompanyData } = useQuery(
    getCompanyDetails,
  )
  const [updateBillingSeats, { loading }] = useMutation(
    updatePaddleSeatsQuantity,
  )
  const [updateMicrosoftSeats, { loading: loadingMicrosoft }] = useMutation(
    updateMicrosoftMarketplaceSeats,
  )

  const [requestedSeats, setRequestedSeats] = useState(1)

  const currentUserCount = useMemo(() => {
    if (!companyData) return 0

    return companyData.currentCompany.userCount
  }, [companyData])

  const { unitPrice, quantity, currency } = useMemo(() => {
    if (!paddleData && !microsoftSubscriptionData)
      return {
        unitPrice: null,
        quantity: null,
        currency: null,
      }

    if (paddleData) {
      const {
        paddleSubscriptionUnitPrice,
        paddleSubscriptionQuantity,
        paddleSubscriptionCurrency,
      } = paddleData.currentCompany

      return {
        unitPrice: paddleSubscriptionUnitPrice
          ? numeral(parseInt(paddleSubscriptionUnitPrice, 10)).format('0,0.00')
          : null,
        quantity: paddleSubscriptionQuantity
          ? parseInt(paddleSubscriptionQuantity, 10)
          : null,
        currency: paddleSubscriptionCurrency
          ? currencyLookup[paddleSubscriptionCurrency]
          : null,
      }
    }

    if (
      microsoftSubscriptionData &&
      microsoftSubscriptionData.currentCompany.microsoftSubscriptionData
    ) {
      const {
        price,
        quantity: msQuantity,
        currency: msCurrency,
      } = microsoftSubscriptionData.currentCompany.microsoftSubscriptionData

      return {
        unitPrice: price ? parseInt(price, 10) : null,
        quantity: msQuantity || null,
        currency: msCurrency ? currencyLookup[msCurrency] : null,
      }
    }

    return {
      unitPrice: null,
      quantity: null,
      currency: null,
    }
  }, [paddleData, microsoftSubscriptionData])

  const userProgress = useMemo(() => {
    if (!currentUserCount || !quantity) return 0

    setRequestedSeats(userLimit)

    return (currentUserCount / quantity) * 100
  }, [currentUserCount, quantity, subscriptionCategory])

  const availableLicences = useMemo(() => {
    return Array.from(new Array(userLimit + 1), (_, index) => {
      if (index === userLimit)
        return {
          value: `${userLimit + 1}+`,
        }

      return { value: (index + 1).toString() }
    })
  }, [subscriptionCategory, userLimit])

  return (
    <Modal
      setIsOpen={onHideModal}
      modalHeader={
        <Heading className={styles.modalTitle} type={3} align="left">
          Add or remove users
          <PeopleIcon color="#333" />
        </Heading>
      }
      loading={loadingCompanyData}
      noText="Close"
      yesButtonDisabled={
        !companyData ||
        !!(quantity && requestedSeats === quantity) ||
        companyData.currentCompany.userCount > requestedSeats
      }
      yesButtonLoading={loading || loadingMicrosoft}
      yesText={
        requestedSeats > userLimit ? (
          <>
            Get a quote{' '}
            <span role="img" aria-label="Chat emoji">
              💬
            </span>
          </>
        ) : (
          `${
            requestedSeats >= (quantity as number)
              ? `Buy ${requestedSeats - (quantity as number)} more`
              : `Remove ${(quantity as number) - requestedSeats}`
          } user${
            Math.abs(requestedSeats - (quantity as number)) === 1 ? '' : 's'
          }`
        )
      }
      onYes={async () => {
        // Option to upgrade to Enterprise (from Startup or Billing)
        if (requestedSeats > userLimit) {
          window.open(calendarBookingLink, '_blank')

          logAction({
            variables: {
              action: 'request-enterprise-upgrade',
              extra: JSON.stringify({
                from: 'Add users modal',
              }),
              websiteSection: 'upgrade',
              pagePath: window.location.pathname,
              functionName: 'upgradeToEnterprise',
            },
          })

          onHideModal(false)

          return
        }

        if (isMicrosoftMarketplace) {
          // Microsoft
          await updateMicrosoftSeats({
            variables: {
              companyID,
              totalSeats: requestedSeats,
            },
          })
        } else {
          await updateBillingSeats({
            variables: {
              companyID,
              updatedQuantity: requestedSeats,
              paddleEnv: usePaddleSandboxAccount ? 'sandbox' : 'prod',
            },
            refetchQueries: [getPaddleSubscriptionDetails],
          })
        }

        await logAction({
          variables: {
            action: `update-${
              isMicrosoftMarketplace ? 'microsoft-marketplace' : 'paddle'
            }-seats-count`,
            extra: JSON.stringify({
              newCount: requestedSeats,
            }),
            websiteSection: 'upgrade',
            pagePath: window.location.pathname,
            functionName: 'addRemoveSeats',
          },
        })

        history.replace('/settings?show=users', {
          newSeatCount: requestedSeats.toString(),
        })

        onHideModal(false)
      }}
      // Show option to upgrade to business instead of Enterprise
      footerContent={
        requestedSeats > userLimit &&
        subscriptionCategory === 'startup' && (
          <Button
            onPress={() => {
              history.push('/upgrade')

              onHideModal(false)
            }}
          >
            Upgrade to Business
          </Button>
        )
      }
    >
      {showWarningMessage ? (
        <ErrorMessage>{showWarningMessage}</ErrorMessage>
      ) : (
        <p>
          Save time and money by letting your colleagues and agencies self-serve
          consistent {linkOrCode()}s and reports.
        </p>
      )}
      {companyData && quantity ? (
        <>
          <p>
            <strong>{companyData.currentCompany.userCount}</strong> of{' '}
            <strong>
              {quantity.toString()} user seat
              {quantity > 1 && 's'}
            </strong>{' '}
            in use.
          </p>
          <ProgressBar
            className={styles.modalUserProgress}
            percentage={userProgress}
            showPercent={false}
            thin
            superThin
            useUrgency
          />
          <p>Select the total no. of monthly user seats you need:</p>
          <SelectBox
            id="user-licences"
            className={styles.licencesDropdown}
            maxMenuHeight={100}
            labelKey="value"
            value={availableLicences.find(
              (licence) =>
                licence.value ===
                `${requestedSeats.toString()}${
                  requestedSeats > userLimit ? '+' : ''
                }`,
            )}
            options={availableLicences}
            onChange={(newValue) => {
              if (!newValue) return

              setRequestedSeats(
                parseInt(newValue.value.replace(/[^\d]+/, ''), 10),
              )
            }}
          />
          {companyData.currentCompany.userCount > requestedSeats && (
            <ErrorMessage>
              You currently have more users than this. Please remove some in
              Users settings.
            </ErrorMessage>
          )}
          {requestedSeats > userLimit ? (
            <p>
              If you need more than {userLimit} users, you need{' '}
              {subscriptionCategory === 'startup'
                ? 'to upgrade to a business licence (max 10 users) or request a quote for an enterprise licence.'
                : 'our Enterprise plan. Talk to us to request an upgrade'}
              .
            </p>
          ) : (
            <>
              {isMicrosoftMarketplace ? (
                <></>
              ) : (
                <p>
                  <Link href="https://www.paddle.com/resources/proration">
                    We will prorate the new subscription total for the rest of
                    this month to your current billing method.
                  </Link>
                </p>
              )}
            </>
          )}
          {unitPrice !== null &&
            quantity &&
            requestedSeats !== quantity &&
            requestedSeats <= userLimit && (
              <p>
                New full monthly bill:{' '}
                <strong>
                  {`${currency}${numeral(unitPrice * requestedSeats).format(
                    '0,0.00',
                  )}`}
                </strong>{' '}
                (ex. VAT)
              </p>
            )}
        </>
      ) : (
        <NoDataMessage errorMsg="Unable to fetch subscription information." />
      )}
    </Modal>
  )
}

interface UpgradePlanModalProps {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  upgradeTo?: 'business' | 'enterprise'
}

export const UpgradePlanModal = ({
  setIsOpen,
  upgradeTo = 'business',
}: UpgradePlanModalProps) => {
  const { companyID, workspaceID, userID } = useReactiveVar(currentUserDetails)

  const logAction = useLogAction()

  const {
    loadingSubscriptionInfo,
    isEnterprise,
    subscriptionBillingPeriod,
    isMicrosoftMarketplace,
    paddleData,
  } = useSubscriptionLevel()

  const { Paddle } = !isMicrosoftMarketplace ? usePaddle() : { Paddle: null }

  const [
    getMSMarketplacePlans,
    { data: microsoftPlansData, loading: loadingMSPlans, error: msPlansError },
  ] = useLazyQuery(listAzureMarketplaceQueries)

  const [
    changePaddleSubscriptionID,
    { loading: updatingPaddleSubscriptionID, error: upgradeError },
  ] = useMutation(updatePaddlePlan)
  const [
    upgradeMicrosoftMarketplacePlan,
    { loading: upgradingMSPlan, error: msUpgradeError },
  ] = useMutation(changeMicrosoftMarketplacePlan)

  const [tierPrice, setTierPrice] = useState('')
  const [paddleError, setPaddleError] = useState(false)
  const [updateSuccess, setUpdateSuccess] = useState(false)

  useEffect(() => {
    logAction({
      variables: {
        action: 'view-upgrade-plan-modal',
        pagePath: window.location.pathname,
        websiteSection: 'upgrade',
        functionName: 'upgradePlan',
        extra: JSON.stringify({ companyID, workspaceID, userID, upgradeTo }),
      },
    })
  }, [])

  const { subscriptionQuantity, newSubscriptionID } = useMemo(() => {
    if (
      (!isMicrosoftMarketplace &&
        (!subscriptionBillingPeriod || !paddleData)) ||
      (isMicrosoftMarketplace && !microsoftPlansData)
    ) {
      return {}
    }

    if (!isMicrosoftMarketplace) {
      const { paddleSubscriptionQuantity } = paddleData?.currentCompany || {}

      if (!paddleSubscriptionQuantity) return {}

      return {
        subscriptionQuantity: parseInt(paddleSubscriptionQuantity, 10),
        newSubscriptionID:
          subscriptionLevelDetails[upgradeTo][
            `${subscriptionBillingPeriod}NoTrialID`
          ],
      }
    }

    const { planId } =
      microsoftPlansData?.accountSettings.azureMarketplaceQueries.listPlans.find(
        (plan) =>
          plan.displayName ===
          (upgradeTo === 'business' ? 'Team' : 'Enterprise'),
      ) || {}

    if (!planId) return {}

    return {
      subscriptionQuantity: 1,
      newSubscriptionID: planId,
    }
  }, [upgradeTo, subscriptionBillingPeriod, paddleData, microsoftPlansData])

  // Fetch MS tiers if on MS Marketplace plan
  useEffect(() => {
    if (!isMicrosoftMarketplace) return

    getMSMarketplacePlans()
  }, [isMicrosoftMarketplace])

  // Fetch accurate price
  useEffect(() => {
    if (isMicrosoftMarketplace) {
      if (!microsoftPlansData) return

      const { billingTerms } =
        microsoftPlansData.accountSettings.azureMarketplaceQueries.listPlans.find(
          (plan) =>
            plan.displayName ===
            (upgradeTo === 'business' ? 'Team' : 'Enterprise'),
        ) || {}

      if (!billingTerms) return

      const { currency, price } = billingTerms[0]

      setTierPrice(
        `${currencyLookup[currency]}${numeral(price).format('0,0.00')}`,
      )
    }

    if (!subscriptionQuantity || !newSubscriptionID || !Paddle) {
      return
    }

    Paddle.PricePreview({
      items: [
        {
          priceId: newSubscriptionID,
          quantity: subscriptionQuantity,
        },
      ],
    })
      .then(({ data }) => {
        const { details } = data

        setTierPrice(
          details.lineItems[0].formattedTotals.subtotal.replace('.00', ''),
        )
      })
      .catch(() => {
        setPaddleError(true)
      })
  }, [
    upgradeTo,
    Paddle,
    subscriptionQuantity,
    newSubscriptionID,
    microsoftPlansData,
  ])

  if (!isMicrosoftMarketplace && isEnterprise) return null

  return (
    <Modal
      setIsOpen={setIsOpen}
      modalHeader={
        <Heading className={styles.modalTitle} type={3} align="left">
          Upgrade to {`${upgradeTo[0].toUpperCase()}${upgradeTo.slice(1)}`} plan
          <img src={UpgradeRocket} alt="Upgrade" />
        </Heading>
      }
      loading={
        loadingSubscriptionInfo ||
        loadingMSPlans ||
        (!tierPrice && !paddleError && !msPlansError)
      }
      noText={updateSuccess ? 'Close' : 'Cancel'}
      beforeClose={() => {
        if (updateSuccess) {
          window.location.replace('/settings?show=billing')
        }
      }}
      yesButtonDisabled={!!upgradeError || !!msUpgradeError}
      yesButtonLoading={updatingPaddleSubscriptionID || upgradingMSPlan}
      yesText={updateSuccess ? 'Continue' : 'Confirm'}
      onYes={async () => {
        if (updateSuccess) {
          window.location.replace('/settings?show=billing')

          return
        }

        // Can only upgrade to enterprise if on a MS Marketplace plan
        let newPriceIDVar = ''

        if (isMicrosoftMarketplace || upgradeTo === 'enterprise') {
          newPriceIDVar =
            upgradeTo === 'business' ? 'business_edition' : 'enterprise_edition'

          await upgradeMicrosoftMarketplacePlan({
            variables: {
              companyID,
              newPlanID: newPriceIDVar,
            },
          })
        } else {
          if (!paddleData || isEnterprise) {
            return
          }

          const { paddleSubscriptionQuantity } = paddleData.currentCompany

          newPriceIDVar =
            subscriptionLevelDetails.business[
              subscriptionBillingPeriod === 'annual'
                ? 'annualNoTrialID'
                : 'monthlyNoTrialID'
            ] || ''

          if (!paddleSubscriptionQuantity || !newPriceIDVar) return

          await changePaddleSubscriptionID({
            variables: {
              updatedPriceID: newPriceIDVar,
              updatedQuantity: parseInt(paddleSubscriptionQuantity, 10),
            },
          })
        }

        // @ts-ignore
        if (window.dataLayer && window.dataLayer.push) {
          // @ts-ignore
          window.dataLayer.push({
            event: `${
              isMicrosoftMarketplace ? 'ms-marketplace' : 'paddle'
            }-upgrade-to-${upgradeTo}`,
          })
        }

        logAction({
          variables: {
            action: `${
              isMicrosoftMarketplace ? 'ms-marketplace' : 'paddle'
            }-upgrade-to-${upgradeTo}`,
            websiteSection: 'upgrade',
            pagePath: window.location.pathname,
            functionName: 'clickDowngrade',
          },
        })

        setUpdateSuccess(true)
      }}
      footerContent={
        (paddleError || msPlansError || !!upgradeError || !!msUpgradeError) && (
          <ErrorMessage showSupport>
            Failed to downgrade subscription.
          </ErrorMessage>
        )
      }
    >
      {updateSuccess ? (
        <SuccessText>Subscription plan successfully updated.</SuccessText>
      ) : (
        <>
          <p style={{ marginBottom: 8 }}>
            <BoldText>You will gain access to:</BoldText>
          </p>
          <ul style={{ marginBottom: 16 }}>
            {subscriptionLevelDetails[
              upgradeTo === 'enterprise' ? 'enterprise' : 'business'
            ].whatYouCanDo.map((item) => {
              return (
                <TickListItem key={item.id}>
                  <Tooltip
                    id={item.id || nanoid()}
                    tooltipMessage={item.tooltip}
                    maxWidth={200}
                  >
                    {item.text}
                  </Tooltip>
                </TickListItem>
              )
            })}
          </ul>
          <p>
            <BoldText>
              Your new billing cost for{' '}
              {`${upgradeTo[0].toUpperCase()}${upgradeTo.slice(1)}`} will be{' '}
              {tierPrice} (ex. VAT) per{' '}
              {subscriptionBillingPeriod === 'annual' ? 'year' : 'month'}.
            </BoldText>
          </p>
          <p>
            <Link href="https://www.paddle.com/resources/proration">
              We will prorate the new subscription total for the rest of this
              month to your current billing method.
            </Link>
          </p>
        </>
      )}
    </Modal>
  )
}

interface PaddleChangeBillingPeriodModalProps {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export const PaddleChangeBillingPeriodModal = ({
  setIsOpen,
}: PaddleChangeBillingPeriodModalProps) => {
  const logAction = useLogAction()

  const { Paddle } = usePaddle()

  const {
    loadingSubscriptionInfo,
    paddleData,
    isEnterprise,
    subscriptionCategory,
    subscriptionBillingPeriod,
  } = useSubscriptionLevel()

  const [
    changePaddleSubscriptionID,
    { loading: updatingBillingPeriod, error: updateError },
  ] = useMutation(updatePaddlePlan)

  const [tierPrice, setTierPrice] = useState('')
  const [updateSuccess, setUpdateSuccess] = useState(false)
  const [paddleError, setPaddleError] = useState(false)

  const { subscriptionQuantity, newSubscriptionID } = useMemo(() => {
    if (!subscriptionCategory || !subscriptionBillingPeriod || !paddleData) {
      return {}
    }

    const { paddleSubscriptionQuantity } = paddleData.currentCompany

    if (!paddleSubscriptionQuantity) return {}

    return {
      subscriptionQuantity: parseInt(paddleSubscriptionQuantity, 10),
      newSubscriptionID:
        subscriptionLevelDetails[subscriptionCategory][
          subscriptionBillingPeriod === 'monthly'
            ? 'annualNoTrialID'
            : 'monthlyNoTrialID'
        ],
    }
  }, [subscriptionCategory, subscriptionBillingPeriod, paddleData])

  // Fetch accurate price from Paddle
  useEffect(() => {
    if (!subscriptionQuantity || !newSubscriptionID || !Paddle) {
      return
    }

    Paddle.PricePreview({
      items: [
        {
          priceId: newSubscriptionID,
          quantity: subscriptionQuantity,
        },
      ],
    })
      .then(({ data }) => {
        const { details } = data

        setTierPrice(
          details.lineItems[0].formattedTotals.subtotal.replace('.00', ''),
        )
      })
      .catch(() => {
        setPaddleError(true)
      })
  }, [Paddle, subscriptionQuantity, newSubscriptionID])

  if (isEnterprise) return null

  return (
    <Modal
      setIsOpen={setIsOpen}
      modalHeader="Confirm billing period change"
      loading={loadingSubscriptionInfo || (!tierPrice && !paddleError)}
      noText={updateSuccess ? 'Close' : 'Cancel'}
      beforeClose={() => {
        if (updateSuccess) {
          window.location.replace('/settings?show=billing')
        }
      }}
      yesButtonLoading={updatingBillingPeriod}
      yesText="Confirm"
      onYes={async () => {
        if (updateSuccess) {
          window.location.replace('/settings?show=billing')

          return
        }

        if (!paddleData) return

        const { paddleSubscriptionQuantity } = paddleData.currentCompany

        const newPriceID =
          subscriptionLevelDetails[subscriptionCategory][
            subscriptionBillingPeriod === 'monthly'
              ? 'annualNoTrialID'
              : 'monthlyNoTrialID'
          ]

        if (!paddleSubscriptionQuantity || !newPriceID) return

        await changePaddleSubscriptionID({
          variables: {
            updatedPriceID: newPriceID,
            updatedQuantity: parseInt(paddleSubscriptionQuantity, 10),
          },
        })

        logAction({
          variables: {
            action: 'paddle-switch-billing-period',
            websiteSection: 'settings',
            pagePath: window.location.pathname,
            functionName: 'switchPaddleBIllingPeriod',
          },
        })

        setUpdateSuccess(true)
      }}
      footerContent={
        (paddleError || !!updateError) && (
          <ErrorMessage showSupport>
            Failed to update billing period.
          </ErrorMessage>
        )
      }
    >
      {updateSuccess ? (
        <SuccessText>Billing period successfully updated.</SuccessText>
      ) : (
        <>
          <p>
            <BoldText>
              Your new billing cost will be {tierPrice} (ex. VAT) per{' '}
              {subscriptionBillingPeriod === 'monthly' ? 'year' : 'month'}.{' '}
              {subscriptionBillingPeriod === 'monthly' ? (
                <BetaLabel title="Save 17%" />
              ) : (
                ''
              )}
            </BoldText>
          </p>
          <p>
            <Link href="https://www.paddle.com/resources/proration">
              We will prorate the new subscription total for the rest of this
              month to your current billing method.
            </Link>
          </p>
        </>
      )}
    </Modal>
  )
}

interface ExcessUsersBlockerModalProps {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  downgradeTo: 'startup' | 'business'
}

const ExcessUsersBlockerModal = ({
  setIsOpen,
  downgradeTo,
}: ExcessUsersBlockerModalProps) => {
  return (
    <Modal
      setIsOpen={setIsOpen}
      modalHeader="Remove users and workspaces"
      yesText="Remove users"
      onYes={() => window.location.replace('/settings?show=users')}
    >
      <p>
        In order to downgrade, please delete all but{' '}
        {subscriptionLevelDetails[downgradeTo].maxWorkspaces.toString()} of your
        workspaces and have less than{' '}
        {subscriptionLevelDetails[downgradeTo].maxUsers} users.
      </p>
    </Modal>
  )
}

interface DowngradePlanModalProps {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  downgradeTo: 'startup' | 'business'
  currentUserCount: number
  currentWorkspaceCount: number
}

export const DowngradePlanModal = ({
  setIsOpen,
  downgradeTo,
  currentUserCount,
  currentWorkspaceCount,
}: DowngradePlanModalProps) => {
  const { companyID, workspaceID, userID } = useReactiveVar(currentUserDetails)

  const logAction = useLogAction()

  const {
    loadingSubscriptionInfo,
    isEnterprise,
    subscriptionBillingPeriod,
    isMicrosoftMarketplace,
    paddleData,
    featureLimits: { users: userLimit, workspaces: workspaceLimit },
  } = useSubscriptionLevel()

  const { Paddle } = !isMicrosoftMarketplace ? usePaddle() : { Paddle: null }

  const [
    getMSMarketplacePlans,
    { data: microsoftPlansData, loading: loadingMSPlans, error: msPlansError },
  ] = useLazyQuery(listAzureMarketplaceQueries)

  const [
    changePaddleSubscriptionID,
    { loading: downgradingPaddle, error: downgradeError },
  ] = useMutation(updatePaddlePlan)
  const [
    downgradeMicrosoftPlan,
    { loading: downgradingMicrosoft, error: msDowngradeError },
  ] = useMutation(changeMicrosoftMarketplacePlan)

  const [tierPrice, setTierPrice] = useState('')
  const [updateSuccess, setUpdateSuccess] = useState(false)
  const [paddleError, setPaddleError] = useState(false)

  useEffect(() => {
    logAction({
      variables: {
        action: 'view-downgrade-plan-modal',
        pagePath: window.location.pathname,
        websiteSection: 'upgrade',
        functionName: 'downgradePlan',
        extra: JSON.stringify({ companyID, workspaceID, userID, downgradeTo }),
      },
    })
  }, [])

  const { subscriptionQuantity, newSubscriptionID } = useMemo(() => {
    if (
      (!isMicrosoftMarketplace &&
        (!subscriptionBillingPeriod || !paddleData)) ||
      (isMicrosoftMarketplace && !microsoftPlansData)
    ) {
      return {}
    }

    if (!isMicrosoftMarketplace) {
      const { paddleSubscriptionQuantity } = paddleData?.currentCompany || {}

      if (!paddleSubscriptionQuantity) return {}

      return {
        subscriptionQuantity: parseInt(paddleSubscriptionQuantity, 10),
        newSubscriptionID:
          subscriptionLevelDetails[downgradeTo][
            `${subscriptionBillingPeriod}NoTrialID`
          ],
      }
    }

    const { planId } =
      microsoftPlansData?.accountSettings.azureMarketplaceQueries.listPlans.find(
        (plan) =>
          plan.displayName === (downgradeTo === 'business' ? 'Team' : 'Free'),
      ) || {}

    if (!planId) return {}

    return {
      subscriptionQuantity: 1,
      newSubscriptionID: planId,
    }
  }, [downgradeTo, subscriptionBillingPeriod, paddleData, microsoftPlansData])

  // Fetch MS tiers if on MS Marketplace plan
  useEffect(() => {
    if (!isMicrosoftMarketplace) return

    getMSMarketplacePlans()
  }, [isMicrosoftMarketplace])

  // Fetch accurate price
  useEffect(() => {
    if (isMicrosoftMarketplace) {
      if (!microsoftPlansData) return

      const { billingTerms } =
        microsoftPlansData.accountSettings.azureMarketplaceQueries.listPlans.find(
          (plan) =>
            plan.displayName === (downgradeTo === 'business' ? 'Team' : 'Free'),
        ) || {}

      if (!billingTerms) return

      const { currency, price } = billingTerms[0]

      setTierPrice(
        `${currencyLookup[currency]}${numeral(price).format('0,0.00')}`,
      )
    }

    if (!subscriptionQuantity || !newSubscriptionID || !Paddle) {
      return
    }

    Paddle.PricePreview({
      items: [
        {
          priceId: newSubscriptionID,
          quantity: subscriptionQuantity,
        },
      ],
    })
      .then(({ data }) => {
        const { details } = data

        setTierPrice(
          details.lineItems[0].formattedTotals.subtotal.replace('.00', ''),
        )
      })
      .catch(() => {
        setPaddleError(true)
      })
  }, [
    downgradeTo,
    Paddle,
    subscriptionQuantity,
    newSubscriptionID,
    microsoftPlansData,
  ])

  if (!isMicrosoftMarketplace && isEnterprise) return null

  // If user has too many users or workspaces, they must delete some to downgrade
  if (currentUserCount > userLimit || currentWorkspaceCount > workspaceLimit) {
    return (
      <ExcessUsersBlockerModal
        setIsOpen={setIsOpen}
        downgradeTo={downgradeTo}
      />
    )
  }

  return (
    <Modal
      setIsOpen={setIsOpen}
      modalHeader="Confirm downgrade"
      loading={
        loadingSubscriptionInfo ||
        loadingMSPlans ||
        (!tierPrice && !paddleError && !msPlansError)
      }
      noText={updateSuccess ? 'Close' : 'Cancel'}
      beforeClose={() => {
        if (updateSuccess) {
          window.location.replace('/settings?show=billing')
        }
      }}
      yesButtonDisabled={!!downgradeError || !!msDowngradeError}
      yesButtonLoading={downgradingPaddle || downgradingMicrosoft}
      yesText={updateSuccess ? 'Continue' : 'Confirm'}
      onYes={async () => {
        if (updateSuccess) {
          window.location.replace('/settings?show=billing')
        }

        // Can only downgrade to business if on a MS Marketplace plan
        if (isMicrosoftMarketplace || downgradeTo === 'business') {
          await downgradeMicrosoftPlan({
            variables: {
              companyID,
              newPlanID:
                downgradeTo === 'business'
                  ? 'business_edition'
                  : 'startup_edition',
            },
          })
        } else {
          if (!paddleData) {
            return
          }

          const { paddleSubscriptionQuantity } = paddleData.currentCompany

          const newPriceID =
            subscriptionLevelDetails.startup[
              subscriptionBillingPeriod === 'annual'
                ? 'annualNoTrialID'
                : 'monthlyNoTrialID'
            ]

          if (!paddleSubscriptionQuantity || !newPriceID) return

          await changePaddleSubscriptionID({
            variables: {
              updatedPriceID: newPriceID,
              updatedQuantity: parseInt(paddleSubscriptionQuantity, 10),
            },
          })
        }

        // @ts-ignore
        if (window.dataLayer && window.dataLayer.push) {
          // @ts-ignore
          window.dataLayer.push({
            event: `${
              isMicrosoftMarketplace ? 'ms-marketplace' : 'paddle'
            }-downgrade-to-${downgradeTo}`,
          })
        }

        logAction({
          variables: {
            action: `${
              isMicrosoftMarketplace ? 'ms-marketplace' : 'paddle'
            }-downgrade-to-${downgradeTo}`,
            websiteSection: 'upgrade',
            pagePath: window.location.pathname,
            functionName: 'clickDowngrade',
          },
        })

        setUpdateSuccess(true)
      }}
      footerContent={
        (paddleError || !!downgradeError || !!msDowngradeError) && (
          <ErrorMessage showSupport>
            Failed to downgrade subscription.
          </ErrorMessage>
        )
      }
    >
      {updateSuccess ? (
        <SuccessText>Subscription plan successfully updated.</SuccessText>
      ) : (
        <>
          <p style={{ marginBottom: 8 }}>
            <BoldText>You will lose access to:</BoldText>
          </p>
          <ul style={{ marginBottom: 16 }}>
            {subscriptionLevelDetails[
              downgradeTo === 'business' ? 'enterprise' : 'business'
            ].whatYouCanDo.map((item) => {
              return (
                <TickListItem key={item.id}>
                  <Tooltip
                    id={item.id || nanoid()}
                    tooltipMessage={item.tooltip}
                    maxWidth={200}
                  >
                    {item.text}
                  </Tooltip>
                </TickListItem>
              )
            })}
          </ul>
          <p>
            <BoldText>
              Your new billing cost for{' '}
              {`${downgradeTo[0].toUpperCase()}${downgradeTo.slice(1)}`} will be{' '}
              {tierPrice} (ex. VAT) per{' '}
              {subscriptionBillingPeriod === 'annual' ? 'year' : 'month'}.
            </BoldText>
          </p>
          <p>
            <Link href="https://www.paddle.com/resources/proration">
              We will prorate the new subscription total for the rest of this
              month to your current billing method.
            </Link>
          </p>
        </>
      )}
    </Modal>
  )
}

interface DeleteAccountModalProps {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}

/** Permanently deletes account. Must only be used after successfully cancelling a subscription */
export const DeleteAccountModal = ({ setIsOpen }: DeleteAccountModalProps) => {
  const { companyID } = useReactiveVar(currentUserDetails)

  const { isMicrosoftMarketplace } = useSubscriptionLevel()

  const [cancelMicrosoftSubscription] = useMutation(
    cancelMicrosoftMarketplaceSubscription,
  )
  const [permanentlyDeleteCompany, { error }] = useMutation(deleteCompany)

  const [deletingAccount, setDeletingAccount] = useState(false)
  const [deleteInputValue, setDeleteInputValue] = useState('')

  return (
    <Modal
      setIsOpen={setIsOpen}
      modalHeader="Confirm account deletion"
      noText="Cancel"
      yesText="Delete account"
      yesButtonDisabled={deleteInputValue !== deleteWord || !!error}
      yesButtonLoading={deletingAccount && !error}
      onYes={async () => {
        if (deleteInputValue === deleteWord && companyID) {
          setDeletingAccount(true)

          if (isMicrosoftMarketplace) {
            // Cancel subscription
            // Doesn't matter which tier subscription is, including Free
            await cancelMicrosoftSubscription({
              variables: { companyID },
            })
          }

          await permanentlyDeleteCompany({
            variables: {
              companyID,
            },
          })

          setDeletingAccount(false)

          logout()
        }
      }}
    >
      <p>
        This will remove all data and cannot be reversed. Type {deleteWord} to
        confirm account deletion.
      </p>
      <Input
        name="deleteAccount"
        type="text"
        required
        value={deleteInputValue}
        onValueChange={(e) => setDeleteInputValue(e)}
      />
      {error && (
        <p className={styles.deleteError}>
          Failed to delete account. Please contact{' '}
          <Link href={`mailto:${supportEmail}`}>support</Link>.
        </p>
      )}
    </Modal>
  )
}

const deleteReasons = [
  {
    label: "The product isn't what I expected",
    value: 'notAsExpected',
  },
  {
    label: "I don't use it any more",
    value: 'dontUse',
  },
  {
    label: 'I found a different tool',
    value: 'foundDifferentTool',
  },
  {
    label: "I'm not getting the results I wanted",
    value: 'notGettingResults',
  },
  {
    label: "I don't have the budget",
    value: 'noBudget',
  },
  {
    label: "I'm not happy with the product",
    value: 'notHappy',
  },
  {
    label: 'Other',
    value: 'other',
  },
]

interface CancelSubscriptionModalProps {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  deleteAfterCancel?: boolean
}

export const CancelSubscriptionModal = ({
  setIsOpen,
  deleteAfterCancel,
}: CancelSubscriptionModalProps) => {
  const { companyID, workspaceID, userID } = useReactiveVar(currentUserDetails)

  const {
    isStartup,
    isBusiness,
    isMicrosoftMarketplace,
    isEnterprise,
    paddleData,
  } = useSubscriptionLevel()

  const logAction = useLogAction()

  const [sendFeedbackMutation] = useMutation(sendFeedback)
  const [
    cancelPaddleSubscription,
    { loading: cancellingSubscription },
  ] = useMutation(cancelPaddlePlan)

  const [feedbackInput, setFeedbackInput] = useState('')
  const [feedbackError, setFeedbackError] = useState(false)
  const [selectedOptions, setSelectedOptions] = useState<string[]>([])
  const [selectedOptionsError, setSelectedOptionsError] = useState(false)
  const [updateSuccess, setUpdateSuccess] = useState(false)
  const [downgradeLoading, setDowngradeLoading] = useState(false)
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false)

  useEffect(() => {
    logAction({
      variables: {
        action: 'view-cancel-subscription-modal',
        pagePath: window.location.pathname,
        websiteSection: 'upgrade',
        functionName: 'cancelSubscription',
        extra: JSON.stringify({ companyID, workspaceID, userID }),
      },
    })
  }, [])

  const paddleCancelUrl = useMemo(() => {
    if (!paddleData) return null

    return paddleData.currentCompany.paddleCancelUrl
  }, [paddleData])

  // Can't cancel Enterprise accounts
  if (isEnterprise && !isMicrosoftMarketplace) {
    return null
  }

  return (
    <>
      <Modal
        setIsOpen={setIsOpen}
        modalHeader="We're sorry to see you go"
        noText={updateSuccess ? 'Close' : 'Back'}
        beforeClose={() => {
          if (updateSuccess) {
            window.location.replace('/settings?show=billing')
          }
        }}
        yesButtonLoading={cancellingSubscription || downgradeLoading}
        yesText={updateSuccess ? 'Continue' : 'Confirm cancel'}
        onYes={async () => {
          if (updateSuccess) {
            if (deleteAfterCancel) {
              setShowDeleteConfirm(true)
            } else {
              window.location.replace('/settings?show=billing')
            }
          }

          if (!feedbackInput || selectedOptions.length === 0) {
            setFeedbackError(!feedbackInput)
            setSelectedOptionsError(selectedOptions.length === 0)
            return
          }

          setDowngradeLoading(true)

          await sendFeedbackMutation({
            variables: {
              message: `Reasons: ${
                selectedOptions
                  .map(
                    (option) =>
                      deleteReasons.find(({ value }) => value === option)
                        ?.label,
                  )
                  .join(', ') || 'Other'
              }.\nDetails: ${feedbackInput}`,
              page: '/upgrade',
              feedbackContext: {
                feedbackPrompt: deleteAfterCancel
                  ? 'Delete account'
                  : 'Cancel subscription',
                feedbackResponse: `Why are you ${
                  deleteAfterCancel
                    ? 'deleting your account'
                    : 'canceling your subscription'
                }?`,
              },
            },
          })

          logAction({
            variables: {
              action: 'confirm-cancel-subscription',
              websiteSection: 'upgrade',
              pagePath: window.location.pathname,
              functionName: 'clickDowngrade',
            },
          })

          // Cancel Paddle subscription
          if (
            !isMicrosoftMarketplace &&
            (isStartup || isBusiness) &&
            paddleCancelUrl
          ) {
            await cancelPaddleSubscription()
          }

          setDowngradeLoading(false)
          setUpdateSuccess(true)

          if (deleteAfterCancel) {
            setShowDeleteConfirm(true)
          }
        }}
      >
        {updateSuccess ? (
          <SuccessText>Subscription cancelled.</SuccessText>
        ) : (
          <>
            <p>
              If something isn't working,{' '}
              <Link href={calendarBookingLink}>book a meeting with us</Link> so
              we can fix it.
            </p>
            <p>
              You will still have access until the end of your current billing
              period, then after 90 days your account and all its data will be
              deleted.
            </p>
            <p>
              If you still wish to{' '}
              {deleteAfterCancel ? 'leave' : 'cancel your subscription'}, please
              share your reasons. We'd really appreciate your honest feedback.
            </p>
            <div className={styles.checkboxesContainer}>
              {deleteReasons.map(({ label, value }) => {
                return (
                  <Input
                    type="checkbox"
                    id={value}
                    name={value}
                    label={label}
                    error={selectedOptionsError}
                    className={styles.checkboxContainer}
                    checked={selectedOptions.includes(value)}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      const { checked } = e.target as HTMLInputElement

                      if (checked && selectedOptionsError) {
                        setSelectedOptionsError(false)
                      }

                      setSelectedOptions((curr) => {
                        const newOptions = [...curr]

                        if (checked) {
                          if (!curr.includes(value)) {
                            newOptions.push(value)
                          }

                          return newOptions
                        }

                        return newOptions.filter((option) => option !== value)
                      })
                    }}
                  />
                )
              })}
            </div>
            {selectedOptionsError && (
              <p className={styles.deleteError} style={{ marginTop: -16 }}>
                Please select at least one option.
              </p>
            )}
            <Input
              name="feedbackInput"
              type="textArea"
              value={feedbackInput}
              onValueChange={(val) => {
                setFeedbackInput(val)
                setFeedbackError(false)
              }}
              required
              placeholder="Please give as much detail as you can"
              multilineInput
              textAreaHeight={80}
              lineBreakAll={false}
              error={feedbackError}
            />
            {feedbackError && (
              <p className={styles.deleteError} style={{ marginTop: -16 }}>
                Please provide feedback before submitting.
              </p>
            )}

            {deleteAfterCancel && (
              <p>
                Once your subscription is cancelled, you will be asked to
                confirm account deletion.
              </p>
            )}
          </>
        )}
      </Modal>
      {deleteAfterCancel && showDeleteConfirm && (
        <DeleteAccountModal setIsOpen={setShowDeleteConfirm} />
      )}
    </>
  )
}

interface RequestContractUpgradeModalProps {
  onHideModal: React.Dispatch<React.SetStateAction<boolean>>
}

export const RequestContractUpgradeModal = ({
  onHideModal,
}: RequestContractUpgradeModalProps) => {
  const { companyID, companyName, workspaceID, userID } = useReactiveVar(
    currentUserDetails,
  )

  const logAction = useLogAction()

  const { isEnterprise, isMicrosoftMarketplace } = useSubscriptionLevel()

  const [requestFeature, { loading, error }] = useMutation(sendFeatureRequest)

  const [additionalInfo, setAdditionalInfo] = useState('')
  const [showThankYou, setShowThankYou] = useState(false)

  useEffect(() => {
    logAction({
      variables: {
        action: 'enterprise-company-view-upgrade-contract-modal',
        pagePath: window.location.pathname,
        websiteSection: 'upgrade',
        functionName: 'requestCustomDomain',
        extra: JSON.stringify({ companyID, workspaceID, userID }),
      },
    })
  }, [])

  if (!isEnterprise || isMicrosoftMarketplace) return null

  return (
    <Modal
      setIsOpen={onHideModal}
      headerColor="pink"
      modalHeader={
        <Heading className={styles.modalTitle} type={3} align="left">
          Increase limits <img src={UpgradeRocket} alt="Upgrade" />
        </Heading>
      }
      noText={showThankYou ? 'Back' : 'Cancel'}
      yesText="Request increase"
      onYes={
        showThankYou
          ? undefined
          : async () => {
              if (!showThankYou) {
                setShowThankYou(true)
                const message = `Request contract upgrade: ${companyName}.\n\n${additionalInfo}`

                requestFeature({
                  variables: {
                    message,
                    page: 'settings',
                  },
                })

                logAction({
                  variables: {
                    action: 'enterprise-company-request-contract-upgrade',
                    pagePath: window.location.pathname,
                    websiteSection: 'setting',
                    functionName: 'requestContractUpgrade',
                    extra: message,
                  },
                })
              } else {
                onHideModal(false)
              }
            }
      }
      footerContent={
        <>
          {error && (
            <ErrorMessage showSupport>Error sending request.</ErrorMessage>
          )}
        </>
      }
    >
      {(!showThankYou || loading) && (
        <>
          <p>
            Let everyone self-serve with more users, workspaces and connectors.
          </p>
          <Input
            type="textArea"
            name="contract-upgrade-additional-info"
            className={styles.modalInput}
            placeholder="What would you like to increase?"
            value={additionalInfo}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const { value: val } = e.target as HTMLInputElement
              setAdditionalInfo(val)
            }}
          />
        </>
      )}
      {showThankYou && !loading && (
        <>
          <p>
            Thank you for contacting us and requesting a contract upgrade. We
            will be in touch soon!
          </p>
        </>
      )}
    </Modal>
  )
}
