import React, { useEffect, useMemo, useState } from 'react'
import { useLazyQuery, useQuery } from '@apollo/client'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import _ from 'lodash'

import Button from './button'
import { FormField, FormLabel, FormRow } from './form'
import Input from './input'
import Link from './link'
import { LoadingLabel } from './loader'
import SelectBox from './select-box'
import { ErrorMessage } from './typography'
import {
  getAvailableEloquaEmailGroups,
  getAvailableEloquaTemplates,
  getEloquaOrgList,
  getEloquaTemplateHTML,
  getIntegrationsStatus,
  getSalesforceEmailHTML,
  getSalesforceEmailTemplates,
  getSalesforceMCEmailTemplateDetail,
  getSalesforceMCTemplateList,
  getSavedPardotAcctList,
} from '../api/graphql/integrations-client'
import { getCampaignCodeGenerator } from '../api/graphql/track-create-client'
import { supportEmail } from '../core/constants'
import { htmlHasLinks } from '../helpers/track-module'
import {
  EloquaEmailFields,
  EmailFields,
  emailSourceOptions,
  EmailSources,
  emailSourcesArray,
  formIsEloquaEmailFields,
  formIsSalesforceMCEmailFields,
  formIsSalesforcePardotEmailFields,
  maxEmailHtmlLength,
  SalesforceEmailTemplate,
  SalesforceMCEmailFields,
  SalesforceMCTemplateType,
  SalesforcePardotEmailFields,
} from '../helpers/track-create'
import { UpdateFormOptions } from '../hooks/useTrackCreateGeneratorForm'
import styles from '../styles/track-create-email-fields.module.scss'

interface ConnectedPlatformFieldProps {
  emailHtml: string
  updateFormValues: (
    newVals: Partial<EmailFields>,
    options: UpdateFormOptions,
  ) => void
  hasErroredFields?: boolean
  setFetchingEmailHtml: React.Dispatch<React.SetStateAction<boolean>>
}

interface EloquaEmailFormFieldsProps extends ConnectedPlatformFieldProps {
  eloquaEmailFields?: EloquaEmailFields | null
}

const EloquaEmailFormFields = ({
  emailHtml,
  updateFormValues,
  hasErroredFields,
  setFetchingEmailHtml,
  eloquaEmailFields,
}: EloquaEmailFormFieldsProps) => {
  const { eloquaOrg, emailGroup, emailTemplate } = eloquaEmailFields || {}

  const {
    data: orgListData,
    loading: loadingOrgListData,
    error: orgListError,
  } = useQuery(getEloquaOrgList)

  const [
    getEloquaEmailGroups,
    {
      data: emailGroupsData,
      loading: loadingEmailGroups,
      error: errorFetchingEmailGroups,
    },
  ] = useLazyQuery(getAvailableEloquaEmailGroups)
  const [
    getEmailTemplates,
    {
      data: emailTemplatesData,
      loading: loadingEmailTemplates,
      error: errorFetchingEmailTemplates,
    },
  ] = useLazyQuery(getAvailableEloquaTemplates)
  const [
    fetchEloquaEmailHtml,
    { error: errorFetchingEloquaEmailHtml },
  ] = useLazyQuery(getEloquaTemplateHTML, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  })

  const [templateSearch, setTemplateSearch] = useState('')

  const orgList = useMemo(() => {
    if (!orgListData) return []

    return orgListData.track.eloquaQueries.getConnectedEloquaOrgs.eloquaOrgList
  }, [orgListData])

  const emailGroups = useMemo(() => {
    if (!emailGroupsData) return []

    return [
      { groupID: null, name: '[Ungrouped - Search templates manually]' },
      ...emailGroupsData.track.eloquaQueries.getAvailableEloquaEmailGroups
        .eloquaEmailGroupList,
    ]
  }, [emailGroupsData])

  const emailTemplates = useMemo(() => {
    if (
      !emailTemplatesData ||
      (emailGroup?.groupID === null && templateSearch.length < 4)
    ) {
      return []
    }

    if (emailGroup?.groupID === null && templateSearch.length > 4) {
      return emailTemplatesData.track.eloquaQueries.getAvailableEloquaTemplateList.eloquaTemplateList.filter(
        (template) =>
          template.name.toLowerCase().indexOf(templateSearch.toLowerCase()) >
          -1,
      )
    }

    return emailTemplatesData.track.eloquaQueries.getAvailableEloquaTemplateList
      .eloquaTemplateList
  }, [emailTemplatesData, emailGroup, templateSearch])

  // Ensure eloquaOrg is set if only one is available
  useEffect(() => {
    if (orgList.length === 1 && !eloquaOrg) {
      const { eloquaOrgDisplayName, eloquaOrgID, eloquaOrgName } = orgList[0]

      updateFormValues(
        {
          emailSource: 'eloqua',
          emailHtml: '',
          eloquaEmailFields: {
            eloquaOrg: { eloquaOrgDisplayName, eloquaOrgID, eloquaOrgName },
            emailGroup: null,
            emailTemplate: null,
          },
        },
        { errorsToAdd: ['emailHtml', 'eloqua'] },
      )

      getEloquaEmailGroups({
        variables: {
          eloquaOrgID,
        },
      })
    }
  }, [eloquaOrg, orgList])

  // Fetch email groups on load if org comes from a saved value
  useEffect(() => {
    if (eloquaOrg?.eloquaOrgID && !emailGroup && emailGroups.length === 0) {
      getEloquaEmailGroups({
        variables: {
          eloquaOrgID: eloquaOrg.eloquaOrgID,
        },
      })
    }
  }, [eloquaOrg, emailGroup, emailGroups])

  // Fetch email templates on load if email group comes from a saved value
  useEffect(() => {
    if (
      eloquaOrg?.eloquaOrgID &&
      emailGroup?.groupID &&
      !emailTemplate &&
      emailTemplates.length === 0
    ) {
      getEmailTemplates({
        variables: {
          eloquaOrgID: eloquaOrg.eloquaOrgID,
          groupID: emailGroup.groupID,
          search: undefined,
        },
      })
    }
  }, [eloquaOrg, emailGroup, emailTemplate, emailTemplates])

  return (
    <>
      {orgList.length > 1 && (
        <FormRow>
          <FormLabel
            id="eloqua-organisation"
            tooltip="The Eloqua organisation to use"
          >
            Organisation
          </FormLabel>
          <FormField>
            <SelectBox
              id="eloquaOrgList"
              labelKey="eloquaOrgDisplayName"
              valueKey="eloquaOrgID"
              placeholder="Select organisation or start typing"
              isClearable
              isLoading={loadingOrgListData}
              loadingMessage={() => (
                <LoadingLabel label="Fetching connected Eloqua organisations" />
              )}
              error={!!orgListError || (hasErroredFields && !eloquaOrg)}
              aria-errormessage="org-list-error"
              value={eloquaEmailFields?.eloquaOrg || null}
              options={orgList}
              onChange={async (newValue) => {
                if (newValue?.eloquaOrgID === eloquaOrg?.eloquaOrgID) {
                  return
                }

                updateFormValues(
                  {
                    emailSource: 'eloqua',
                    emailHtml: '',
                    eloquaEmailFields: {
                      eloquaOrg: newValue,
                      emailGroup: null,
                      emailTemplate: null,
                    },
                  },
                  // Always add the error, because this resets later fields
                  { errorsToAdd: ['emailHtml', 'eloqua'] },
                )

                if (!newValue) return

                await getEloquaEmailGroups({
                  variables: {
                    eloquaOrgID: newValue.eloquaOrgID,
                  },
                })
              }}
            />

            {hasErroredFields && !eloquaOrg && (
              <ErrorMessage>Please select an organisation.</ErrorMessage>
            )}
          </FormField>
        </FormRow>
      )}
      <FormRow>
        <FormLabel
          id="eloqua-email-group"
          tooltip="The email group to use from Eloqua."
        >
          Email group
        </FormLabel>
        <FormField>
          <SelectBox
            id="eloquaEmailGroup"
            labelKey="name"
            valueKey="groupID"
            placeholder="Select"
            isClearable
            isLoading={loadingEmailGroups}
            isDisabled={
              !eloquaOrg ||
              (!loadingOrgListData &&
                eloquaOrg !== null &&
                emailGroups.length === 0)
            }
            loadingMessage={() => (
              <LoadingLabel label="Fetching email groups" />
            )}
            error={
              (eloquaOrg && !!errorFetchingEmailGroups) ||
              (hasErroredFields && !emailGroup)
            }
            aria-errormessage="email-group-error"
            value={emailGroup || null}
            options={emailGroups}
            onChange={async (newValue) => {
              if (newValue?.groupID === emailGroup?.groupID) {
                return
              }

              updateFormValues(
                {
                  emailSource: 'eloqua',
                  emailHtml: '',
                  eloquaEmailFields: {
                    eloquaOrg: eloquaOrg as EloquaEmailFields['eloquaOrg'],
                    emailGroup: newValue as EloquaEmailFields['emailGroup'],
                    emailTemplate: null,
                  },
                },
                // Always add the error, because this resets later fields
                { errorsToAdd: ['emailHtml', 'eloqua'] },
              )

              // Ungrouped: Should use search field for templates instead
              if (!eloquaOrg || !newValue || newValue.groupID === null) {
                return
              }

              await getEmailTemplates({
                variables: {
                  eloquaOrgID: eloquaOrg.eloquaOrgID,
                  groupID: newValue.groupID,
                  search: undefined,
                },
              })
            }}
          />
          {!!orgListError && (
            <ErrorMessage>
              Error fetching organisations. Please reload the page or contact
              support (
              <Link href={`mailto:${supportEmail}`}>{supportEmail}</Link>
              ).
            </ErrorMessage>
          )}
          {eloquaOrg && !!errorFetchingEmailGroups && (
            <ErrorMessage>
              Error fetching email groups. Please reload the page or contact
              support (
              <Link href={`mailto:${supportEmail}`}>{supportEmail}</Link>
              ).
            </ErrorMessage>
          )}
          {!errorFetchingEmailGroups &&
            !loadingEmailGroups &&
            !!eloquaOrg &&
            emailGroups.length === 0 && (
              <ErrorMessage>
                No email groups found for the selected organisation.
              </ErrorMessage>
            )}
          {hasErroredFields && !eloquaEmailFields?.emailGroup && (
            <ErrorMessage>Please select an email group.</ErrorMessage>
          )}
        </FormField>
      </FormRow>
      <FormRow>
        <FormLabel
          id="eloqua-email-template"
          tooltip="The email template to use from the selected group."
        >
          Email template
        </FormLabel>
        <FormField>
          <SelectBox
            id="eloquaEmailTemplate"
            labelKey="name"
            valueKey="eloquaID"
            placeholder={
              emailGroup
                ? 'Select'
                : 'Select an email group to view available templates'
            }
            isClearable
            isLoading={loadingEmailTemplates}
            isDisabled={
              !eloquaOrg ||
              !emailGroup ||
              (!loadingEmailTemplates &&
                emailTemplates.length === 0 &&
                emailGroup.groupID !== null)
            }
            loadingMessage={() => (
              <LoadingLabel label="Fetching email templates" />
            )}
            error={
              emailHtml.length > maxEmailHtmlLength ||
              (emailGroup && !!errorFetchingEmailTemplates) ||
              (hasErroredFields && !emailTemplate)
            }
            aria-errormessage="email-template-error"
            value={emailTemplate}
            options={emailTemplates}
            inputValue={templateSearch}
            onInputChange={async (newValue) => {
              setTemplateSearch(newValue)

              // Only fetch templates asynchronously if user is searching ungrouped templates
              if (
                eloquaOrg &&
                emailGroup &&
                emailGroup.groupID === null &&
                newValue.length === 4
              ) {
                await getEmailTemplates({
                  variables: {
                    eloquaOrgID: eloquaOrg.eloquaOrgID,
                    groupID: undefined,
                    search: newValue,
                  },
                })
              }
            }}
            onChange={async (newValue) => {
              let newEmailHtml = ''

              if (eloquaOrg && newValue) {
                setFetchingEmailHtml(true)

                const { data: htmlData } = await fetchEloquaEmailHtml({
                  variables: {
                    eloquaOrgID: eloquaOrg.eloquaOrgID,
                    templateID: newValue.eloquaID,
                  },
                })

                if (htmlData) {
                  newEmailHtml =
                    htmlData.track.eloquaQueries.getEloquaEmailTemplateDetail
                      .emailHtml || ''
                }
              }

              const errorsToAdd: string[] = []
              const errorsToRemove: string[] = []

              if (newValue) {
                errorsToRemove.push('eloqua')

                if (newEmailHtml) {
                  errorsToRemove.push('emailHtml')
                }
              } else {
                errorsToAdd.push('eloqua', 'emailHtml')
              }

              updateFormValues(
                {
                  emailSource: 'eloqua',
                  emailHtml: newEmailHtml,
                  eloquaEmailFields: {
                    eloquaOrg: eloquaOrg as EloquaEmailFields['eloquaOrg'],
                    emailGroup: emailGroup as EloquaEmailFields['emailGroup'],
                    emailTemplate: newValue,
                  },
                },
                { errorsToAdd, errorsToRemove },
              )

              setFetchingEmailHtml(false)
            }}
          />
          {emailHtml.length > maxEmailHtmlLength && (
            <ErrorMessage>
              Your email html has too many characters. Try only copying the
              email body.
            </ErrorMessage>
          )}
          {!!errorFetchingEmailTemplates && (
            <ErrorMessage>
              Error fetching email template. Please reload the page or contact
              support (
              <Link href={`mailto:${supportEmail}`}>{supportEmail}</Link>
              ).
            </ErrorMessage>
          )}
          {!errorFetchingEmailTemplates &&
            !loadingEmailTemplates &&
            emailTemplates.length === 0 &&
            !!emailGroup &&
            emailGroup?.groupID !== null && (
              <ErrorMessage>
                No email templates found for the selected organisation.
              </ErrorMessage>
            )}
          {!!emailTemplate && !!errorFetchingEloquaEmailHtml && (
            <ErrorMessage>
              Error fetching email HTML. Please reload the page or contact
              support (
              <Link href={`mailto:${supportEmail}`}>{supportEmail}</Link>
              ).
            </ErrorMessage>
          )}
          {!errorFetchingEmailTemplates &&
            !errorFetchingEloquaEmailHtml &&
            emailHtml &&
            !htmlHasLinks(emailHtml) && (
              <ErrorMessage>
                No links found in this email template.
              </ErrorMessage>
            )}
        </FormField>
      </FormRow>
    </>
  )
}

interface SalesforcePardotFormFieldsProps extends ConnectedPlatformFieldProps {
  isClassic?: boolean
  salesforcePardotEmailFields?: SalesforcePardotEmailFields | null
}

const SalesforcePardotFormFields = ({
  emailHtml,
  updateFormValues,
  hasErroredFields,
  setFetchingEmailHtml,
  isClassic,
  salesforcePardotEmailFields,
}: SalesforcePardotFormFieldsProps) => {
  const { businessUnitId, emailTemplate } = salesforcePardotEmailFields || {}

  const { data: generatorData } = useQuery(getCampaignCodeGenerator)

  const [
    getSFBusinessUnits,
    {
      data: sfBusinessUnitsData,
      loading: loadingSFBusinessUnits,
      error: businessUnitsError,
    },
  ] = useLazyQuery(getSavedPardotAcctList)
  const [
    getSFEmailTemplates,
    {
      data: sfEmailTemplatesData,
      loading: loadingSFTemplates,
      error: errorFetchingSFTemplates,
    },
  ] = useLazyQuery(getSalesforceEmailTemplates)
  const [
    fetchSFPardotEmailHTML,
    { loading: fetchingEmailHtml, error: errorFetchingSFPardotEmailHtml },
  ] = useLazyQuery(getSalesforceEmailHTML, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  })

  const [fetchedSavedBusinessUnit, setFetchedSavedBusinessUnit] = useState(
    false,
  )

  /** Business units are only required for classic template */
  const sfBusinessUnits = useMemo(() => {
    if (!isClassic || !sfBusinessUnitsData) return []

    return sfBusinessUnitsData.track.getSavedPardotAcctList.pardotList
  }, [isClassic, sfBusinessUnitsData])

  const sfEmailTemplates: {
    label?: string
    options: SalesforceEmailTemplate[]
  }[] = useMemo(() => {
    if (!sfEmailTemplatesData) return []

    if (!isClassic) {
      const lightningTemplates =
        sfEmailTemplatesData.track.availableSalesforcePardotEmailTemplates
          .lightningBuilder

      return [
        {
          options: _.cloneDeep(lightningTemplates).sort((a, b) => {
            return moment(b.dateUpdated).diff(moment(a.dateUpdated))
          }),
        },
      ]
    }

    const classicTemplates = _.cloneDeep(
      sfEmailTemplatesData.track.availableSalesforcePardotEmailTemplates
        .classicBuilder,
    )

    const fullOrderedList = classicTemplates.sort((a, b) => {
      return moment(b.dateUpdated).diff(moment(a.dateUpdated))
    })

    return [
      {
        label: 'List emails',
        options: fullOrderedList.filter(
          (template) => template.isClassicListEmail,
        ),
      },
      {
        label: 'Email templates',
        options: fullOrderedList.filter(
          (template) => !template.isClassicListEmail,
        ),
      },
    ]
  }, [isClassic, sfEmailTemplatesData])

  // If Company has Salesforce integration, get Business units
  // But this only needs to be fetched for the classic templates
  useEffect(() => {
    if (isClassic && !sfBusinessUnitsData) {
      getSFBusinessUnits()

      return
    }

    if (
      !isClassic &&
      sfEmailTemplates.every((group) => group.options.length === 0)
    ) {
      getSFEmailTemplates({
        variables: {
          pardotID: undefined,
          templateLocation: 'lightning',
        },
      })
    }
  }, [isClassic, sfBusinessUnitsData, sfEmailTemplates])

  // Check if a default business unit has been set
  // For Salesforce Pardot classic builder
  useEffect(() => {
    if (
      !isClassic ||
      !generatorData ||
      !generatorData.campaignCodeGenerator.defaultPardotBusinessUnit ||
      fetchedSavedBusinessUnit
    ) {
      return
    }

    updateFormValues(
      {
        emailSource: 'classic',
        emailHtml: '',
        salesforcePardotEmailFields: {
          businessUnitId:
            generatorData.campaignCodeGenerator.defaultPardotBusinessUnit,
          emailTemplate: null,
        },
      },
      // Always add the error, because this resets later fields
      { errorsToAdd: ['emailHtml', 'classic'] },
    )

    setFetchedSavedBusinessUnit(true)

    getSFEmailTemplates({
      variables: {
        pardotID: generatorData.campaignCodeGenerator.defaultPardotBusinessUnit,
        templateLocation: 'classic',
      },
    })
  }, [isClassic, fetchedSavedBusinessUnit, generatorData])

  // Ensure business unit is set if only one is available
  useEffect(() => {
    if (sfBusinessUnits.length === 1 && !businessUnitId) {
      const { pardotID } = sfBusinessUnits[0]

      updateFormValues(
        {
          emailSource: 'classic',
          emailHtml: '',
          salesforcePardotEmailFields: {
            businessUnitId: pardotID,
            emailTemplate: null,
          },
        },
        // Always add the error, because this resets later fields
        { errorsToAdd: ['emailHtml', 'classic'] },
      )

      getSFEmailTemplates({
        variables: {
          pardotID,
          templateLocation: 'classic',
        },
      })
    }
  }, [businessUnitId, sfBusinessUnits])

  return (
    <>
      {isClassic && sfBusinessUnits.length > 1 && (
        <FormRow>
          <FormLabel
            id="salesforce-pardot-business-unit"
            tooltip="The name of the Salesforce business unit that has the email template you want to find and replace links in."
          >
            Salesforce business unit
          </FormLabel>
          <FormField>
            <SelectBox
              id="salesforceBusinessUnit"
              isClearable
              labelKey="pardotName"
              valueKey="pardotID"
              placeholder="Select business unit or start typing"
              isLoading={loadingSFBusinessUnits}
              loadingMessage={() => (
                <LoadingLabel label="Checking integrations" />
              )}
              error={
                !!businessUnitsError || (hasErroredFields && !businessUnitId)
              }
              value={
                sfBusinessUnits.find(
                  (option) => option.pardotID === businessUnitId,
                ) || null
              }
              options={sfBusinessUnits}
              onChange={async (newValue) => {
                updateFormValues(
                  {
                    emailSource: 'classic',
                    emailHtml: '',
                    salesforcePardotEmailFields: {
                      businessUnitId: newValue?.pardotID || '',
                      emailTemplate: null,
                    },
                  },
                  // Always add the error, because this resets later fields
                  { errorsToAdd: ['emailHtml', 'classic'] },
                )

                // Fetch templates for the selected business unit
                if (newValue) {
                  getSFEmailTemplates({
                    variables: {
                      pardotID: newValue.pardotID,
                      templateLocation: 'classic',
                    },
                  })
                }
              }}
            />
            {hasErroredFields && !businessUnitId && (
              <ErrorMessage>Please select a business unit.</ErrorMessage>
            )}
          </FormField>
        </FormRow>
      )}
      <FormRow>
        <FormLabel
          id="salesforce-pardot-email-template"
          tooltip={
            <>
              <p>The name of the email in Salesforce.</p>
              {emailTemplate && (
                <p>
                  <strong>Your Salesforce Account:</strong>{' '}
                  {emailTemplate.pardotName} ({emailTemplate.pardotID})
                </p>
              )}
            </>
          }
        >
          Email template
        </FormLabel>
        <FormField>
          <SelectBox
            id="salesforceEmailTemplate"
            isClearable
            labelKey="emailName"
            valueKey="emailID"
            placeholder="Select"
            isDisabled={
              (isClassic && !businessUnitId) ||
              (!loadingSFTemplates &&
                sfEmailTemplates.every((group) => group.options.length === 0))
            }
            isLoading={fetchingEmailHtml || loadingSFTemplates}
            loadingMessage={() => <LoadingLabel label="Fetching templates" />}
            error={
              !!businessUnitsError ||
              !!errorFetchingSFTemplates ||
              (!!emailTemplate && !!errorFetchingSFPardotEmailHtml) ||
              emailHtml.length > maxEmailHtmlLength ||
              (hasErroredFields && !emailTemplate)
            }
            aria-errormessage="email-template-error"
            value={emailTemplate}
            options={sfEmailTemplates}
            onChange={async (newValue) => {
              setFetchingEmailHtml(true)

              const errorsToAdd: string[] = []
              const errorsToRemove: string[] = []

              let emailHtmlToAdd = ''

              if (newValue) {
                errorsToRemove.push(isClassic ? 'classic' : 'lightning')

                const {
                  pardotID,
                  pardotName,
                  emailID,
                  uiType,
                  isClassicListEmail,
                } = newValue

                const { data: htmlData } = await fetchSFPardotEmailHTML({
                  variables: {
                    [`${
                      isClassic ? 'classic' : 'lightning'
                    }PardotTemplateList`]: [
                      {
                        pardotID,
                        pardotName,
                        emailID,
                        uiType,
                        isClassicListEmail,
                      },
                    ],
                  },
                })

                emailHtmlToAdd =
                  htmlData?.track.salesforcePardotEmailHTML[
                    `${isClassic ? 'classic' : 'lightning'}Builder`
                  ][0].emailHTML || ''
              } else {
                errorsToAdd.push(isClassic ? 'classic' : 'lightning')
              }

              if (!emailHtmlToAdd) {
                errorsToAdd.push('emailHtml')
              } else {
                errorsToRemove.push('emailHtml')
              }

              updateFormValues(
                {
                  emailSource: isClassic ? 'classic' : 'lightning',
                  salesforcePardotEmailFields: {
                    businessUnitId: businessUnitId || '',
                    emailTemplate: newValue,
                  },
                  emailHtml: emailHtmlToAdd,
                },
                { errorsToAdd, errorsToRemove },
              )

              setFetchingEmailHtml(false)
            }}
          />
          {emailHtml.length > maxEmailHtmlLength && (
            <ErrorMessage>
              Your email html has too many characters. Try only copying the
              email body.
            </ErrorMessage>
          )}
          {!!businessUnitsError && (
            <ErrorMessage>
              Error fetching business units. Please reload the page or contact
              support (
              <Link href={`mailto:${supportEmail}`}>{supportEmail}</Link>
              ).
            </ErrorMessage>
          )}
          {!errorFetchingSFTemplates && hasErroredFields && !emailTemplate && (
            <ErrorMessage>Please select an email template.</ErrorMessage>
          )}
          {!!errorFetchingSFTemplates && (
            <ErrorMessage>
              Error fetching email templates. Please reload the page or contact
              support (
              <Link href={`mailto:${supportEmail}`}>{supportEmail}</Link>
              ).
            </ErrorMessage>
          )}
          {!errorFetchingSFTemplates &&
            !loadingSFTemplates &&
            isClassic &&
            businessUnitId &&
            sfEmailTemplates.every((group) => group.options.length === 0) && (
              <ErrorMessage>
                No email templates found for the selected business unit.
              </ErrorMessage>
            )}
          {!!emailTemplate && !!errorFetchingSFPardotEmailHtml && (
            <ErrorMessage>
              Error fetching email HTML. Please reload the page or contact
              support (
              <Link href={`mailto:${supportEmail}`}>{supportEmail}</Link>
              ).
            </ErrorMessage>
          )}
          {!errorFetchingSFPardotEmailHtml &&
            emailHtml &&
            !htmlHasLinks(emailHtml) && (
              <ErrorMessage>
                No links found in this email template.
              </ErrorMessage>
            )}
        </FormField>
      </FormRow>
    </>
  )
}

interface SalesforceMCFormFieldsProps extends ConnectedPlatformFieldProps {
  salesforceMCEmailFields?: SalesforceMCEmailFields | null
}

const SalesforceMCFormFields = ({
  emailHtml,
  updateFormValues,
  hasErroredFields,
  setFetchingEmailHtml,
  salesforceMCEmailFields,
}: SalesforceMCFormFieldsProps) => {
  const {
    businessUnitId,
    businessUnitName,
    templateID,
    templateType,
    slotMap,
    textContent,
  } = salesforceMCEmailFields || {}

  const {
    data: salesforceMCIntegrationData,
    loading: loadingSFBusinessUnits,
    error: errorFetchingSFBusinessUnits,
  } = useQuery(getIntegrationsStatus)

  const [
    getSFMCEmailTemplates,
    {
      data: sfEmailTemplatesData,
      loading: loadingSFTemplates,
      error: errorFetchingSFMCTemplates,
    },
  ] = useLazyQuery(getSalesforceMCTemplateList)
  const [
    fetchSFMCEmailHTML,
    { loading: fetchingSFMCEmailHtml, error: errorFetchingSFMCEmailHtml },
  ] = useLazyQuery(getSalesforceMCEmailTemplateDetail)

  // Business units are only required for classic template
  const sfBusinessUnits = useMemo(() => {
    if (!salesforceMCIntegrationData) return []

    const isConnected =
      salesforceMCIntegrationData.currentCompany
        .salesforceMcIntegrationStatus === 'active'

    if (!isConnected) return []

    return salesforceMCIntegrationData.currentCompany
      .salesforceMcIntegrationList
  }, [salesforceMCIntegrationData])

  const sfEmailTemplates = useMemo(() => {
    if (!sfEmailTemplatesData) return []

    return sfEmailTemplatesData.track.salesforceMarketingCloudQueries
      .getTemplateList
  }, [sfEmailTemplatesData])

  const { slotMapHasNoLinks, textContentHasNoLinks } = useMemo(() => {
    // 'template' type does not use slotMap - no need to check
    if (templateType === 'template' || !slotMap || !textContent)
      return { slotMapHasNoLinks: false, textContentHasNoLinks: false }

    // Short sharp check for links in any of the slots
    // String concatenation is fine here - only checking, not replacing
    return {
      slotMapHasNoLinks: !slotMap.find((slot) => {
        return slot.hasLinks
      }),
      textContentHasNoLinks: !htmlHasLinks(textContent, true),
    }
  }, [templateType, slotMap, textContent])

  // Fetch email templates on load if org comes from a saved value
  useEffect(() => {
    if (businessUnitId && !templateID && sfEmailTemplates.length === 0) {
      getSFMCEmailTemplates({
        variables: {
          mid: businessUnitId,
        },
      })
    }
  }, [businessUnitId, templateID, sfEmailTemplates])

  // Ensure businessUnitId is set if only one is available
  useEffect(() => {
    if (!businessUnitId && sfBusinessUnits.length === 1) {
      const {
        mid,
        businessUnitName: businessUnitNameToUse,
      } = sfBusinessUnits[0]

      updateFormValues(
        {
          emailSource: 'salesforceMC',
          emailHtml: '',
          salesforceMCEmailFields: {
            businessUnitId: mid,
            businessUnitName: businessUnitNameToUse,
            templateID: null,
            templateType: null,
            slotMap: null,
            textContent: null,
          },
        },
        { errorsToAdd: ['salesforceMC', 'emailHtml'] },
      )

      // And request templates for that business unit
      getSFMCEmailTemplates({
        variables: {
          mid,
        },
      })
    }
  }, [businessUnitId, sfBusinessUnits])

  return (
    <>
      {sfBusinessUnits.length > 1 && (
        <FormRow>
          <FormLabel
            id="salesforce-mc-business-unit"
            tooltip="The name of the Salesforce Marketing Cloud business unit that has the email template you want to find and replace links in."
          >
            Salesforce MC business unit
          </FormLabel>
          <FormField>
            <SelectBox
              id="salesforceBusinessUnit"
              isClearable
              labelKey="pardotName"
              valueKey="pardotID"
              placeholder="Select business unit or start typing"
              isLoading={loadingSFBusinessUnits}
              loadingMessage={() => (
                <LoadingLabel label="Checking integrations" />
              )}
              value={sfBusinessUnits.find(
                (option) => option.mid === businessUnitId,
              )}
              options={sfBusinessUnits}
              onChange={async (newValue) => {
                updateFormValues(
                  {
                    emailSource: 'salesforceMC',
                    emailHtml: '',
                    salesforceMCEmailFields: {
                      businessUnitId: newValue?.mid || null,
                      businessUnitName: newValue?.businessUnitName || null,
                      templateID: null,
                      templateType: null,
                      slotMap: null,
                      textContent: null,
                    },
                  },
                  { errorsToAdd: ['salesforceMC', 'emailHtml'] },
                )

                if (newValue) {
                  getSFMCEmailTemplates({
                    variables: {
                      mid: newValue.mid,
                    },
                  })
                }
              }}
            />
          </FormField>
        </FormRow>
      )}
      <FormRow>
        <FormLabel
          id="salesforce-mc-email-template"
          tooltip={
            <>
              <p>The name of the email in Salesforce.</p>
              {businessUnitId && businessUnitName && (
                <p>
                  <strong>Your Salesforce Account:</strong> {businessUnitName} (
                  {businessUnitId})
                </p>
              )}
            </>
          }
        >
          Email template
        </FormLabel>
        <FormField>
          <SelectBox
            id="salesforceEmailTemplate"
            isClearable
            labelKey="templateName"
            valueKey="templateID"
            placeholder="Select"
            isDisabled={!loadingSFTemplates && sfEmailTemplates.length === 0}
            isLoading={loadingSFTemplates || fetchingSFMCEmailHtml}
            loadingMessage={() => <LoadingLabel label="Fetching templates" />}
            error={
              !!errorFetchingSFBusinessUnits ||
              !!errorFetchingSFMCTemplates ||
              (!!templateID && !!errorFetchingSFMCEmailHtml) ||
              emailHtml.length > maxEmailHtmlLength ||
              (hasErroredFields && !templateID)
            }
            aria-errormessage="email-template-error"
            value={sfEmailTemplates.find(
              (template) => template.templateID === templateID,
            )}
            options={sfEmailTemplates}
            onChange={async (newValue) => {
              setFetchingEmailHtml(true)

              const errorsToAdd: string[] = []
              const errorsToRemove: string[] = []

              let emailHtmlToAdd = ''

              const newSalesforceMCEmailFields: SalesforceMCEmailFields = {
                businessUnitId: businessUnitId || null,
                businessUnitName: businessUnitName || null,
                templateID: newValue?.templateID || null,
                templateType:
                  (newValue?.templateType as SalesforceMCTemplateType) || null,
                slotMap: null,
                textContent: null,
              }

              if (businessUnitId && newValue) {
                errorsToRemove.push('salesforceMC')

                const { templateID: newTemplateID } = newValue

                const { data: htmlData } = await fetchSFMCEmailHTML({
                  variables: {
                    mid: businessUnitId,
                    templateID: newTemplateID,
                  },
                })

                const {
                  templateContent,
                  textContent: newTextContent,
                  slotMap: newSlotMap,
                } =
                  htmlData?.track.salesforceMarketingCloudQueries
                    .getTemplateDetail || {}

                emailHtmlToAdd = templateContent || ''

                // Check if each slot in slotMap has a link
                // If yes, flag the slot for updating
                // Only need to do for templatebasedemail
                const flaggedSlotMap =
                  newValue.templateType === 'templatebasedemail' &&
                  Array.isArray(newSlotMap) &&
                  newSlotMap.length > 0
                    ? newSlotMap.map((slot) => {
                        let hasLinks = htmlHasLinks(slot.content)
                        // For efficiency, only run this check if main content does not have links
                        if (!hasLinks) {
                          hasLinks = !!slot.blocks.find((block) =>
                            htmlHasLinks(block.content),
                          )
                        }
                        return {
                          ...slot,
                          hasLinks,
                        }
                      })
                    : null

                newSalesforceMCEmailFields.templateID = newValue.templateID
                newSalesforceMCEmailFields.templateType = newValue.templateType as SalesforceMCTemplateType
                newSalesforceMCEmailFields.slotMap = flaggedSlotMap
                newSalesforceMCEmailFields.textContent = newTextContent || ''
              } else {
                errorsToAdd.push('salesforceMC')
              }

              if (!emailHtmlToAdd) {
                errorsToAdd.push('emailHtml')
              } else {
                errorsToRemove.push('emailHtml')
              }

              updateFormValues(
                {
                  emailSource: 'salesforceMC',
                  salesforceMCEmailFields: newSalesforceMCEmailFields,
                  emailHtml: emailHtmlToAdd,
                },
                { errorsToAdd, errorsToRemove },
              )

              setFetchingEmailHtml(false)
            }}
          />
          {emailHtml.length > maxEmailHtmlLength && (
            <ErrorMessage>
              Your email html has too many characters. Try only copying the
              email body.
            </ErrorMessage>
          )}
          {!!errorFetchingSFBusinessUnits && (
            <ErrorMessage>
              Error fetching business units. Please reload the page or contact
              support (
              <Link href={`mailto:${supportEmail}`}>{supportEmail}</Link>
              ).
            </ErrorMessage>
          )}
          {!errorFetchingSFMCTemplates && hasErroredFields && !templateID && (
            <ErrorMessage>Please select an email template.</ErrorMessage>
          )}
          {!!errorFetchingSFMCTemplates && (
            <ErrorMessage>
              Error fetching email templates. Please reload the page or contact
              support (
              <Link href={`mailto:${supportEmail}`}>{supportEmail}</Link>
              ).
            </ErrorMessage>
          )}
          {!errorFetchingSFMCTemplates &&
            !loadingSFTemplates &&
            businessUnitId &&
            sfEmailTemplates.length === 0 && (
              <ErrorMessage>
                No email templates found for the selected business unit.
              </ErrorMessage>
            )}
          {!!templateID && !!errorFetchingSFMCEmailHtml && (
            <ErrorMessage>
              Error fetching email HTML. Please reload the page or contact
              support (
              <Link href={`mailto:${supportEmail}`}>{supportEmail}</Link>
              ).
            </ErrorMessage>
          )}
          {!errorFetchingSFMCEmailHtml &&
            emailHtml &&
            !htmlHasLinks(emailHtml) &&
            slotMapHasNoLinks &&
            textContentHasNoLinks && (
              <ErrorMessage>
                No links found in this email template.
              </ErrorMessage>
            )}
        </FormField>
      </FormRow>
    </>
  )
}

type ConnectionsStatuses = {
  [key in EmailSources]: boolean | 'requiresReconnect'
}

interface EmailFieldsProps {
  formValues: EmailFields
  updateFormValues: (
    newVals: Partial<EmailFields>,
    options: UpdateFormOptions,
  ) => void
  showErrorMessages?: boolean
  fieldsWithErrors?: string[] | null
  setFetchingEmailHtml: React.Dispatch<React.SetStateAction<boolean>>
}

const TrackCreateFormEmailFields = ({
  formValues,
  updateFormValues,
  showErrorMessages,
  fieldsWithErrors,
  setFetchingEmailHtml,
}: EmailFieldsProps) => {
  const history = useHistory()

  const { data: integrationsData, loading: loadingIntegrationsData } = useQuery(
    getIntegrationsStatus,
  )

  // Get workspace connection status for all email platforms
  const connectionsStatuses: ConnectionsStatuses = useMemo(() => {
    if (!integrationsData)
      return {
        salesforceMC: false,
        lightning: false,
        classic: false,
        eloqua: false,
        emailHtml: true,
      }

    let _salesforcePardotConnected: 'requiresReconnect' | boolean =
      integrationsData.currentCompany.pardotIntegrationStatus === 'active'

    if (
      integrationsData.currentCompany.pardotIntegrationStatus ===
      'needs_reconnect'
    ) {
      _salesforcePardotConnected = 'requiresReconnect'
    }

    return {
      salesforceMC:
        integrationsData.currentCompany.salesforceMcIntegrationStatus ===
        'active',
      lightning: _salesforcePardotConnected,
      classic: _salesforcePardotConnected,
      eloqua:
        integrationsData.currentCompany.eloquaIntegrationStatus === 'active',
      emailHtml: true,
    }
  }, [integrationsData])

  // Set email source to Eloqua/SF if connected
  useEffect(() => {
    // Do not overwrite if not HTML or if HTML is already set
    if (formValues.emailSource !== 'emailHtml' || formValues.emailHtml !== '')
      return

    const { salesforceMC, classic, eloqua } = connectionsStatuses

    let setConnectedEmailSource: EmailSources | null = null

    if (eloqua === true) {
      setConnectedEmailSource = 'eloqua'
    } else if (classic === true) {
      setConnectedEmailSource = 'classic'
    } else if (salesforceMC === true) {
      setConnectedEmailSource = 'salesforceMC'
    }

    if (setConnectedEmailSource) {
      updateFormValues(
        {
          emailSource: setConnectedEmailSource,
        },
        { errorsToAdd: ['emailHtml'] },
      )
    }
  }, [connectionsStatuses])

  return (
    <>
      <FormRow className={styles.topFormRow}>
        <FormLabel
          id="email-platform"
          tooltip="Select which tool you use to draft emails. If not listed, use 'Other' to find and replace links manually in email HTML."
        >
          Email platform
        </FormLabel>
        <FormField>
          <SelectBox
            id="emailSource"
            labelKey="emailSourceName"
            valueKey="emailSource"
            placeholder="Select platform"
            isLoading={loadingIntegrationsData}
            loadingMessage={() => (
              <LoadingLabel label="Checking integrations" />
            )}
            value={emailSourceOptions.find(
              (option) => option.emailSource === formValues.emailSource,
            )}
            options={emailSourceOptions}
            onChange={(newEmailSource) => {
              if (!newEmailSource) return

              const { emailSource } = newEmailSource

              // Direct to connect page if not connected
              if (connectionsStatuses[emailSource] !== true) {
                history.push('/connect')

                return
              }

              const errorsToAdd = ['emailHtml']
              const errorsToRemove: string[] = []

              errorsToAdd.push(emailSource)

              const otherEmailSources = emailSourcesArray.filter(
                (source) => source !== emailSource,
              )
              errorsToRemove.push(...otherEmailSources)

              updateFormValues(
                {
                  emailSource: newEmailSource.emailSource,
                },
                { errorsToAdd, errorsToRemove },
              )
            }}
          >
            <Button
              variant="text"
              className={styles.addButton}
              onPressStart={() => history.push('/connect')}
            >
              Connect a new platform +
            </Button>
          </SelectBox>
          {[
            connectionsStatuses.lightning,
            connectionsStatuses.classic,
          ].includes('requiresReconnect') && (
            <p className={styles.footNoteError}>
              Salesforce Pardot account requires{' '}
              <Link type="arrowForward" newTab={false} href="/connect">
                reconnection
              </Link>
            </p>
          )}
        </FormField>
      </FormRow>
      {formIsEloquaEmailFields(formValues) && (
        <EloquaEmailFormFields
          emailHtml={formValues.emailHtml}
          updateFormValues={updateFormValues}
          hasErroredFields={
            showErrorMessages && fieldsWithErrors?.includes('eloqua')
          }
          setFetchingEmailHtml={setFetchingEmailHtml}
          eloquaEmailFields={formValues.eloquaEmailFields}
        />
      )}
      {formIsSalesforcePardotEmailFields(formValues) && (
        <SalesforcePardotFormFields
          emailHtml={formValues.emailHtml}
          updateFormValues={updateFormValues}
          hasErroredFields={
            showErrorMessages &&
            (fieldsWithErrors?.includes('classic') ||
              fieldsWithErrors?.includes('lightning'))
          }
          setFetchingEmailHtml={setFetchingEmailHtml}
          isClassic={formValues.emailSource === 'classic'}
          salesforcePardotEmailFields={formValues.salesforcePardotEmailFields}
        />
      )}
      {formIsSalesforceMCEmailFields(formValues) && (
        <SalesforceMCFormFields
          emailHtml={formValues.emailHtml}
          updateFormValues={updateFormValues}
          hasErroredFields={
            showErrorMessages &&
            (fieldsWithErrors?.includes('classic') ||
              fieldsWithErrors?.includes('lightning'))
          }
          setFetchingEmailHtml={setFetchingEmailHtml}
          salesforceMCEmailFields={formValues.salesforceMCEmailFields}
        />
      )}
      {formValues.emailSource === 'emailHtml' && (
        <FormRow includePaddingBottom>
          <FormField className={styles.emailHtmlField}>
            <Input
              type="textArea"
              id="email-html"
              name="Email HTML"
              className={styles.textArea}
              maxLength={100000}
              placeholder="Paste email HTML here before selecting values to be added to email links."
              value={formValues.emailHtml}
              onValueChange={(value: string) => {
                const valid =
                  value !== '' &&
                  value.length < maxEmailHtmlLength &&
                  htmlHasLinks(value)

                updateFormValues(
                  {
                    emailHtml: value,
                  },
                  valid
                    ? { errorsToRemove: ['emailHtml'] }
                    : { errorsToAdd: ['emailHtml'] },
                )
              }}
              error={
                (showErrorMessages &&
                  fieldsWithErrors?.includes('emailHtml')) ||
                formValues.emailHtml.length > maxEmailHtmlLength
              }
            />
            {formValues.emailHtml.length > maxEmailHtmlLength && (
              <ErrorMessage className={styles.emailHtmlError}>
                Too many characters. Try only copying the email body.
              </ErrorMessage>
            )}
            {showErrorMessages &&
              fieldsWithErrors?.includes('emailHtml') &&
              formValues.emailHtml.length < maxEmailHtmlLength && (
                <ErrorMessage className={styles.emailHtmlError}>
                  You must enter some valid email HTML that contains links (e.g.
                  href="https://uplifter.tech").
                </ErrorMessage>
              )}
          </FormField>
        </FormRow>
      )}
    </>
  )
}

export default TrackCreateFormEmailFields
