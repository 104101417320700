import { useEffect, useMemo } from 'react'
import { useLazyQuery, useReactiveVar } from '@apollo/client'
import _ from 'lodash'

import { currentUserDetails } from '../api/apollo/variables'
import { getCampaignCodeGenerator } from '../api/graphql/track-create-client'
import { defaultUAGenerator } from '../helpers/track-module'

interface useTrackGeneratorProps {
  useDummyData?: boolean
}

/** Submission logic and error state for Track>Create form */
const useTrackGenerator = ({
  useDummyData = false,
}: useTrackGeneratorProps = {}) => {
  const { workspaceID } = useReactiveVar(currentUserDetails)

  const [
    getGenerator,
    { data: generatorData, loading: loadingGenerator, error: generatorError },
  ] = useLazyQuery(getCampaignCodeGenerator)

  // Wait for account ID so generator can be cached
  useEffect(() => {
    if (!workspaceID) return

    getGenerator({ fetchPolicy: 'network-only' })
  }, [workspaceID])

  const generatedStructure = useMemo(() => {
    if (!generatorData) return null

    return generatorData.campaignCodeGenerator
  }, [generatorData])

  return {
    generatedStructure: useDummyData ? defaultUAGenerator : generatedStructure,
    loadingGenerator,
    generatorError,
  }
}

export default useTrackGenerator
